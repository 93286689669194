import {
  Image,
  RefreshControl,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from "react-native";
import CircularProgress from "react-native-circular-progress-indicator";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import colors from "@acme/common-utils/colors";

import AppButton from "~/components/AppButton";
import AppImage from "~/components/AppImage";
import NotSubscribed from "~/components/NotSubscribed";
import SaleCarousel from "~/components/SaleCarousel";
import { storageDomain } from "~/config/settings";
import { useAuthStore } from "~/store";
import { RouterOutputs, trpc } from "~/utils/trpc";
import AppText from "../components/AppText";
import Screen from "../components/Screen";
import SquareItem from "../components/SquareItem";
import { AppNavigatorParamList } from "../navigation/AppNavigator";
import routes from "../navigation/routes";

dayjs.extend(advancedFormat);

export default function CustomerDashboardScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.CUSTOMER_DASHBOARD
  >;
}) {
  const user = useAuthStore((state) => state.user)!;

  const trpcUtils = trpc.useUtils();

  const queryClient = useQueryClient();

  const {
    data: dietryProducts,
    isLoading: dietryProductsLoading,
    refetch: dietryProductsRefetch,
  } = trpc.product.listDietaryProducts.useQuery();

  const utils = trpc.useUtils();

  const { mutate: addToBasket, isLoading: isAddingToBasket } =
    trpc.product.addToBasket.useMutation({
      onError() {
        utils.invalidate();
      },
      onMutate(variables) {
        const getProductsQueryKey = getQueryKey(
          trpc.product.listDietaryProducts,
        );
        queryClient.setQueriesData<
          RouterOutputs["product"]["listDietaryProducts"]
        >({ queryKey: getProductsQueryKey }, (oldData) => {
          if (!oldData) {
            return oldData;
          }
          return oldData.map((product) => {
            if (product.id === variables.productId) {
              return {
                ...product,
                isInBasket: true,
              };
            }
            return product;
          });
        });
      },
    });

  const { mutate: removeFromBasket, isLoading: isRemovingFromBasket } =
    trpc.product.removeFromBasket.useMutation({
      onError() {
        utils.invalidate();
      },
      onMutate(variables) {
        const getProductsQueryKey = getQueryKey(
          trpc.product.listDietaryProducts,
        );
        queryClient.setQueriesData<
          RouterOutputs["product"]["listDietaryProducts"]
        >({ queryKey: getProductsQueryKey }, (oldData) => {
          if (!oldData) {
            return oldData;
          }
          return oldData.map((product) => {
            if (product.id === variables.productId) {
              return {
                ...product,
                isInBasket: false,
              };
            }
            return product;
          });
        });
      },
    });

  return (
    <>
      <Screen
        noSafeArea
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={() => {
              trpcUtils.invalidate();
            }}
          />
        }
      >
        {/* <SaleCarousel /> */}
        {user.deliveriesLeft !== null ? (
          <View className="px-5">
            <AppText bigText className="mb-4 mt-2 font-bold">
              Subscription
            </AppText>
            {user.subscription ? (
              <View className="flex-row flex-wrap justify-between">
                <TouchableHighlight
                  accessibilityRole="button"
                  underlayColor={colors.highlight}
                  onPress={() => {
                    navigation.push(routes.SUBSCRIPTION_LIST);
                  }}
                  className="mb-4 h-32 flex-1 flex-row rounded-xl bg-white p-5 pb-5"
                >
                  <>
                    <View className="flex-1">
                      <AppText className="flex-1 text-lg leading-6">
                        {user.subscription.name}
                      </AppText>
                      <AppText className="mt-2 text-lg leading-5">
                        {user.deliveriesLeft}
                        {user.deliveriesLeft === 1
                          ? " Delivery"
                          : " Deliveries"}{" "}
                        left
                      </AppText>
                      {/* <AppText className="mt-2 text-lg leading-5">
                      {dayjs(user.subscriptionEndDate).format("Do MMM YYYY")}
                    </AppText> */}
                    </View>
                    <View className="justify-center">
                      <CircularProgress
                        value={user.deliveriesLeft}
                        duration={1000}
                        radius={40}
                        progressValueColor={colors.primary}
                        maxValue={user.subscriptionTotalDeliveries ?? 90}
                        title={"left"}
                        titleColor={"black"}
                        titleStyle={{ fontWeight: "bold" }}
                      />
                    </View>
                  </>
                </TouchableHighlight>
              </View>
            ) : (
              <NotSubscribed />
            )}
          </View>
        ) : null}
        {/* <View className="px-5">
        <AppText bigText className="mb-4 font-bold">
          Services
        </AppText>
        <View className="flex-row flex-wrap justify-between">
          {[
            {
              name: "My Basket",
              onPress: () => {
                navigation.push(routes.MY_BASKET);
              },
              iconName: "cart",
            },
            {
              name: "Farmhouse Booking",
              onPress: () => {
                navigation.push(routes.FARM_HOUSE_LIST);
              },
              iconName: "home",
            },
            {
              name: "Restaurant Orders",
              onPress: () => {
                navigation.push(routes.RESTAURANT_ORDER_LIST);
              },
              iconName: "carrot",
            },
          ].map(({ name, onPress, iconName }) => (
            <SquareItem
              key={name}
              name={name}
              onPress={onPress}
              iconName={iconName}
            />
          ))}
        </View>
      </View> */}
        <View className="px-5">
          <AppText bigText className="mb-4 mt-2 font-bold">
            My Products
          </AppText>
          {user.subscription ? (
            <>
              {dietryProducts && (
                <>
                  <View className="flex-1">
                    <View className="flex-row flex-wrap rounded-xl">
                      {dietryProducts
                        .filter(({ isShown }) => isShown)
                        .map((product, index) => (
                          <TouchableOpacity
                            key={product.id}
                            accessibilityRole="button"
                            onPress={() => {
                              product.isInBasket
                                ? removeFromBasket({ productId: product.id })
                                : addToBasket({ productId: product.id });
                            }}
                            className="border-mediumGray w-1/3 bg-white"
                          >
                            <View className="items-center justify-center px-5 py-3">
                              <View
                                className={`${
                                  product.isInBasket ? "bg-primary" : "bg-white"
                                } border-primary absolute right-5 top-5 z-10 self-end rounded-full border-2`}
                              >
                                <MaterialCommunityIcons
                                  name="check"
                                  size={16}
                                  color={colors.white}
                                />
                              </View>
                              <View className="m-2 h-24 w-24">
                                {product.image ? (
                                  <AppImage
                                    source={{
                                      uri: `${storageDomain}/${product.image}`,
                                    }}
                                    className="h-24 w-24"
                                  />
                                ) : (
                                  <Image
                                    source={require("../assets/default-product-avatar.png")}
                                    className="h-24 w-24"
                                  />
                                )}
                              </View>
                              <AppText className="text-center text-lg">
                                {product.name}
                              </AppText>
                            </View>
                          </TouchableOpacity>
                        ))}
                    </View>
                  </View>
                </>
              )}
            </>
          ) : (
            <View className="flex-1 items-center justify-center">
              <NotSubscribed />
            </View>
          )}
        </View>
      </Screen>
      {user.subscription ? (
        <View className="bg-white">
          <AppButton
            disabled
            className="m-3 mx-auto h-8 px-4"
            title={
              isRemovingFromBasket || isAddingToBasket ? "Saving..." : "Saved"
            }
            color={"bg-lightGray2"}
            textColor="text-mediumGray"
            onPress={() => {}}
          />
        </View>
      ) : null}
    </>
  );
}
