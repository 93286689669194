import { useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  Platform,
  TextInput,
  TouchableHighlight,
  View,
} from "react-native";
import Autocomplete from "react-native-autocomplete-input";
import MapView, { Marker } from "react-native-maps";
import Constants from "expo-constants";

import colors from "@acme/common-utils/colors";

import AppText from "~/components/AppText";
import { ListItemSeparator } from "~/components/lists";
import { getPlaceList, getPlaceName } from "~/utils/googleMaps";
import useDebounce from "~/utils/useDebounce";
import { LocationType } from "../screens/UserCreateScreen";

export default function LocationPickerModal({
  modalVisible,
  setModalVisible,
  location,
  setLocation,
}: {
  modalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
  location: null | LocationType;
  setLocation: (location: null | LocationType) => void;
}) {
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [data, setData] = useState<
    {
      name: string;
      lat: number;
      lng: number;
    }[]
  >([]);

  const mapRef = useRef<MapView>(null);
  const textRef = useRef<TextInput>(null);

  useDebounce(
    () => {
      setSearchQuery(query);
    },
    [query],
    500,
  );

  useEffect(() => {
    if (location) {
      mapRef.current?.animateCamera({
        center: {
          latitude: location.lat,
          longitude: location.lng,
        },
        zoom: 15,
      });
      setQuery(location.name);
    }
  }, [location]);

  useEffect(() => {
    if (searchQuery && showResults) {
      (async () => {
        setData(await getPlaceList(searchQuery));
      })();
    }
  }, [searchQuery, showResults]);

  return (
    <Modal animationType="slide" visible={modalVisible}>
      <View
        style={{
          flexDirection: "row",
          padding: 4,
          paddingHorizontal: 8,
          justifyContent: "space-between",
          paddingTop: Platform.OS === "android" ? 0 : Constants.statusBarHeight,
        }}
      >
        <Button
          title="Cancel"
          onPress={() => {
            setLocation(null);
            setModalVisible(false);
          }}
          color={colors.primary}
        />
        <Button
          title="Confirm"
          onPress={() => {
            setModalVisible(false);
          }}
          color={colors.primary}
        />
      </View>

      <View className="relative flex-1">
        <View className="absolute left-0 right-0 top-0 z-10">
          <Autocomplete
            renderTextInput={(props) => (
              <TextInput
                {...props}
                ref={textRef}
                placeholder="Search for a place"
                style={{
                  backgroundColor: colors.white,
                  padding: 10,
                  borderRadius: 10,
                }}
              />
            )}
            data={data}
            value={query}
            onBlur={() => setShowResults(false)}
            onFocus={() => setShowResults(true)}
            onChangeText={(text) => setQuery(text)}
            flatListProps={{
              keyExtractor: (
                {
                  name,
                  lat,
                  lng,
                }: {
                  name: string;
                  lat: number;
                  lng: number;
                },
                idx,
              ) => `${name}-${lat}-${lng}-${idx}`,
              renderItem: ({ item, index }) => (
                <>
                  {index !== 0 ? <ListItemSeparator /> : null}
                  <TouchableHighlight
                    underlayColor={colors.highlight}
                    onPress={() => {
                      setData([]);
                      if (textRef.current) {
                        textRef.current.blur();
                      }
                      setLocation({
                        lat: item.lat,
                        lng: item.lng,
                        name: item.name,
                      });
                    }}
                  >
                    <AppText className="p-2">{item.name}</AppText>
                  </TouchableHighlight>
                </>
              ),
              keyboardShouldPersistTaps: "handled",
            }}
          />
        </View>
        <MapView
          initialRegion={{
            latitude: 27.7172,
            longitude: 85.324,
            latitudeDelta: 0.0922,
            longitudeDelta: 0.0421,
          }}
          ref={mapRef}
          showsMyLocationButton
          showsUserLocation
          provider={"google"}
          className="h-full w-full"
          onPress={(e) => {
            setLocation({
              lat: e.nativeEvent.coordinate.latitude,
              lng: e.nativeEvent.coordinate.longitude,
              name: "",
            });
            (async () => {
              setLocation({
                lat: e.nativeEvent.coordinate.latitude,
                lng: e.nativeEvent.coordinate.longitude,
                name: await getPlaceName(
                  e.nativeEvent.coordinate.latitude,
                  e.nativeEvent.coordinate.longitude,
                ),
              });
            })();
          }}
        >
          {location ? (
            <Marker
              coordinate={{
                latitude: location.lat,
                longitude: location.lng,
              }}
              title="Picked Location"
              description="This is the location you picked"
            />
          ) : null}
        </MapView>
      </View>
    </Modal>
  );
}
