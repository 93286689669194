enum routes {
  LOGIN = "Login",
  LOGIN_OTP = "OTP",
  LOGIN_INTERMEDIATE = "LoginIntermediate",
  CHANGE_PASSWORD = "ChangePassword",
  RESET_PASSWORD = "ResetPassword",
  RESET_PASSWORD_OTP = "ResetPasswordOTP",
  HOME_TAB = "HomeTab",
  CUSTOMER_DASHBOARD = "CustomerDashboard",
  ADMIN_DASHBOARD = "AdminDashboard",
  GENERAL_STAFF_DASHBOARD = "GeneralStaffDashboard",
  MY_BASKET = "MyBasket",
  USER_BASKET = "UserBasket",
  PRODUCT = "Product",
  PRODUCT_LIST = "ProductList",
  PRODUCT_MANAGE_LIST = "ProductManageList",
  SETTINGS = "Settings",
  SUBSCRIPTION_LIST = "SubscriptionList",
  NOTIFICATIONS = "Notifications",
  CHAT_LIST = "ChatList",
  CHAT = "Chat",
  USER_CREATE = "UserCreate",
  USER_MANAGE_LIST = "UserManageList",
  USER_GENERAL_MEMBER_LIST = "UserGeneralMemberList",
  USER_ASSIGN_DRIVER_LIST = "UserAssignDriverList",
  DELIVERY_LIST = "DeliveryList",
  SUBSCRIPTION_CREATE = "SubscriptionCreate",
  FARM_HOUSE_CREATE = "FarmhouseCreate",
  FARM_HOUSE_BOOKING = "FarmhouseBooking",
  FARM_HOUSE_LIST = "FarmhouseList",
  FARM_HOUSE_MANAGE_LIST = "FarmhouseManageList",
  FARM_HOUSE_BOOKING_MANAGE_LIST = "FarmhouseBookingManageList",
  FARM_HOUSE_BOOKING_ADMIN = "FarmhouseBookingAdmin",
  FARM_HOUSE_SPECIFIC_BOOKING_LIST = "FarmhouseSpecificBookingList",
  FLYER_CREATE = "FlyerManage",
  FLYER_LIST = "FlyerList",
  RESTAURANT_ITEM_MANAGE_LIST = "RestaurantItemManageList",
  RESTAURANT_ITEM_CREATE = "RestaurantItemCreate",
  RESTAURANT_ORDER_ITEM_LIST = "RestaurantOrderItemList",
  RESTAURANT_ORDER_LIST = "RestaurantOrderList",
  RESTAURANT_ORDER_SUMMARY = "RestaurantOrderSummary",
  RESTAURANT_STAFF_DASHBOARD = "RestaurantStaffDashboard",

  PRODUCT_CREATE = "ProductCreate",
  USER_UPDATE = "UserUpdate",
  USER_PROFILE = "UserProfile",
}

export default routes;
