import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import colors from "@acme/common-utils/colors";

import AdminDashboardScreen from "~/screens/AdminDashboardScreen";
import ChatListScreen from "~/screens/ChatListScreen";
import ChatScreen from "~/screens/ChatScreen";
import DeliveryListScreen from "~/screens/DeliveryListScreen";
import GeneralStaffDashboardScreen from "~/screens/GeneralStaffDashboardScreen";
import MyBasketScreen from "~/screens/MyBasketScreen";
import NotificationsScreen from "~/screens/NotificationsScreen";
import RestaurantStaffDashboardScreen from "~/screens/RestaurantStaffDashboardScreen";
import SettingsScreen from "~/screens/SettingsScreen";
import { useAuthStore } from "~/store";
import useTextLang from "~/utils/textLang";
import defaultStyles from "../config/styles";
// import { ParamListBase, RouteProp } from "@react-navigation/native";

import CustomerDashboardScreen from "../screens/CustomerDashboardScreen";
import { AppNavigatorParamList } from "./AppNavigator";
import routes from "./routes";
import { UserTypeChoice } from ".prisma/client";

interface TabBarIconProps {
  color: string;
  size: number;
}

const tabHomeIcon = ({ color, size }: TabBarIconProps) => (
  <MaterialCommunityIcons color={color} name="home" size={size} />
);

const tabDeliveryIcon = ({ color, size }: TabBarIconProps) => (
  <MaterialCommunityIcons color={color} name="truck" size={size} />
);

// const tabUserListIcon = ({ color, size }: TabBarIconProps) => (
//   <MaterialCommunityIcons color={color} name="account-group" size={size} />
// );

// const tabBarButton = (navigation, route: RouteProp<ParamListBase>) => (
//   <NewListingButton onPress={() => navigation.push(route)} />
// );

// const tabNotificationsIcon = ({ color, size }: TabBarIconProps) => (
//   <MaterialCommunityIcons color={color} name="bell" size={size} />
// );

const tabBasketIcon = ({ color, size }: TabBarIconProps) => (
  <MaterialCommunityIcons color={color} name="cart" size={size} />
);

const tabNotificationIcon = ({ color, size }: TabBarIconProps) => (
  <MaterialCommunityIcons color={color} name="bell" size={size} />
);

const tabChatIcon = ({ color, size }: TabBarIconProps) => (
  <MaterialCommunityIcons color={color} name="chat" size={size} />
  // <Ionicons name="md-settings-sharp" size={size} color={color} />
);

const tabSettingsIcon = ({ color, size }: TabBarIconProps) => (
  <Ionicons name="md-settings-sharp" size={size} color={color} />
);

export default function HomeTabNavigator() {
  const Tab = createBottomTabNavigator<AppNavigatorParamList>();
  const user = useAuthStore().user;
  const textLang = useTextLang();

  if (!user) return null;

  return (
    <Tab.Navigator
      screenOptions={{
        headerStyle: defaultStyles.headerStyle,
        headerShown: false,
        tabBarActiveTintColor: colors.primary,
        // tabBarLabel: "",
      }}
    >
      {user.type === UserTypeChoice.ADMIN ? (
        <Tab.Screen
          component={AdminDashboardScreen}
          name={routes.ADMIN_DASHBOARD}
          options={{
            headerShown: true,
            title: textLang("Home", "गृह"),
            headerTitle: textLang("AgriStudio", "अग्रीस्टुडियो"),
            tabBarIcon: tabHomeIcon,
          }}
        />
      ) : null}
      {user.type === UserTypeChoice.GENERAL_STAFF ? (
        <Tab.Screen
          component={GeneralStaffDashboardScreen}
          name={routes.GENERAL_STAFF_DASHBOARD}
          options={{
            headerShown: true,
            title: textLang("Home", "गृह"),
            headerTitle: textLang("AgriStudio", "अग्रीस्टुडियो"),
            tabBarIcon: tabHomeIcon,
          }}
        />
      ) : null}
      {user.type === UserTypeChoice.RESTAURANT_STAFF ? (
        <Tab.Screen
          component={RestaurantStaffDashboardScreen}
          name={routes.RESTAURANT_STAFF_DASHBOARD}
          options={{
            headerShown: true,
            title: textLang("Home", "गृह"),
            headerTitle: textLang("AgriStudio", "अग्रीस्टुडियो"),
            tabBarIcon: tabHomeIcon,
          }}
        />
      ) : null}
      {user.type === UserTypeChoice.DRIVER ? (
        <Tab.Screen
          component={DeliveryListScreen}
          name={routes.DELIVERY_LIST}
          options={{
            headerShown: true,
            title: textLang("Deliveries", "डिलिभरी"),
            tabBarIcon: tabDeliveryIcon,
          }}
        />
      ) : null}
      {user.type === UserTypeChoice.CUSTOMER ? (
        <>
          <Tab.Screen
            component={CustomerDashboardScreen}
            name={routes.CUSTOMER_DASHBOARD}
            options={{
              headerShown: true,
              title: textLang("Home", "गृह"),
              headerTitle: textLang("AgriStudio", "अग्रीस्टुडियो"),
              tabBarIcon: tabHomeIcon,
            }}
          />
          {/* <Tab.Screen
            component={MyBasketScreen}
            initialParams={{ noBackRoute: true }}
            name={routes.MY_BASKET}
            options={({
              navigation,
            }: {
              navigation: NativeStackNavigationProp<any>;
            }) => ({
              headerShown: true,
              title: textLang("My Basket", "मेरो झोला"),
              tabBarIcon: tabBasketIcon,
            })}
          /> */}
        </>
      ) : null}
      {user.type === UserTypeChoice.GENERAL_STAFF ? null : (
        <Tab.Screen
          component={NotificationsScreen}
          initialParams={{ noBackRoute: true }}
          name={routes.NOTIFICATIONS}
          options={{
            headerShown: true,
            title: textLang("Notifications", "सूचना"),
            tabBarIcon: tabNotificationIcon,
          }}
        />
      )}
      {user.type === UserTypeChoice.ADMIN ? (
        <Tab.Screen
          component={ChatListScreen}
          name={routes.CHAT_LIST}
          options={{
            headerShown: true,
            title: textLang("Chats", "च्याटहरू"),
            tabBarIcon: tabChatIcon,
          }}
        />
      ) : user.type === UserTypeChoice.GENERAL_STAFF ? null : (
        <Tab.Screen
          component={ChatScreen}
          name={routes.CHAT}
          initialParams={{ noPaddingBottom: true }}
          options={{
            headerShown: true,
            title: textLang("Chat with Admin", "व्यवस्थापकसँग च्याट"),
            tabBarIcon: tabChatIcon,
          }}
        />
      )}
      <Tab.Screen
        component={SettingsScreen}
        name={routes.SETTINGS}
        options={{
          headerShown: true,
          title: textLang("Settings", "सेटिङ्गहरू"),
          tabBarIcon: tabSettingsIcon,
        }}
      />
    </Tab.Navigator>
  );
}
