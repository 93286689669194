import { useState } from "react";
import {
  ActivityIndicator as NativeActivityIndicator,
  RefreshControl,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import colors from "@acme/common-utils/colors";

import AppTextInput from "~/components/AppTextInput";
import InfoText from "~/components/InfoText";
import { ListTitle } from "~/components/lists/ListTitle";
import VerticalSeparator from "~/components/lists/VerticalSeparator";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { trpc } from "~/utils/trpc";
import useDebounce from "~/utils/useDebounce";
import AppText from "../components/AppText";
import { ListItemSeparator } from "../components/lists";
import Screen from "../components/Screen";

export default function FarmHouseManageListScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.FARM_HOUSE_MANAGE_LIST
  >;
}) {
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useDebounce(
    () => {
      setSearchQuery(search);
    },
    [search],
    500,
  );

  const { data, isLoading, refetch } = trpc.farmHouse.list.useQuery({
    keyword: searchQuery,
  });

  return (
    <Screen
      className="flex-1 px-5"
      noSafeArea
      refreshControl={<RefreshControl refreshing={false} onRefresh={refetch} />}
    >
      <AppTextInput
        clearButtonMode="always"
        onChangeText={(text) => setSearch(text)}
        value={search}
        placeholder="Search"
        className="mt-2"
        noBorder
        textClassName="bg-gray-200 flex-1 px-3 py-2 text-base leading-[20px]"
      />
      <ListTitle name="Farmhouse" />
      {isLoading && (
        <View className="h-32 justify-center">
          <NativeActivityIndicator color={colors.primary} size="large" />
        </View>
      )}
      {data && (
        <>
          {data.length === 0 && (
            <View className="py-4">
              <InfoText text="There are no farmhouses" />
            </View>
          )}
          <View>
            <View className="rounded-xl bg-white">
              {data.map(
                (
                  { id, name, numberOfGuests, discountedPrice, price },
                  index,
                ) => (
                  <View key={id}>
                    {index === 0 ? null : (
                      <View className="pl-5">
                        <ListItemSeparator />
                      </View>
                    )}
                    <View className="px-5 pt-3">
                      <View>
                        <AppText className="text-lg">{name}</AppText>
                        <View className="flex-row justify-between">
                          <AppText className="text-mediumGray text-base">
                            No. of guests: {numberOfGuests}
                          </AppText>
                          <AppText className="text-mediumGray text-base">
                            <AppText className="line-through">
                              Rs. {price}
                            </AppText>{" "}
                            Rs. {discountedPrice}
                          </AppText>
                        </View>
                      </View>
                      <View className="flex-row items-start">
                        {[
                          {
                            name: "View\nBookings",
                            onPress: () => {
                              navigation.push(
                                routes.FARM_HOUSE_SPECIFIC_BOOKING_LIST,
                                {
                                  farmHouseId: id,
                                },
                              );
                            },
                            icon: "calendar",
                          },
                          {
                            name: "Create\nBooking",
                            onPress: () => {
                              navigation.push(routes.FARM_HOUSE_BOOKING, {
                                farmHouseId: id,
                              });
                            },
                            icon: "plus",
                          },
                          {
                            name: "Edit\nDetails",
                            onPress: () => {
                              navigation.push(routes.FARM_HOUSE_CREATE, {
                                farmHouseId: id,
                              });
                            },
                            icon: "pencil",
                          },
                        ].map(({ name, onPress, icon }) => (
                          <TouchableOpacity
                            key={name}
                            className="flex-1 items-center justify-center py-4"
                            onPress={onPress}
                          >
                            <MaterialCommunityIcons
                              name={icon as any}
                              size={24}
                              color={colors.primary}
                            />
                            <AppText className="text-primary mt-1 text-center">
                              {name}
                            </AppText>
                          </TouchableOpacity>
                        ))}
                      </View>
                    </View>
                  </View>
                ),
              )}
            </View>
          </View>
        </>
      )}
    </Screen>
  );
}
