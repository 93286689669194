import { useEffect, useState } from "react";
import { RefreshControl, TouchableHighlight, View } from "react-native";
import { Calendar } from "react-native-calendars";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import colors from "@acme/common-utils/colors";

import ActivityIndicator from "~/components/ActivityIndicator";
import Alert from "~/components/Alert";
import AppButton from "~/components/AppButton";
import AppText from "~/components/AppText";
import AppTextInput from "~/components/AppTextInput";
import { ImageList } from "~/components/ImageList";
import { ListItemSeparator } from "~/components/lists";
import { ListTitle } from "~/components/lists/ListTitle";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { useAuthStore } from "~/store";
import { toTitleCase } from "~/utils/toTitleCase";
import { trpc } from "~/utils/trpc";
import Screen from "../components/Screen";
import { BookingStatusChoice, UserTypeChoice } from ".prisma/client";

const selectedColorMap = {
  RED: "#ff7777",
  GREEN: "green",
  BLUE: "#7777ff",
  YELLOW: "#f7d100",
};

export default function FarmHouseBookingAdminScreen({
  navigation,
  route,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.FARM_HOUSE_BOOKING_ADMIN
  >;
  route: RouteProp<AppNavigatorParamList, routes.FARM_HOUSE_BOOKING_ADMIN>;
}) {
  const user = useAuthStore((state) => state.user)!;
  const [userFullName, setUserFullName] = useState("");
  const [userContact, setUserContact] = useState("");
  const utils = trpc.useUtils();
  const { farmHouseId: _farmHouseId, farmHouseBookingId } = route.params;

  const [farmHouseId, setFarmHouseId] = useState<string | null>(null);

  const {
    data: farmHouseData,
    isLoading: farmHouseDataLoading,
    refetch: farmHouseDataRefetch,
  } = trpc.farmHouse.bookingDetials.useQuery(
    { farmHouseId: farmHouseId || "" },
    {
      enabled: !!farmHouseId,
    },
  );

  const {
    data: bookingData,
    isLoading: isBookingLoading,
    refetch: bookingDataRefetch,
  } = trpc.farmHouse.getBooking.useQuery(
    { farmHouseBookingId: farmHouseBookingId || "" },
    {
      enabled: !!farmHouseBookingId,
    },
  );

  useEffect(() => {
    if (_farmHouseId) {
      setFarmHouseId(_farmHouseId);
    } else if (bookingData) {
      setFarmHouseId(bookingData.farmHouseId);
    }
  }, [bookingData, _farmHouseId]);

  const [markedDates, setMarkedDates] = useState<{
    [key: string]: {
      selected: boolean;
      selectedColor: string;
    };
  }>({});

  const { mutate: bookFarmHouse, isLoading: isBooking } =
    trpc.farmHouse.book.useMutation({
      onSuccess: () => {
        utils.farmHouse.invalidate();
        navigation.popToTop();
        Alert.alert("Success", "Farmhouse booked successfully");
      },
      onError: (err) => {
        Alert.alert("Error", err.message);
      },
    });

  const { mutate: setBookingStatus, isLoading: isSettingBookingStatus } =
    trpc.farmHouse.setBookingStatus.useMutation({
      onSuccess: () => {
        utils.farmHouse.invalidate();
        navigation.goBack();
        Alert.alert("Success", "Booking status updated successfully");
      },
      onError: (err) => {
        Alert.alert("Error", err.message);
      },
    });

  useEffect(() => {
    if (farmHouseData) {
      const markedDates = farmHouseData.bookings.map(
        ({ id, dates, bookingStatus }) => {
          if (bookingStatus !== BookingStatusChoice.APPROVED) {
            return {};
          }
          return dates.reduce(
            (acc, checkInDate) => ({
              ...acc,
              [checkInDate.toISOString().split("T")[0]]: {
                selected: true,
                selectedColor:
                  bookingStatus === BookingStatusChoice.APPROVED
                    ? selectedColorMap.RED
                    : selectedColorMap.GREEN,
              },
            }),
            {},
          );
        },
      );
      setMarkedDates(Object.assign({}, ...markedDates));
      if (bookingData) {
        const { dates } = bookingData;
        setMarkedDates((prev) => ({
          ...prev,
          ...dates.reduce(
            (acc, checkInDate) => ({
              ...acc,
              [checkInDate.toISOString().split("T")[0]]: {
                selected: true,
                selectedColor: selectedColorMap.BLUE,
              },
            }),
            {},
          ),
        }));
      }
    }
  }, [farmHouseData, bookingData]);

  return (
    <>
      <ActivityIndicator
        className="bg-iosBackground"
        visible={
          (farmHouseId && farmHouseDataLoading) ||
          isBooking ||
          isSettingBookingStatus ||
          (isBookingLoading && !!farmHouseBookingId)
        }
      />
      <Screen
        noSafeArea
        refreshControl={
          <RefreshControl
            refreshing={
              false
              // farmhouseDataRefetching ||
              // (bookingDataRefetching && !!farmHouseBookingId)
            }
            onRefresh={() => {
              farmHouseDataRefetch();
              bookingDataRefetch();
            }}
          />
        }
        className="p-5 pb-10"
      >
        {farmHouseData && (
          <>
            <AppText className="pt-2 text-center text-3xl font-bold">
              {farmHouseData.name}
            </AppText>
            <ListTitle name={"Images"} />
            <ImageList images={farmHouseData.images} />
            <View>
              <ListTitle name={"Infomation"} />
              <View className="overflow-hidden rounded-lg bg-white">
                <View>
                  <TouchableHighlight
                    underlayColor={colors.highlight}
                    accessibilityRole={"text"}
                  >
                    <View className="flex-row items-center justify-between">
                      <AppText className={`flex-1 py-3 pl-5 text-base`}>
                        Number of Guests
                      </AppText>
                      <AppText className="text-mediumGray flex-row justify-center pr-5 text-base">
                        {farmHouseData.numberOfGuests}
                      </AppText>
                    </View>
                  </TouchableHighlight>

                  <View className="pl-5">
                    <ListItemSeparator />
                  </View>
                </View>

                <View>
                  <TouchableHighlight
                    underlayColor={colors.highlight}
                    accessibilityRole={"text"}
                  >
                    <View className="">
                      <AppText className={`py-3 pb-1 pl-5 text-base`}>
                        Description
                      </AppText>
                      <AppText className="text-mediumGray px-5 pb-3 text-base">
                        {farmHouseData.description}
                      </AppText>
                    </View>
                  </TouchableHighlight>

                  <View className="pl-5">
                    <ListItemSeparator />
                  </View>
                </View>

                <View>
                  <TouchableHighlight
                    underlayColor={colors.highlight}
                    accessibilityRole={"text"}
                  >
                    <View className="">
                      <AppText className={`py-3 pb-1 pl-5 text-base`}>
                        Features
                      </AppText>
                      <AppText className="text-mediumGray px-5 pb-3 text-base">
                        {farmHouseData.features}
                      </AppText>
                    </View>
                  </TouchableHighlight>

                  <View className="pl-5">
                    <ListItemSeparator />
                  </View>
                </View>
                <View>
                  <TouchableHighlight
                    underlayColor={colors.highlight}
                    accessibilityRole={"text"}
                  >
                    <View className="flex-row items-center justify-between">
                      <AppText className={`flex-1 py-3 pl-5 text-base`}>
                        Price
                      </AppText>
                      <AppText className="text-mediumGray flex-row justify-center pr-5 text-base">
                        <AppText className="line-through">
                          Rs. {farmHouseData.price}
                        </AppText>{" "}
                        Rs. {farmHouseData.discountedPrice}
                      </AppText>
                    </View>
                  </TouchableHighlight>
                </View>
                <View>
                  <TouchableHighlight
                    underlayColor={colors.highlight}
                    accessibilityRole={"text"}
                  >
                    <View className="flex-row items-center justify-between">
                      <AppText className={`flex-1 py-3 pl-5 text-base`}>
                        Booking Status
                      </AppText>
                      <AppText className="text-mediumGray flex-row justify-center pr-5 text-base">
                        {toTitleCase(bookingData?.bookingStatus || "N/A")}
                      </AppText>
                    </View>
                  </TouchableHighlight>
                </View>
              </View>
            </View>

            {bookingData && user.type === UserTypeChoice.ADMIN ? (
              <View>
                <ListTitle name={"Booked by"} />
                <View className="overflow-hidden rounded-lg bg-white">
                  <View>
                    <TouchableHighlight
                      underlayColor={colors.highlight}
                      accessibilityRole={"text"}
                    >
                      <View className="flex-row items-center justify-between">
                        <AppText className={`flex-1 py-3 pl-5 text-base`}>
                          Full Name
                        </AppText>
                        <AppText className="text-mediumGray flex-row justify-center pr-5 text-base">
                          {bookingData.user?.fullName ||
                            bookingData.userFullName}
                        </AppText>
                      </View>
                    </TouchableHighlight>

                    <View className="pl-5">
                      <ListItemSeparator />
                    </View>
                  </View>
                  <View>
                    <TouchableHighlight
                      underlayColor={colors.highlight}
                      accessibilityRole={"text"}
                    >
                      <View className="flex-row items-center justify-between">
                        <AppText className={`flex-1 py-3 pl-5 text-base`}>
                          Contact
                        </AppText>
                        <AppText className="text-mediumGray flex-row justify-center pr-5 text-base">
                          {bookingData.user?.phone || bookingData?.userContact}
                        </AppText>
                      </View>
                    </TouchableHighlight>

                    <View className="pl-5">
                      <ListItemSeparator />
                    </View>
                  </View>
                  <View>
                    <TouchableHighlight
                      underlayColor={colors.highlight}
                      accessibilityRole={"text"}
                    >
                      <View className="flex-row items-center justify-between">
                        <AppText className={`flex-1 py-3 pl-5 text-base`}>
                          Payment Status
                        </AppText>
                        <AppText className="text-mediumGray flex-row justify-center pr-5 text-base">
                          {bookingData.isPaid ? "Complete" : "Not Paid"}
                        </AppText>
                      </View>
                    </TouchableHighlight>
                  </View>
                </View>
              </View>
            ) : null}

            <ListTitle name={"Booking Dates"} />
            <Calendar
              style={{
                height: 350,
              }}
              current={
                new Date(bookingData?.dates[0] || new Date().toISOString())
                  .toISOString()
                  .split("T")[0]
              }
              onDayPress={(day) => {
                if (user.type === UserTypeChoice.ADMIN && bookingData) {
                  return;
                }
                if (markedDates[day.dateString]) {
                  if (
                    markedDates[day.dateString].selectedColor ===
                      selectedColorMap.RED ||
                    markedDates[day.dateString].selectedColor ===
                      selectedColorMap.YELLOW
                  ) {
                    return;
                  }
                  const { [day.dateString]: _, ...rest } = markedDates;
                  setMarkedDates(rest);
                  return;
                }
                setMarkedDates({
                  ...markedDates,
                  [day.dateString]: {
                    selected: true,
                    selectedColor: selectedColorMap.BLUE,
                  },
                });
              }}
              markedDates={markedDates}
            />
            <View className="pt-5">
              {user.type === UserTypeChoice.CUSTOMER && !farmHouseBookingId ? (
                <AppButton
                  onPress={() => {
                    const dates = Object.entries(markedDates)
                      .filter(
                        ([_, { selectedColor }]) =>
                          selectedColor === selectedColorMap.BLUE,
                      )
                      .map(([date]) => new Date(date));
                    bookFarmHouse({ farmHouseId: farmHouseId || "", dates });
                  }}
                  title="Request Booking"
                />
              ) : null}
              {user.type === UserTypeChoice.ADMIN && (
                <>
                  {bookingData ? (
                    <>
                      {bookingData.bookingStatus ===
                      BookingStatusChoice.PENDING ? (
                        <>
                          <AppButton
                            onPress={() => {
                              setBookingStatus({
                                farmHouseBookingId: farmHouseBookingId || "",
                                bookingStatus: BookingStatusChoice.APPROVED,
                              });
                            }}
                            title="Approve Booking"
                          />
                          <AppButton
                            onPress={() => {
                              setBookingStatus({
                                farmHouseBookingId: farmHouseBookingId || "",
                                bookingStatus: BookingStatusChoice.REJECTED,
                              });
                            }}
                            color="bg-red"
                            title="Reject Booking"
                          />
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <ListTitle name={"User Info"} />
                      <View className="mb-2 rounded-lg bg-white px-5 py-2">
                        <AppTextInput
                          accessibilityLabel="Full Name"
                          onChangeText={(text) => setUserFullName(text)}
                          value={userFullName}
                          placeholder="Full Name"
                        />
                        <AppTextInput
                          accessibilityLabel="Contact"
                          onChangeText={(text) => setUserContact(text)}
                          value={userContact}
                          placeholder="Contact"
                        />
                        {/* <AppButton
                      onPress={() => {
                        setBookingStatus({
                          farmHouseBookingId: farmHouseBookingId || "",
                          bookingStatus: BookingStatusChoice.APPROVED,
                          userFullName,
                          userContact,
                        });
                      }}
                      title="Approve Booking"
                    /> */}
                      </View>
                      <AppButton
                        onPress={() => {
                          bookFarmHouse({
                            farmHouseId: farmHouseId || "",
                            dates: Object.entries(markedDates)
                              .filter(
                                ([_, { selectedColor }]) =>
                                  selectedColor === selectedColorMap.BLUE,
                              )
                              .map(([date]) => new Date(date)),
                            userFullName,
                            userContact,
                          });
                        }}
                        color="bg-primary"
                        title="Book"
                      />
                    </>
                  )}
                </>
              )}
            </View>
          </>
        )}
      </Screen>
    </>
  );
}
