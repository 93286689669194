import { DefaultTheme } from "@react-navigation/native";

import colors from "@acme/common-utils/colors";

export default {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: colors.primary,
    background: colors.iosBackground,
  },
};
