import { useEffect } from "react";
import { Image, LogBox, Platform, View } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { MenuProvider } from "react-native-popup-menu";
import * as NavigationBar from "expo-navigation-bar";
import * as Notifications from "expo-notifications";
import * as SplashScreen from "expo-splash-screen";
import { NavigationContainer } from "@react-navigation/native";
import { NativeWindStyleSheet } from "nativewind";

import colors from "@acme/common-utils/colors";

import AuthProvider from "~/auth/context";
import authStorage from "~/auth/storage";
import OfflineNotice from "~/components/OfflineNotice";
import AppNavigator from "~/navigation/AppNavigator";
import AuthNavigator from "~/navigation/AuthNavigator";
import navigationTheme from "~/navigation/navigationTheme";
import { navigationRef } from "~/navigation/routeNavigation";
import UploadScreen from "~/screens/AppProgress";
import { useAuthStore, useBearStore } from "~/store";
import { TRPCProvider } from "~/utils/trpc";

if (Platform.OS !== "web") {
  Blob.prototype[Symbol.toStringTag] = "Blob";
  File.prototype[Symbol.toStringTag] = "File";
}

LogBox.ignoreLogs(["Could not find image"]);
NavigationBar.setBackgroundColorAsync("white");

NativeWindStyleSheet.setOutput({
  default: "native",
});

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

SplashScreen.preventAutoHideAsync();

export default function App() {
  const user = useAuthStore((state) => state.user);
  const initialLoaded = useBearStore((state) => state.initialLoaded);
  const loadingVisible = useBearStore((state) => state.loadingVisible);
  const setLoadingVisible = useBearStore((state) => state.setLoadingVisible);
  const setLanguage = useBearStore((state) => state.setLanguage);

  useEffect(() => {
    if (initialLoaded) {
      setTimeout(() => {
        SplashScreen.hideAsync();
      }, 0);
    }
  }, [initialLoaded]);

  useEffect(() => {
    (async () => {
      setLanguage(await authStorage.getLanguage());
    })();
  }, []);

  return (
    <TRPCProvider>
      <MenuProvider>
        <AuthProvider>
          <OfflineNotice />
          <UploadScreen
            visible={loadingVisible}
            onDone={() => {
              setLoadingVisible(false);
            }}
            progress={1}
          />
          <NavigationContainer theme={navigationTheme} ref={navigationRef}>
            <GestureHandlerRootView className="flex-1 bg-white">
              <View
                className="mx-auto h-full w-full"
                style={{
                  maxWidth: Platform.OS === "web" ? 540 : undefined,
                  borderWidth: Platform.OS === "web" ? 1 : 0,
                  borderColor: colors.primary,
                  borderTopWidth: 0,
                  borderBottomWidth: 0,
                }}
              >
                {user === undefined ? (
                  <View className="flex-1">
                    <Image
                      source={require("~/assets/splash.png")}
                      className="absolute h-full w-full"
                    />
                  </View>
                ) : user !== null ? (
                  <AppNavigator />
                ) : (
                  <AuthNavigator />
                )}
              </View>
            </GestureHandlerRootView>
          </NavigationContainer>
        </AuthProvider>
      </MenuProvider>
    </TRPCProvider>
  );
}
