import { useEffect } from "react";
import { Image, Keyboard, TouchableOpacity, View } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import * as Yup from "yup";

import colors from "@acme/common-utils/colors";

import Alert from "~/components/Alert";
import AppButton from "~/components/AppButton";
import AppImage from "~/components/AppImage";
import AppText from "~/components/AppText";
import InfoText from "~/components/InfoText";
import { ListTitle } from "~/components/lists/ListTitle";
import { storageDomain } from "~/config/settings";
import { RouterOutputs, trpc } from "~/utils/trpc";
import ActivityIndicator from "../components/ActivityIndicator";
import {
  ErrorMessage,
  Form,
  FormField,
  SubmitButton,
} from "../components/forms";
import FormNativePicker from "../components/forms/FormNativePicker";
import Screen from "../components/Screen";
import { AppNavigatorParamList } from "../navigation/AppNavigator";
import routes from "../navigation/routes";
import { toTitleCase } from "../utils/toTitleCase";
import { DietaryChoice } from ".prisma/client";

export default function SubscriptionCreateScreen({
  navigation,
  route,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.SUBSCRIPTION_CREATE
  >;
  route: RouteProp<AppNavigatorParamList, routes.SUBSCRIPTION_CREATE>;
}) {
  const utils = trpc.useUtils();
  const { subscriptionId } = route.params || {};
  const { data: subscriptionData, isLoading: subscriptionLoading } =
    trpc.subscription.get.useQuery(
      {
        subscriptionId: subscriptionId || "",
      },
      {
        enabled: !!subscriptionId,
      },
    );
  const queryClient = useQueryClient();

  const {
    mutate: createSubscription,
    isLoading: createSubscriptionLoading,
    error,
  } = trpc.subscription.create.useMutation({
    onSuccess: () => {
      Alert.alert("Success", "Subscription saved successfully");
      utils.invalidate();
      navigation.pop();
    },
    onError(error) {
      Alert.alert("Error", error.message);
    },
  });

  const { data: productsData, isLoading: productsLoading } =
    trpc.product.list.useQuery(
      {},
      {
        enabled: !!subscriptionId,
      },
    );

  const { mutate: deleteSubscription, isLoading: deleteSubscriptionLoading } =
    trpc.subscription.delete.useMutation({
      onSuccess: () => {
        Alert.alert("Success", "Subscription deleted successfully");
        utils.invalidate();
        navigation.pop();
      },
      onError(error) {
        Alert.alert("Error", error.message);
      },
    });

  const handleSubmit = async (input: {
    [K in keyof CreateSubscriptionInput]: NonNullable<
      CreateSubscriptionInput[K]
    >;
  }) => {
    Keyboard.dismiss();
    createSubscription({
      ...input,
      subscriptionId,
    });
  };

  useEffect(() => {
    if (subscriptionId) {
      navigation.setOptions({
        title: "Edit",
      });
    }
  }, [subscriptionId]);

  const { mutate: addToSubscription } =
    trpc.product.addToSubscription.useMutation({
      onError() {
        utils.invalidate();
      },
      onMutate(variables) {
        const getProductsQueryKey = getQueryKey(trpc.subscription.get);
        queryClient.setQueriesData<RouterOutputs["subscription"]["get"]>(
          { queryKey: getProductsQueryKey },
          (oldData) => {
            if (!oldData) {
              return oldData;
            }
            if (oldData.id === variables.subscriptionId) {
              return {
                ...oldData,
                defaultProducts: [
                  ...oldData.defaultProducts,
                  {
                    id: Math.random().toString(),
                    product: {
                      id: variables.productId,
                    },
                  },
                ],
              };
            }
            return oldData;
          },
        );
      },
    });

  const { mutate: removeFromSubscription } =
    trpc.product.removeFromSubscription.useMutation({
      onError() {
        utils.invalidate();
      },
      onMutate(variables) {
        const getProductsQueryKey = getQueryKey(trpc.subscription.get);
        queryClient.setQueriesData<RouterOutputs["subscription"]["get"]>(
          { queryKey: getProductsQueryKey },
          (oldData) => {
            if (!oldData) {
              return oldData;
            }
            if (oldData.id === variables.subscriptionId) {
              return {
                ...oldData,
                defaultProducts: oldData.defaultProducts.filter(
                  (defaultBasketProduct) =>
                    defaultBasketProduct.product.id !== variables.productId,
                ),
              };
            }
            return oldData;
          },
        );
      },
    });

  return (
    <>
      <ActivityIndicator
        visible={
          deleteSubscriptionLoading ||
          createSubscriptionLoading ||
          (subscriptionLoading && !!subscriptionId) ||
          (productsLoading && !!subscriptionId)
        }
      />
      <Screen backgroundColor="white" className="px-5 py-2" noSafeArea>
        {(!subscriptionId || subscriptionData !== undefined) && (
          <Form
            initialValues={
              subscriptionData
                ? Object.keys(subscriptionData).reduce((acc, key) => {
                    if (
                      Object.prototype.hasOwnProperty.call(
                        formInitialValues,
                        key,
                      )
                    ) {
                      return { ...acc, [key]: subscriptionData[key] };
                    }
                    return acc;
                  }, formInitialValues)
                : formInitialValues
            }
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <ErrorMessage
              error={error?.message || "Invalid phone or password"}
              visible={!!error}
            />
            <FormField
              autoCapitalize="words"
              name="name"
              label="Subscription Name"
              keyboardType="default"
            />
            <FormNativePicker
              items={Object.values(DietaryChoice).map((value) => ({
                value,
                label: toTitleCase(value),
              }))}
              name="dietary"
              label="Dietary"
            />
            <FormField
              number
              keyboardType="number-pad"
              name="price"
              label="Price per delivery"
            />
            {subscriptionData && (
              <>
                <ListTitle name="Default Products" />
                {productsData && (
                  <>
                    <View>
                      <View className="flex-row flex-wrap rounded-xl bg-white">
                        {productsData.map((product, index) => (
                          <TouchableOpacity
                            key={product.id}
                            accessibilityRole="button"
                            onPress={() => {
                              subscriptionData.defaultProducts.some(
                                (defaultProduct) =>
                                  defaultProduct.product.id === product.id,
                              )
                                ? removeFromSubscription({
                                    subscriptionId: subscriptionData.id,
                                    productId: product.id,
                                  })
                                : addToSubscription({
                                    subscriptionId: subscriptionData.id,
                                    productId: product.id,
                                  });
                            }}
                            className="border-mediumGray w-1/3"
                          >
                            <View className="items-center justify-center px-5 py-3">
                              <View
                                className={`${
                                  subscriptionData.defaultProducts.some(
                                    (defaultProduct) =>
                                      defaultProduct.product.id === product.id,
                                  )
                                    ? "bg-primary"
                                    : "bg-white"
                                } border-primary absolute right-5 top-5 z-10 self-end rounded-full border-2`}
                              >
                                <MaterialCommunityIcons
                                  name="check"
                                  size={16}
                                  color={colors.white}
                                />
                              </View>
                              <View className="m-2 h-24 w-24">
                                {product.image ? (
                                  <AppImage
                                    source={{
                                      uri: `${storageDomain}/${product.image}`,
                                    }}
                                    className="h-24 w-24"
                                  />
                                ) : (
                                  <Image
                                    source={require("../assets/default-product-avatar.png")}
                                    className="h-24 w-24"
                                  />
                                )}
                              </View>
                              <View className="flex-1">
                                <AppText className="text-center text-lg">
                                  {product.name}
                                </AppText>
                              </View>
                            </View>
                          </TouchableOpacity>
                        ))}
                      </View>
                    </View>
                    {productsData.length === 0 && (
                      <InfoText className="my-10" text="No products" />
                    )}
                  </>
                )}
              </>
            )}
            <SubmitButton title="Save Subscription" />
            {subscriptionData && (
              <AppButton
                color="bg-red"
                title="Delete Subscription"
                onPress={() => {
                  Alert.alert(
                    "Delete Subscription",
                    "Are you sure you want to delete this subscription?",
                    [
                      {
                        text: "Cancel",
                        style: "cancel",
                      },
                      {
                        text: "Delete",
                        style: "destructive",
                        onPress: () => {
                          deleteSubscription({
                            subscriptionId: subscriptionId || "",
                          });
                        },
                      },
                    ],
                  );
                }}
              />
            )}
          </Form>
        )}
      </Screen>
    </>
  );
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label("Subscription Name"),
  dietary: Yup.string()
    .oneOf(Object.values(DietaryChoice))
    .required()
    .label("Dietary"),
  price: Yup.string().required().label("Price"),
});

const formInitialValues: CreateSubscriptionInput = {
  name: "",
  dietary: null,
  price: 0,
};

type CreateSubscriptionInput = {
  name: string;
  dietary: DietaryChoice | null;
  price: number;
};
