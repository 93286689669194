import { useEffect } from "react";
import { Image, RefreshControl, View } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import Alert from "~/components/Alert";
import AppListItems from "~/components/AppListItems";
import { storageDomain } from "~/config/settings";
import { useAuthStore } from "~/store";
import { toTitleCase } from "~/utils/toTitleCase";
import { trpc } from "~/utils/trpc";
import ActivityIndicator from "../components/ActivityIndicator";
import AppImage from "../components/AppImage";
import AppText from "../components/AppText";
import Screen from "../components/Screen";
import { AppNavigatorParamList } from "../navigation/AppNavigator";
import routes from "../navigation/routes";

export default function ProductScreen({
  route,
  navigation,
}: {
  route: RouteProp<AppNavigatorParamList, routes.PRODUCT>;
  navigation: NativeStackNavigationProp<AppNavigatorParamList, routes.PRODUCT>;
}) {
  const { productId } = route.params;
  const user = useAuthStore().user!;

  const { data, isLoading, refetch } = trpc.product.get.useQuery({
    productId,
  });

  useEffect(() => {
    if (data) {
      navigation.setOptions({
        headerTitle: data.name,
      });
    }
  }, [data]);

  const utils = trpc.useUtils();

  const { mutate: addToBasket, isLoading: isLoadingAddToBasket } =
    trpc.product.addToBasket.useMutation({
      onSuccess() {
        navigation.goBack();
        Alert.alert("Success", "Product added to basket");
        utils.invalidate();
      },
    });

  const { mutate: removeFromBasket, isLoading: isLoadingRemoveFromBasket } =
    trpc.product.removeFromBasket.useMutation({
      onSuccess() {
        navigation.goBack();
        Alert.alert("Success", "Product removed from basket");
        utils.invalidate();
      },
    });

  return (
    <>
      <ActivityIndicator
        className="bg-iosBackground"
        visible={isLoading || isLoadingAddToBasket || isLoadingRemoveFromBasket}
      />
      <Screen
        className="px-5"
        noSafeArea
        refreshControl={
          <RefreshControl refreshing={false} onRefresh={refetch} />
        }
      >
        {data && (
          <>
            <View className="flex-1 pt-5">
              <View className="items-center">
                <View className="h-24 w-24">
                  {data.image ? (
                    <AppImage
                      source={{
                        uri: `${storageDomain}/${data.image}`,
                      }}
                      className="mb-4 h-24 w-24 rounded-full"
                    />
                  ) : (
                    <Image
                      source={require("../assets/default-product-avatar.png")}
                      className="mb-4 h-24 w-24 rounded-full"
                    />
                  )}
                </View>
                <AppText className="text-3xl font-bold">{data.name}</AppText>
              </View>
              <AppListItems
                items={[
                  {
                    name: "Info",
                    options: [
                      {
                        title: "Price",
                        subTitle: `Rs. ${data.price}`,
                      },
                      {
                        title: "Dietary",
                        subTitle: toTitleCase(data.dietary),
                      },
                    ],
                  },
                ]}
              />
            </View>
          </>
        )}
      </Screen>
    </>
  );
}
