import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Dimensions,
  Image,
  Platform,
  ScrollView,
  StatusBar,
  TouchableWithoutFeedback,
  View,
} from "react-native";

import colors from "@acme/common-utils/colors";

import { storageDomain } from "../config/settings";
import AppImage from "./AppImage";

export function ImageList({
  images,
  className,
  style,
  noPictureFullScreen,
}: {
  images: string[];
  className?: string;
  style?: object;
  noPictureFullScreen?: boolean;
}) {
  const [imageIndex, setImageIndex] = useState(-1);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState<number>(
    Dimensions.get("window").width - 32,
  );

  useEffect(() => {
    if (imageIndex !== -1) {
      StatusBar.setBarStyle("light-content");
      if (Platform.OS === "android") {
        StatusBar.setBackgroundColor(colors.black);
      }
    } else {
      StatusBar.setBarStyle("dark-content");
      if (Platform.OS === "android") {
        StatusBar.setBackgroundColor("transparent");
      }
    }
  }, [imageIndex]);

  return (
    <View className="mt-1">
      {images.length > 0 ? (
        <>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            showsHorizontalScrollIndicator={false}
            horizontal
            pagingEnabled
            className="flex-row"
            onContentSizeChange={(width, height) => {
              setWidth(width);
            }}
            contentContainerStyle={{
              width: `${images.length * 100}%`,
              alignItems: "stretch",
            }}
            onMomentumScrollEnd={(event) => {
              setPageNumber(
                Math.round(
                  event.nativeEvent.contentOffset.x /
                    event.nativeEvent.layoutMeasurement.width,
                ) + 1,
              );
            }}
          >
            {images.map((url, index) => (
              <EachImage
                width={width / images.length}
                key={url}
                url={url}
                index={index}
                setImageIndex={setImageIndex}
                className={className}
                style={style}
                noPictureFullScreen={noPictureFullScreen}
              />
            ))}
          </ScrollView>
          {images.length > 1 ? (
            <View className="my-1 flex-row justify-center">
              {images.map((url, index) => (
                <View
                  key={url}
                  className={`mx-1 h-2 w-2 rounded-full ${
                    pageNumber === index + 1 ? "bg-primary" : "bg-highlight"
                  }`}
                />
              ))}
            </View>
          ) : null}
        </>
      ) : null}
    </View>
  );
}

function EachImage({
  width,
  url,
  index,
  setImageIndex,
  className,
  style,
  noPictureFullScreen,
}: {
  width: number;
  url: string;
  index: number;
  setImageIndex: (index: number) => void;
  className?: string;
  style?: object;
  noPictureFullScreen?: boolean;
}) {
  const [imgHeight, setImgHeight] = useState<number>(0);
  const [contain, setContain] = useState<boolean>(false);
  const [dummyShown, setDummyShown] = useState<boolean>(true);
  useLayoutEffect(() => {
    Image.getSize(`${storageDomain}/${url}`, (widthw, heightw) => {
      const ratio = widthw / heightw;
      if (ratio > 1.25) {
        setImgHeight((Dimensions.get("window").width - 32) / ratio);
      } else {
        setImgHeight(Dimensions.get("window").width - 32);
        setContain(true);
      }
      setDummyShown(false);
    });
  }, []);

  return (
    <TouchableWithoutFeedback
      key={url}
      delayPressIn={5}
      delayPressOut={5}
      delayLongPress={5}
      disabled={noPictureFullScreen}
      className="flex-1 rounded-xl"
      onPress={() => {
        setImageIndex(index);
      }}
    >
      {/* <View className="justify-center rounded-xl bg-black"> */}
      <View
        className={`justify-center overflow-hidden rounded-xl bg-gray-100 ${className}`}
        style={style}
      >
        {dummyShown ? (
          <View
            style={{
              height: width,
            }}
          />
        ) : null}

        <View
          style={{
            width,
            height: imgHeight,
          }}
        >
          <AppImage
            contain={contain}
            source={{
              uri: `${storageDomain}/${url}`,
            }}
            className="h-full rounded-xl"
          />
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}
