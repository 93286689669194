import { useEffect } from "react";
import {
  Button,
  Image,
  RefreshControl,
  TouchableHighlight,
  View,
} from "react-native";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import InfoText from "~/components/InfoText";
import { storageDomain } from "~/config/settings";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import useTextLang from "~/utils/textLang";
import { trpc } from "~/utils/trpc";
import ActivityIndicator from "../components/ActivityIndicator";
import AppImage from "../components/AppImage";
import AppText from "../components/AppText";
import Screen from "../components/Screen";

export default function UserBasketScreen({
  navigation,
  route,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.USER_BASKET
  >;
  route: RouteProp<AppNavigatorParamList, routes.USER_BASKET>;
}) {
  const { userId } = route.params;

  const textLang = useTextLang();

  const { data, isLoading, refetch } =
    trpc.product.getUserBasketProducts.useQuery({
      userId,
    });

  return (
    <>
      <ActivityIndicator className="bg-iosBackground" visible={isLoading} />
      <Screen
        backgroundColor="iosBackground"
        className="p-5 pb-5"
        noSafeArea
        refreshControl={
          <RefreshControl refreshing={false} onRefresh={refetch} />
        }
      >
        {data && (
          <>
            <View className="flex-1">
              <View className="flex-row flex-wrap rounded-xl">
                {data.map(({ product }, index) => (
                  <View
                    key={product.id}
                    className="border-mediumGray w-1/3 bg-white"
                  >
                    <View className="border-iosBackground items-center justify-center border-[1px] px-3 py-3">
                      <View className="m-2 h-24 w-24">
                        {product.image ? (
                          <AppImage
                            source={{
                              uri: `${storageDomain}/${product.image}`,
                            }}
                            className="h-24 w-24"
                          />
                        ) : (
                          <Image
                            source={require("../assets/default-product-avatar.png")}
                            className="h-24 w-24"
                          />
                        )}
                      </View>
                      <AppText className="text-center text-lg">
                        {product.name}
                      </AppText>
                    </View>
                  </View>
                ))}
                {data.length === 0 && (
                  <View className="flex-1 py-4 text-center">
                    <InfoText
                      text={textLang(
                        "No items found",
                        "कुनै पनि वस्तुहरू पाइएन",
                      )}
                    />
                  </View>
                )}
              </View>
            </View>
          </>
        )}
      </Screen>
    </>
  );
}
