import { useEffect, useState } from "react";
import { Keyboard } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import * as Yup from "yup";

import Alert from "~/components/Alert";
import { trpc } from "~/utils/trpc";
import { uploadImages } from "~/utils/upload";
import ActivityIndicator from "../components/ActivityIndicator";
import {
  ErrorMessage,
  Form,
  FormField,
  FormImagePicker,
  SubmitButton,
} from "../components/forms";
import Screen from "../components/Screen";
import { AppNavigatorParamList } from "../navigation/AppNavigator";
import routes from "../navigation/routes";

export default function FarmHouseCreateScreen({
  navigation,
  route,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.FARM_HOUSE_CREATE
  >;
  route: RouteProp<AppNavigatorParamList, routes.FARM_HOUSE_CREATE>;
}) {
  const utils = trpc.useUtils();
  const { farmHouseId } = route.params || {};
  const [artificialLoading, setArtificialLoading] = useState(false);

  const { data: farmHouseData, isLoading: farmHouseLoading } =
    trpc.farmHouse.get.useQuery({
      farmHouseId: farmHouseId || "",
    });

  const {
    mutate: createFarmHouse,
    isLoading: createFarmHouseLoading,
    error,
  } = trpc.farmHouse.create.useMutation({
    onSuccess: () => {
      Alert.alert("Success", "Farmhouse saved successfully");
      utils.invalidate();
      setArtificialLoading(false);
      navigation.pop();
    },
    onError(error) {
      setArtificialLoading(false);
      Alert.alert("Error", error.message);
    },
  });
  const { mutateAsync: getSignedUrl } = trpc.s3.getSignedUrl.useMutation();

  const handleSubmit = async (input: {
    [K in keyof CreateFarmHouseInput]: NonNullable<CreateFarmHouseInput[K]>;
  }) => {
    setArtificialLoading(true);
    Keyboard.dismiss();
    createFarmHouse({
      ...input,
      farmHouseId,
      images: await uploadImages(input.images, getSignedUrl, () => {}),
    });
  };

  useEffect(() => {
    if (farmHouseId) {
      navigation.setOptions({
        title: "Edit",
      });
    }
  }, [farmHouseId]);

  return (
    <>
      <ActivityIndicator
        visible={
          artificialLoading || createFarmHouseLoading || farmHouseLoading
        }
      />
      <Screen backgroundColor="white" className="px-5 py-2" noSafeArea>
        {farmHouseData !== undefined && (
          <Form
            initialValues={
              farmHouseData
                ? Object.keys(farmHouseData).reduce((acc, key) => {
                    if (
                      Object.prototype.hasOwnProperty.call(
                        formInitialValues,
                        key,
                      )
                    ) {
                      return { ...acc, [key]: farmHouseData[key] };
                    }
                    return acc;
                  }, formInitialValues)
                : formInitialValues
            }
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <ErrorMessage
              error={error?.message || "Invalid phone or password"}
              visible={!!error}
            />
            <FormImagePicker label="Images" name="images" />
            <FormField
              autoCapitalize="words"
              name="name"
              label="Name"
              keyboardType="default"
            />
            <FormField
              autoCapitalize="words"
              name="description"
              label="Description"
              multiline
              keyboardType="default"
            />
            <FormField
              autoCapitalize="words"
              name="features"
              label="Features"
              multiline
              keyboardType="default"
            />
            <FormField
              number
              keyboardType="number-pad"
              name="price"
              label="Price"
            />
            <FormField
              number
              keyboardType="number-pad"
              name="discountedPrice"
              label="Discounted Price"
            />
            <FormField
              number
              keyboardType="number-pad"
              name="numberOfGuests"
              label="Number of Guests"
            />
            <SubmitButton title="Save Farmhouse" />
          </Form>
        )}
      </Screen>
    </>
  );
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label("Farmhouse Name"),
  features: Yup.string().required().label("Features"),
  description: Yup.string().required().label("Description"),
  price: Yup.string().required().label("Price"),
  numberOfGuests: Yup.string().required().label("Number of Guests"),
  discountedPrice: Yup.string().required().label("Discounted Price"),
});

const formInitialValues: CreateFarmHouseInput = {
  name: "",
  features: "",
  description: "",
  price: 0,
  numberOfGuests: 0,
  discountedPrice: 0,
  images: [],
};

type CreateFarmHouseInput = {
  name: string;
  features: string;
  description: string;
  price: number;
  numberOfGuests: number;
  discountedPrice: number;
  images: string[];
};
