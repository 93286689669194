import { useCallback, useEffect, useState } from "react";
import {
  Dimensions,
  FlatList,
  ActivityIndicator as NativeActivityIndicator,
  TouchableHighlight,
  View,
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import colors from "@acme/common-utils/colors";

import ActivityIndicator from "~/components/ActivityIndicator";
import InfoText from "~/components/InfoText";
import { useAuthStore } from "~/store";
import { RouterOutputs, trpc } from "~/utils/trpc";
import AppText from "../components/AppText";
import { ListItemSeparator } from "../components/lists";
import Screen from "../components/Screen";
import { AppNavigatorParamList } from "../navigation/AppNavigator";
import routes from "../navigation/routes";
import {
  NotificationDataTypeChoice,
  NotificationTypeChoice,
  UserTypeChoice,
} from ".prisma/client";

dayjs.extend(relativeTime);

export default function NotificationsScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.NOTIFICATIONS
  >;
}) {
  const user = useAuthStore((state) => state.user)!;

  const utils = trpc.useUtils();

  const { data, isLoading, refetch } = trpc.notification.list.useQuery({});

  useFocusEffect(
    useCallback(() => {
      utils.notification.list.invalidate();
    }, []),
  );

  const renderItem = ({
    item: notification,
    index,
  }: {
    item: RouterOutputs["notification"]["list"][number];
    index: number;
  }) => (
    <>
      {index !== 0 && <ListItemSeparator height={1} />}
      <TouchableHighlight
        underlayColor={colors.highlight}
        onPress={() => {
          switch (notification.dataType) {
            // case NotificationDataTypeChoice.DELIVERY:
            //   if (!notification.dataId) return;
            //   navigation.push(routes., {
            //     deliveryId: notification.dataId,
            //   });
            //   break;
            case NotificationDataTypeChoice.FARMHOUSE_BOOKING:
              if (!notification.dataId) return;
              if (user.type === UserTypeChoice.ADMIN) {
                navigation.push(routes.FARM_HOUSE_BOOKING_ADMIN, {
                  farmHouseBookingId: notification.dataId,
                });
              } else {
                navigation.push(routes.FARM_HOUSE_BOOKING, {
                  farmHouseBookingId: notification.dataId,
                });
              }
              break;
            case NotificationDataTypeChoice.SUBSCRIPTION:
              if (!notification.dataId) return;
              navigation.push(routes.SUBSCRIPTION_LIST);
              break;
            case NotificationDataTypeChoice.RESTAURANT_ORDER:
              if (!notification.dataId) return;
              navigation.push(routes.RESTAURANT_ORDER_SUMMARY, {
                restaurantOrderId: notification.dataId,
              });
              break;
            default:
              break;
          }
        }}
        className="flex-row items-center justify-between px-4 py-2"
      >
        <View className="ml-2 flex-1">
          <View className="flex-row items-center justify-between">
            <AppText className="font-sans-medium text-lg">
              {notification.title}
            </AppText>
          </View>
          <View className="flex-row items-center justify-between">
            <AppText className="flex-1 text-sm text-gray-500">
              {notification.body}
            </AppText>
            <AppText className="text-mediumGray">
              {dayjs(notification.createdAt).fromNow()}
            </AppText>
          </View>
        </View>
      </TouchableHighlight>
    </>
  );

  return (
    <>
      <ActivityIndicator visible={isLoading} />
      <Screen noKeyboardAwareScroll backgroundColor="white" noSafeArea>
        <FlatList
          // onEndReachedThreshold={0.5}
          // onEndReached={() => {}}
          // ListFooterComponent={
          //   isLoading ? (
          //     <View className="items-center justify-center py-40">
          //       <NativeActivityIndicator />
          //     </View>
          //   ) : null
          // }
          renderItem={renderItem}
          keyExtractor={(listing) => `${listing?.id.toString()}`}
          ListEmptyComponent={
            !isLoading ? (
              <InfoText className="my-10" text="No notifications" />
            ) : null
          }
          onRefresh={refetch}
          refreshing={false}
          data={data}
        />
      </Screen>
    </>
  );
}
