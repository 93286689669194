import {
  Button,
  Image,
  RefreshControl,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";

import colors from "@acme/common-utils/colors";

import AppButton from "~/components/AppButton";
import NotSubscribed from "~/components/NotSubscribed";
import { storageDomain } from "~/config/settings";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { useAuthStore } from "~/store";
import { RouterOutputs, trpc } from "~/utils/trpc";
import ActivityIndicator from "../components/ActivityIndicator";
import AppImage from "../components/AppImage";
import AppText from "../components/AppText";
import Screen from "../components/Screen";

export default function MyBasketScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.MY_BASKET
  >;
}) {
  const user = useAuthStore((state) => state.user)!;
  const queryClient = useQueryClient();

  const {
    data: dietryProducts,
    isLoading: dietryProductsLoading,
    refetch: dietryProductsRefetch,
  } = trpc.product.listDietaryProducts.useQuery();

  const utils = trpc.useUtils();

  const { mutate: addToBasket, isLoading: isAddingToBasket } =
    trpc.product.addToBasket.useMutation({
      onError() {
        utils.invalidate();
      },
      onMutate(variables) {
        const getProductsQueryKey = getQueryKey(
          trpc.product.listDietaryProducts,
        );
        queryClient.setQueriesData<
          RouterOutputs["product"]["listDietaryProducts"]
        >({ queryKey: getProductsQueryKey }, (oldData) => {
          if (!oldData) {
            return oldData;
          }
          return oldData.map((product) => {
            if (product.id === variables.productId) {
              return {
                ...product,
                isInBasket: true,
              };
            }
            return product;
          });
        });
      },
    });

  const { mutate: removeFromBasket, isLoading: isRemovingFromBasket } =
    trpc.product.removeFromBasket.useMutation({
      onError() {
        utils.invalidate();
      },
      onMutate(variables) {
        const getProductsQueryKey = getQueryKey(
          trpc.product.listDietaryProducts,
        );
        queryClient.setQueriesData<
          RouterOutputs["product"]["listDietaryProducts"]
        >({ queryKey: getProductsQueryKey }, (oldData) => {
          if (!oldData) {
            return oldData;
          }
          return oldData.map((product) => {
            if (product.id === variables.productId) {
              return {
                ...product,
                isInBasket: false,
              };
            }
            return product;
          });
        });
      },
    });

  return (
    <>
      <ActivityIndicator visible={dietryProductsLoading} />
      <Screen
        backgroundColor="iosBackground"
        className="flex-1 p-5"
        noSafeArea
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={dietryProductsRefetch}
          />
        }
      >
        {user.subscription ? (
          <>
            {dietryProducts && (
              <>
                <View className="flex-1">
                  <View className="flex-row flex-wrap rounded-xl">
                    {dietryProducts
                      .filter(({ isShown }) => isShown)
                      .map((product, index) => (
                        <TouchableOpacity
                          key={product.id}
                          accessibilityRole="button"
                          onPress={() => {
                            product.isInBasket
                              ? removeFromBasket({ productId: product.id })
                              : addToBasket({ productId: product.id });
                          }}
                          className="border-mediumGray w-1/3 bg-white"
                        >
                          <View className="items-center justify-center px-5 py-3">
                            <View
                              className={`${
                                product.isInBasket ? "bg-primary" : "bg-white"
                              } border-primary absolute right-5 top-5 z-10 self-end rounded-full border-2`}
                            >
                              <MaterialCommunityIcons
                                name="check"
                                size={16}
                                color={colors.white}
                              />
                            </View>
                            <View className="m-2 h-24 w-24">
                              {product.image ? (
                                <AppImage
                                  source={{
                                    uri: `${storageDomain}/${product.image}`,
                                  }}
                                  className="h-24 w-24"
                                />
                              ) : (
                                <Image
                                  source={require("../assets/default-product-avatar.png")}
                                  className="h-24 w-24"
                                />
                              )}
                            </View>
                            <AppText className="text-center text-lg">
                              {product.name}
                            </AppText>
                          </View>
                        </TouchableOpacity>
                      ))}
                  </View>
                </View>
              </>
            )}
          </>
        ) : (
          <View className="flex-1 items-center justify-center">
            <NotSubscribed />
          </View>
        )}
      </Screen>
      {user.subscription ? (
        <View className="bg-white">
          <AppButton
            disabled
            className="m-3"
            title={
              isRemovingFromBasket || isAddingToBasket ? "Saving..." : "Saved"
            }
            color={"bg-lightGray2"}
            textColor="text-mediumGray"
            onPress={() => {}}
          />
        </View>
      ) : null}
    </>
  );
}
