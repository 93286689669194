import { useState } from "react";
import { View } from "react-native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import Alert from "~/components/Alert";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { trpc } from "~/utils/trpc";
import { uploadImages } from "~/utils/upload";
import ActivityIndicator from "../components/ActivityIndicator";
import {
  ErrorMessage,
  Form,
  FormImagePicker,
  FormSingleImagePicker,
  SubmitButton,
} from "../components/forms";
import Screen from "../components/Screen";

export default function FlyerCreateScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.FLYER_CREATE
  >;
}) {
  const [artificialLoading, setArtificialLoading] = useState(false);
  const utils = trpc.useUtils();

  const [error, setError] = useState("");

  const { mutate: createFlyer, isLoading: isCreatingFlyer } =
    trpc.flyer.create.useMutation({
      onSuccess: () => {
        setArtificialLoading(false);
        navigation.goBack();
        Alert.alert("Success", "Flyer created successfully");
      },
      onError: (error) => {
        setArtificialLoading(false);
        Alert.alert("Error", error.message);
      },

      onSettled: () => {
        utils.invalidate();
      },
    });
  const { mutateAsync: getSignedUrl } = trpc.s3.getSignedUrl.useMutation();

  const handleSubmit = async (values: FormInitialValues) => {
    setError("");
    setArtificialLoading(true);

    createFlyer({
      image: values.image
        ? (await uploadImages([values.image], getSignedUrl, () => {}))[0]
        : "",
    });
  };

  return (
    <>
      <ActivityIndicator visible={artificialLoading || isCreatingFlyer} />
      <Screen noSafeArea backgroundColor="white">
        <View className="flex-grow px-5 py-2">
          <ErrorMessage error={error} visible={!!error} />
          <Form initialValues={formInitialValues} onSubmit={handleSubmit}>
            <FormContent />
          </Form>
        </View>
      </Screen>
    </>
  );
}

function FormContent() {
  return (
    <>
      <FormSingleImagePicker fullWidth label="FLyer Image" name="image" />
      <SubmitButton title="Create Flyer" />
    </>
  );
}

type FormInitialValues = {
  image: string | null;
};

const formInitialValues: FormInitialValues = {
  image: null,
};
