import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { RouterOutputs } from "@acme/server";

import { animation } from "../config/animation";
import defaultStyles from "../config/styles";
import LoginIntermediateScreen from "../screens/LoginIntermediateScreen";
import LoginOTPScreen from "../screens/LoginOTPScreen";
import LoginScreen, { LoginCredentials } from "../screens/LoginScreen";
import ResetPasswordOTPScreen from "../screens/ResetPasswordOTPScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen";
import routes from "./routes";

export type AuthNavigatorParamList = {
  [routes.LOGIN]: undefined;
  [routes.LOGIN_OTP]: LoginCredentials;
  [routes.LOGIN_INTERMEDIATE]: LoginCredentials & {
    logInData?: RouterOutputs["user"]["login"];
  };
  [routes.RESET_PASSWORD]: undefined;
  [routes.RESET_PASSWORD_OTP]: {
    email: string;
  };
};

export default function AuthNavigator() {
  const Stack = createNativeStackNavigator<AuthNavigatorParamList>();
  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: defaultStyles.headerStyle,
        animation,
        headerShown: false,
      }}
    >
      <Stack.Screen component={LoginScreen} name={routes.LOGIN} />
      <Stack.Screen
        component={LoginIntermediateScreen}
        name={routes.LOGIN_INTERMEDIATE}
      />
      <Stack.Screen
        options={{
          presentation: "modal",
          gestureEnabled: false,
        }}
        component={LoginOTPScreen}
        name={routes.LOGIN_OTP}
      />
      <Stack.Screen
        component={ResetPasswordScreen}
        options={{
          headerShown: true,
          title: "Reset Password",
        }}
        name={routes.RESET_PASSWORD}
      />
      <Stack.Screen
        options={{
          presentation: "modal",
        }}
        component={ResetPasswordOTPScreen}
        name={routes.RESET_PASSWORD_OTP}
      />
    </Stack.Navigator>
  );
}
