import { useState } from "react";

import { useAuthContext } from "~/auth/context";
import AppListMenuPicker from "~/components/AppListMenuPicker";
import { langToIndex, useBearStore } from "~/store";
import useTextLang from "~/utils/textLang";
import AppListItems from "../components/AppListItems";
import Screen from "../components/Screen";

export default function SettingsScreen() {
  const { logout } = useAuthContext();

  const language = useBearStore((state) => state.language);
  const setLanguage = useBearStore((state) => state.setLanguage);

  const textLang = useTextLang();

  const [logoutLoading, setLogoutLoading] = useState(false);
  return (
    <Screen noSafeArea className="px-5">
      <AppListItems
        items={[
          {
            name: textLang("Preferences", "प्राथमिकताहरू"),
            options: [
              {
                dropdown: true,
                title: textLang("Language", "भाषा"),
                subTitle: (
                  <AppListMenuPicker
                    label={textLang("Language", "भाषा")}
                    noPlaceholder
                    className="flex-1"
                    items={[
                      { value: "en", label: "English" },
                      { value: "np", label: "नेपाली" },
                    ]}
                    onItemSelect={(item) => {
                      setLanguage(item.value as keyof typeof langToIndex);
                    }}
                    selectedItem={language}
                  />
                ),
              },
            ],
          },
          {
            name: textLang("About App", "एपको बारे"),
            options: [
              {
                title: textLang("Version", "संस्करण"),
                subTitle: "1.0.0",
              },
            ],
          },
          {
            name: textLang("Account", "खाता"),
            options: [
              {
                loading: logoutLoading,
                title: textLang("Logout", "लगआउट"),
                loadingTitle: textLang("Logging out...", "लगआउट हुँदै..."),
                onPress: () => {
                  setLogoutLoading(true);
                  logout();
                },
              },
            ],
          },
        ]}
      />
    </Screen>
  );
}
