import { useState } from "react";
import {
  Image,
  ActivityIndicator as NativeActivityIndicator,
  RefreshControl,
  TouchableHighlight,
  View,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import colors from "@acme/common-utils/colors";

import AppListItems from "~/components/AppListItems";
import AppTextInput from "~/components/AppTextInput";
import InfoText from "~/components/InfoText";
import { ListTitle } from "~/components/lists/ListTitle";
import NotSubscribed from "~/components/NotSubscribed";
import { storageDomain } from "~/config/settings";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { useAuthStore } from "~/store";
import useTextLang from "~/utils/textLang";
import { toTitleCase } from "~/utils/toTitleCase";
import { trpc } from "~/utils/trpc";
import useDebounce from "~/utils/useDebounce";
import AppImage from "../components/AppImage";
import AppText from "../components/AppText";
import { ListItemSeparator } from "../components/lists";
import Screen from "../components/Screen";
import { UserTypeChoice } from ".prisma/client";

export default function UsersGeneralMemberListScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.USER_GENERAL_MEMBER_LIST
  >;
}) {
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const textLang = useTextLang();

  useDebounce(
    () => {
      setSearchQuery(search);
    },
    [search],
    500,
  );

  const { data, isLoading, refetch } = trpc.user.list.useQuery({
    keyword: searchQuery,
    type: UserTypeChoice.CUSTOMER,
  });

  return (
    <Screen
      className="flex-1 px-5"
      noSafeArea
      refreshControl={<RefreshControl refreshing={false} onRefresh={refetch} />}
    >
      <AppTextInput
        clearButtonMode="always"
        onChangeText={(text) => setSearch(text)}
        value={search}
        placeholder={textLang("Search", "खोज")}
        className="mt-2"
        noBorder
        textClassName="bg-gray-200 flex-1 px-3 py-2 text-base leading-[20px]"
      />
      <ListTitle name={textLang("Users", "प्रयोगकर्ता")} />
      {isLoading && (
        <View className="h-32 justify-center">
          <NativeActivityIndicator color={colors.primary} size="large" />
        </View>
      )}
      {data && (
        <>
          {data.length === 0 && (
            <View className="py-4">
              <InfoText text="There are no users" />
            </View>
          )}
          <View>
            <View className="rounded-xl bg-white">
              {data.map(({ id, avatar, fullName, type, phone }, index) => (
                <View key={id} className="flex-row items-center">
                  <TouchableHighlight
                    accessibilityRole="button"
                    underlayColor={colors.highlight}
                    onPress={() => {
                      navigation.push(routes.USER_BASKET, {
                        userId: id,
                      });
                    }}
                    className="flex-1"
                  >
                    <View>
                      {index === 0 ? null : (
                        <View className="pl-5">
                          <ListItemSeparator />
                        </View>
                      )}
                      <View className="flex-row items-center px-5 py-3">
                        <View className="h-12 w-12">
                          {avatar ? (
                            <AppImage
                              source={{
                                uri: `${storageDomain}/${avatar}`,
                              }}
                              className="h-12 w-12 rounded-full"
                            />
                          ) : (
                            <Image
                              source={require("../assets/default-avatar.png")}
                              className="h-12 w-12 rounded-full"
                            />
                          )}
                        </View>
                        <View className="ml-4 flex-1">
                          <AppText className="text-lg">{fullName}</AppText>
                          <View className="flex-row justify-between">
                            <AppText className="text-mediumGray text-base">
                              {phone}
                            </AppText>
                            {/* <AppText className="text-mediumGray text-base">
                            {toTitleCase(type)}
                          </AppText> */}
                          </View>
                        </View>
                        {type === UserTypeChoice.CUSTOMER ? (
                          <MaterialCommunityIcons
                            name="cart"
                            size={24}
                            color={colors.primary}
                          />
                        ) : null}
                      </View>
                    </View>
                  </TouchableHighlight>
                </View>
              ))}
            </View>
          </View>
        </>
      )}
    </Screen>
  );
}
