import { RefreshControl, View } from "react-native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import ActivityIndicator from "~/components/ActivityIndicator";
import useTextLang from "~/utils/textLang";
import { trpc } from "~/utils/trpc";
import AppText from "../components/AppText";
import Screen from "../components/Screen";
import SquareItem from "../components/SquareItem";
import { AppNavigatorParamList } from "../navigation/AppNavigator";
import routes from "../navigation/routes";

dayjs.extend(advancedFormat);

export default function AdminDashboardScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.ADMIN_DASHBOARD
  >;
}) {
  const { data: counts, isLoading } = trpc.getAdminDashboard.useQuery();

  const textLang = useTextLang();
  const utils = trpc.useUtils();

  return (
    <>
      <ActivityIndicator visible={isLoading} />
      <Screen
        noSafeArea
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={() => {
              utils.invalidate();
            }}
          />
        }
      >
        <View className="px-5">
          <AppText bigText className="mb-4 mt-2 font-bold">
            {textLang("Manage", "प्रबन्धन")}
          </AppText>
          <View className="flex-row flex-wrap justify-between">
            {[
              {
                name: textLang("Users", "प्रयोगकर्ता"),
                onPress: () => {
                  navigation.push(routes.USER_MANAGE_LIST);
                },
                iconName: "account-group",
                count: counts?.users,
              },
              {
                name: textLang("Deliveries", "डिलिभरी"),
                onPress: () => {
                  navigation.push(routes.DELIVERY_LIST);
                },
                iconName: "truck",
                count: counts?.deliveries,
              },
              {
                name: textLang("Products", "उत्पादन"),
                onPress: () => {
                  navigation.push(routes.PRODUCT_MANAGE_LIST);
                },
                iconName: "carrot",
                count: counts?.products,
              },
              {
                name: textLang("Subscriptions", "सदस्यता"),
                onPress: () => {
                  navigation.push(routes.SUBSCRIPTION_LIST);
                },
                iconName: "chart-bar",
                count: counts?.subscriptions,
              },
              // {
              //   name: textLang("Flyer", "फ्लायर"),
              //   onPress: () => {
              //     navigation.push(routes.FLYER_LIST);
              //   },
              //   iconName: "tag",
              //   count: counts?.flyers,
              // },
              {
                name: textLang("Farmhouse", "फार्महाउस"),
                onPress: () => {
                  navigation.push(routes.FARM_HOUSE_MANAGE_LIST);
                },
                iconName: "home",
                count: counts?.farmHouses,
              },
              // {
              //   name: textLang("Restaurant Menu Items", "भोजनालय मेनू आइटम"),
              //   onPress: () => {
              //     navigation.push(routes.RESTAURANT_ITEM_MANAGE_LIST);
              //   },
              //   iconName: "clipboard-text",
              // },
              // {
              //   name: textLang("Restaurant Orders", "भोजनालय आदेश"),
              //   onPress: () => {
              //     navigation.push(routes.RESTAURANT_ORDER_LIST);
              //   },
              //   iconName: "carrot",
              // },
            ].map(({ name, onPress, iconName, count }) => (
              <SquareItem
                key={name}
                name={name}
                onPress={onPress}
                iconName={iconName}
                count={count}
              />
            ))}
          </View>
        </View>
      </Screen>
    </>
  );
}
