import { Platform, Alert as RNAlert } from "react-native";

export type AlertOption = {
  text: string;
  style?: "cancel" | "default" | "destructive";
  onPress?: () => any;
};

const alert = (
  title: string,
  description?: string,
  options?: {
    text: string;
    style?: "cancel" | "default" | "destructive";
    onPress?: () => any;
  }[],
  extra?: any,
) => {
  const result = window.confirm(
    [title, description].filter(Boolean).join("\n"),
  );
  if (result) {
    const confirmOption = options?.find(({ style }) => style !== "cancel");
    (confirmOption && confirmOption.onPress?.()) || null;
  } else {
    const cancelOption = options?.find(({ style }) => style === "cancel");
    (cancelOption && cancelOption.onPress?.()) || null;
  }
};

const Alert = Platform.OS === "web" ? { alert } : RNAlert;

export default Alert;
