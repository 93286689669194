import { Image, Keyboard, View } from "react-native";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import * as Yup from "yup";

import { trpc } from "~/utils/trpc";
import ActivityIndicator from "../components/ActivityIndicator";
import Text from "../components/AppText";
import {
  ErrorMessage,
  Form,
  FormField,
  SubmitButton,
} from "../components/forms";
import LogoHeader from "../components/LogoHeader";
import Screen from "../components/Screen";
import type { AuthNavigatorParamList } from "../navigation/AuthNavigator";
import routes from "../navigation/routes";
import type { LoginCredentials } from "./LoginScreen";

export default function ResetPasswordScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AuthNavigatorParamList,
    routes.RESET_PASSWORD
  >;
}) {
  const {
    mutate: sendOTPForForgotPassword,
    isLoading,
    error,
  } = trpc.user.sendOTPForForgotPassword.useMutation({
    onError: (error_) => {
      throw new Error(error_.message);
    },
    onSuccess(_, variables) {
      navigation.push(routes.RESET_PASSWORD_OTP, {
        email: variables.email,
      });
    },
  });
  const handleSubmit = ({ email }: LoginCredentials) => {
    Keyboard.dismiss();
    void sendOTPForForgotPassword({
      email,
    });
  };

  const initialForm = {
    email: "",
    password: "",
  };

  return (
    <>
      <ActivityIndicator visible={isLoading} />
      <Screen className="p-5" backgroundColor="white">
        <LogoHeader />
        <Form
          initialValues={initialForm}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <ErrorMessage
            error={error?.message ?? "Invalid email or password"}
            visible={!!error}
          />
          <FormField
            autoCapitalize="none"
            autoCorrect={false}
            autoComplete="email"
            name="email"
            label="Enter your email"
            keyboardType="email-address"
            textContentType="emailAddress"
          />
          <SubmitButton title="Next" />
        </Form>
      </Screen>
    </>
  );
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required().label("Email"),
});
