import { useEffect } from "react";
import {
  RefreshControl,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import colors from "@acme/common-utils/colors";

import ActivityIndicator from "~/components/ActivityIndicator";
import AppButton from "~/components/AppButton";
import AppText from "~/components/AppText";
import InfoText from "~/components/InfoText";
import { ListTitle } from "~/components/lists/ListTitle";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { useAuthStore } from "~/store";
import { toTitleCase } from "~/utils/toTitleCase";
import { trpc } from "~/utils/trpc";
import Screen from "../components/Screen";
import { UserTypeChoice } from ".prisma/client";

export default function SubscriptionListScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.SUBSCRIPTION_LIST
  >;
}) {
  const user = useAuthStore((state) => state.user)!;
  const {
    data: subscriptions,
    isLoading,
    refetch,
  } = trpc.subscription.list.useQuery();
  useEffect(() => {
    if (user.type === UserTypeChoice.ADMIN) {
      navigation.setOptions({
        headerRight() {
          return (
            <TouchableOpacity
              onPress={() => {
                navigation.push(routes.SUBSCRIPTION_CREATE);
              }}
            >
              {/* <View className="mx-5"> */}
              <MaterialCommunityIcons
                name="plus"
                size={24}
                color={colors.primary}
              />
              {/* </View> */}
            </TouchableOpacity>
          );
        },
      });
    }
  }, []);

  return (
    <>
      <ActivityIndicator className="bg-iosBackground" visible={isLoading} />
      <Screen
        noSafeArea
        className="px-5"
        refreshControl={
          <RefreshControl refreshing={false} onRefresh={refetch} />
        }
      >
        <View>
          <ListTitle name={"Subscriptions"} />
          {subscriptions?.map(({ id, name, price, dietary }, index) => (
            <TouchableHighlight
              underlayColor={colors.highlight}
              onPress={
                UserTypeChoice.ADMIN
                  ? () => {
                      navigation.push(routes.SUBSCRIPTION_CREATE, {
                        subscriptionId: id,
                      });
                    }
                  : undefined
              }
              accessibilityRole={
                user.type === UserTypeChoice.ADMIN ? "button" : "text"
              }
              key={id}
              className="mb-4 overflow-hidden rounded-lg bg-white py-3"
            >
              <View>
                <AppText className={`pb-2 pl-5 text-xl font-bold`}>
                  {name}
                </AppText>
                <AppText className={`pl-5 text-lg`}>
                  Dietary: {toTitleCase(dietary)}
                </AppText>
                <AppText className={`pl-5 text-lg`}>
                  Price: Rs. {price}/delivery
                </AppText>

                {user.type === UserTypeChoice.CUSTOMER ? (
                  <>
                    {user.subscription?.id === id ? (
                      <View className="p-4">
                        <InfoText text="You are currently subscribed to this plan" />
                      </View>
                    ) : (
                      <AppButton
                        className="mx-5 mt-3"
                        title="Contact Us"
                        onPress={() => {
                          // trpc.subscription.subscribe.mutation({ id });
                        }}
                      />
                    )}
                  </>
                ) : null}
              </View>
            </TouchableHighlight>
          ))}
          {subscriptions?.length === 0 ? (
            <InfoText text="No subscriptions found" />
          ) : null}
        </View>
      </Screen>
    </>
  );
}
