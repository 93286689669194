import { useEffect, useState } from "react";
import {
  Image,
  ActivityIndicator as NativeActivityIndicator,
  RefreshControl,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import dayjs from "dayjs";

import colors from "@acme/common-utils/colors";

import AppButton from "~/components/AppButton";
import AppListItems from "~/components/AppListItems";
import AppTextInput from "~/components/AppTextInput";
import StepperInput from "~/components/forms/StepperInput";
import InfoText from "~/components/InfoText";
import { ListTitle } from "~/components/lists/ListTitle";
import NotSubscribed from "~/components/NotSubscribed";
import { storageDomain } from "~/config/settings";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { useAuthStore } from "~/store";
import { toTitleCase } from "~/utils/toTitleCase";
import { trpc } from "~/utils/trpc";
import useDebounce from "~/utils/useDebounce";
import { UserTypeChoice } from "../../../prisma-generated";
import ActivityIndicator from "../components/ActivityIndicator";
import AppImage from "../components/AppImage";
import AppText from "../components/AppText";
import { ListItemSeparator } from "../components/lists";
import Screen from "../components/Screen";

export default function RestaurantOrderListScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.RESTAURANT_ORDER_LIST
  >;
}) {
  const { data, isLoading, refetch } = trpc.restaurant.listOrders.useQuery();

  const user = useAuthStore((state) => state.user)!;

  return (
    <>
      <Screen className="px-5" noSafeArea noKeyboardAwareScroll>
        <KeyboardAwareScrollView
          refreshControl={
            <RefreshControl refreshing={false} onRefresh={refetch} />
          }
          contentContainerStyle={{
            paddingBottom: 40,
          }}
          enableResetScrollToCoords={false}
          keyboardShouldPersistTaps="handled"
          extraScrollHeight={100}
          keyboardOpeningTime={0}
        >
          <ListTitle
            name={
              [
                UserTypeChoice.ADMIN as UserTypeChoice,
                UserTypeChoice.RESTAURANT_STAFF,
              ].includes(user.type)
                ? "Orders"
                : "My Orders"
            }
          />
          {isLoading && (
            <View className="h-32 justify-center">
              <NativeActivityIndicator color={colors.primary} size="large" />
            </View>
          )}
          {data && (
            <>
              {data.length === 0 && (
                <View className="py-4">
                  <InfoText text="No orders found" />
                </View>
              )}
              <View>
                <View className="rounded-xl bg-white">
                  {data.map(({ id, date, items, status, user }, index) => (
                    <View key={id}>
                      {index > 0 && <ListItemSeparator />}
                      <TouchableHighlight
                        underlayColor={colors.highlight}
                        onPress={() => {
                          navigation.push(routes.RESTAURANT_ORDER_SUMMARY, {
                            restaurantOrderId: id,
                          });
                        }}
                      >
                        <View className="flex-row items-center px-5 py-3">
                          <View className="h-12 w-12">
                            {user.avatar ? (
                              <AppImage
                                source={{
                                  uri: `${storageDomain}/${user.avatar}`,
                                }}
                                className="h-12 w-12 rounded-full"
                              />
                            ) : (
                              <Image
                                source={require("../assets/default-avatar.png")}
                                className="h-12 w-12 rounded-full"
                              />
                            )}
                          </View>
                          <View className="ml-4 flex-1">
                            <View className="flex-row items-end justify-between">
                              <AppText className="text-lg">
                                {user.fullName}
                              </AppText>
                              <AppText className="text-mediumGray text-base">
                                For {dayjs(date).format("DD MMM, YYYY")}
                              </AppText>
                            </View>
                            <View className="flex-row justify-between">
                              <AppText className="text-mediumGray text-base">
                                {user.phone}
                              </AppText>
                              <AppText className="text-mediumGray text-base">
                                {toTitleCase(status)}
                              </AppText>
                            </View>
                          </View>
                        </View>
                      </TouchableHighlight>
                    </View>
                  ))}
                </View>
              </View>
            </>
          )}
        </KeyboardAwareScrollView>
      </Screen>
    </>
  );
}
