import { useEffect } from "react";
import { Modal, View } from "react-native";
import * as Progress from "react-native-progress";
import LottieView from "lottie-react-native";

import colors from "@acme/common-utils/colors";

export default function AppProgress({
  onDone,
  progress = 0,
  visible = false,
}: {
  onDone: (isCanceled: boolean) => void;
  progress: number;
  visible: boolean;
}) {
  useEffect(() => {
    setTimeout(() => {
      if (progress >= 1) {
        onDone(false);
      }
    }, 750);
  }, [visible, progress]);

  return (
    <Modal visible={visible} animationType="slide">
      <View className="flex-1 items-center justify-center">
        {progress < 1 ? (
          <Progress.Bar
            color={colors.primary}
            progress={progress}
            width={200}
          />
        ) : (
          <LottieView
            // onAnimationFinish={onDone}
            progress={1}
            loop={false}
            resizeMode="cover"
            style={{ width: 100, height: 100 }}
            source={require("../assets/animations/done.json")}
          />
        )}
      </View>
    </Modal>
  );
}
