import { useEffect, useState } from "react";
import {
  Image,
  ActivityIndicator as NativeActivityIndicator,
  RefreshControl,
  TouchableHighlight,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import colors from "@acme/common-utils/colors";

import AppButton from "~/components/AppButton";
import AppListItems from "~/components/AppListItems";
import AppTextInput from "~/components/AppTextInput";
import StepperInput from "~/components/forms/StepperInput";
import InfoText from "~/components/InfoText";
import { ListTitle } from "~/components/lists/ListTitle";
import NotSubscribed from "~/components/NotSubscribed";
import { storageDomain } from "~/config/settings";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { useAuthStore } from "~/store";
import { toTitleCase } from "~/utils/toTitleCase";
import { trpc } from "~/utils/trpc";
import useDebounce from "~/utils/useDebounce";
import ActivityIndicator from "../components/ActivityIndicator";
import AppImage from "../components/AppImage";
import AppText from "../components/AppText";
import { ListItemSeparator } from "../components/lists";
import Screen from "../components/Screen";

export default function RestaurantOrderItemListScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.RESTAURANT_ORDER_ITEM_LIST
  >;
}) {
  const [counters, setCounters] = useState<{ [key: string]: number }>({});

  const { data, isLoading, refetch } = trpc.restaurant.listItems.useQuery({});

  useEffect(() => {
    if (data) {
      const counters = data.reduce((acc, item) => {
        acc[item.id] = 0;
        return acc;
      }, {});
      setCounters(counters);
    }
  }, [data]);

  return (
    <>
      <Screen className="px-5" noSafeArea noKeyboardAwareScroll>
        <KeyboardAwareScrollView
          refreshControl={
            <RefreshControl refreshing={false} onRefresh={refetch} />
          }
          contentContainerStyle={{
            paddingBottom: 40,
          }}
          enableResetScrollToCoords={false}
          keyboardShouldPersistTaps="handled"
          extraScrollHeight={100}
          keyboardOpeningTime={0}
        >
          <ListTitle name="Menu" />
          {isLoading && (
            <View className="h-32 justify-center">
              <NativeActivityIndicator color={colors.primary} size="large" />
            </View>
          )}
          {data && (
            <>
              {data.length === 0 && (
                <View className="py-4">
                  <InfoText text="No items found" />
                </View>
              )}
              <View>
                {data.map(({ id, image, name, dietary, price }, index) => (
                  <View key={id} className="mb-2 rounded-xl bg-white">
                    <View>
                      <View className="flex-row items-center px-5 py-3">
                        <View className="h-12 w-12">
                          {image ? (
                            <AppImage
                              source={{
                                uri: `${storageDomain}/${image}`,
                              }}
                              className="h-12 w-12 rounded-full"
                            />
                          ) : (
                            <Image
                              source={require("../assets/default-product-avatar.png")}
                              className="h-12 w-12 rounded-full"
                            />
                          )}
                        </View>
                        <View className="ml-4 flex-1">
                          <AppText className="text-lg">{name}</AppText>
                          <View className="flex-row justify-between">
                            <AppText className="text-mediumGray text-base">
                              {toTitleCase(dietary)}
                            </AppText>
                            <AppText className="text-mediumGray text-base">
                              Rs. {price}
                            </AppText>
                          </View>
                        </View>
                      </View>
                      <AppListItems
                        items={[
                          {
                            name: "",
                            options: [
                              {
                                title: "Quantity",
                                subTitle: (
                                  <StepperInput
                                    counter={counters[id]?.toString() || "0"}
                                    setCounter={(setter) => {
                                      setCounters({
                                        ...counters,
                                        [id]: Number(
                                          setter(
                                            counters[id]?.toString() || "0",
                                          ),
                                        ),
                                      });
                                    }}
                                  />
                                ),
                              },
                              ...(counters[id] > 0
                                ? [
                                    {
                                      title: "Amount",
                                      subTitle: `Rs. ${counters[id] * price}`,
                                    },
                                  ]
                                : []),
                            ],
                          },
                        ]}
                      />
                    </View>
                  </View>
                ))}
              </View>
            </>
          )}
        </KeyboardAwareScrollView>
      </Screen>
      <View className="bg-white px-5 pb-6">
        <AppButton
          title="Next"
          onPress={() => {
            navigation.push(routes.RESTAURANT_ORDER_SUMMARY, {
              orders: counters,
            });
          }}
        />
      </View>
    </>
  );
}
