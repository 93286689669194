import { useCallback } from "react";
import {
  Image,
  RefreshControl,
  Text,
  TouchableHighlight,
  View,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import dayjs from "dayjs";

import colors from "@acme/common-utils/colors";

import ActivityIndicator from "~/components/ActivityIndicator";
import AppImage from "~/components/AppImage";
import AppText from "~/components/AppText";
import InfoText from "~/components/InfoText";
import { ListItemSeparator } from "~/components/lists";
import Screen from "~/components/Screen";
import { storageDomain } from "~/config/settings";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { trpc } from "~/utils/trpc";

export default function ChatListScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.CHAT_LIST
  >;
}) {
  const {
    data: chatListData,
    isLoading: chatListLoading,
    refetch,
  } = trpc.chat.list.useQuery();

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, []),
  );

  return (
    <>
      <ActivityIndicator visible={chatListLoading} />
      <Screen
        refreshControl={
          <RefreshControl refreshing={false} onRefresh={refetch} />
        }
        backgroundColor="white"
        noSafeArea
      >
        {chatListData?.map((chat) => (
          <View key={chat.id}>
            <TouchableHighlight
              onPress={() => {
                navigation.push(routes.CHAT, {
                  chatId: chat.id,
                  fullName: chat.participants[0].fullName,
                });
              }}
              underlayColor={colors.highlight}
            >
              <View className="flex-row items-center">
                <View
                  className={`${
                    !chat.read ? "bg-primary" : ""
                  } mx-2 h-3 w-3 rounded-full`}
                ></View>
                <View className="mr-3 h-14 w-14">
                  {chat.participants[0].avatar ? (
                    <AppImage
                      source={{
                        uri: `${storageDomain}/${chat.participants[0].avatar}`,
                      }}
                      className="h-14 w-14 rounded-full"
                    />
                  ) : (
                    <Image
                      source={require("../assets/default-avatar.png")}
                      className="h-14 w-14 rounded-full"
                    />
                  )}
                </View>
                <View className="flex-1 pr-5">
                  <View className="flex-1">
                    <View className="flex-row justify-center pt-2">
                      <View className="flex-1">
                        <AppText className="flex-1 text-base font-bold">
                          {chat.participants[0].fullName}
                        </AppText>
                      </View>
                      {chat.messages.length > 0 ? (
                        <View>
                          <AppText className="text-mediumGray text-base">
                            {dayjs(chat.messages[0].createdAt).diff(
                              dayjs(),
                              "day",
                            ) > -1
                              ? dayjs(chat.messages[0].createdAt).format(
                                  "h:mm A",
                                )
                              : dayjs(chat.messages[0].createdAt).diff(
                                  dayjs(),
                                  "day",
                                ) > -7
                              ? dayjs(chat.messages[0].createdAt).format("dddd")
                              : dayjs(chat.messages[0].createdAt).format(
                                  "MMM D, YYYY",
                                )}
                          </AppText>
                        </View>
                      ) : (
                        <View>
                          <AppText className="text-mediumGray text-base">
                            {dayjs(chat.createdAt).diff(dayjs(), "day") > -1
                              ? dayjs(chat.createdAt).format("h:mm A")
                              : dayjs(chat.createdAt).diff(dayjs(), "day") > -7
                              ? dayjs(chat.createdAt).format("dddd")
                              : dayjs(chat.createdAt).format("MMM D, YYYY")}
                          </AppText>
                        </View>
                      )}

                      <View>
                        <MaterialCommunityIcons
                          name="chevron-right"
                          size={24}
                          color={colors.mediumGray}
                        />
                      </View>
                    </View>
                    <View className="flex-1">
                      <AppText
                        className="text-mediumGray h-14 text-base"
                        numberOfLines={3}
                      >
                        {chat.messages.length > 0 ? chat.messages[0].text : " "}
                      </AppText>
                    </View>
                  </View>
                  <ListItemSeparator />
                </View>
              </View>
            </TouchableHighlight>
          </View>
        ))}
        {chatListData?.length === 0 && (
          <InfoText text="You don't have any chats yet." className="my-10" />
        )}
      </Screen>
    </>
  );
}
