import { TouchableHighlight, View } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import colors from "@acme/common-utils/colors";

import AppText from "./AppText";

export default function SquareItem({
  name,
  onPress,
  iconName,
  count,
}: {
  name: string;
  onPress?: () => void;
  iconName: any;
  count?: number;
}) {
  return (
    <TouchableHighlight
      accessibilityRole="button"
      underlayColor={colors.highlight}
      onPress={onPress}
      className="mb-4 h-32 w-[47%] justify-between rounded-xl bg-white p-4 pb-2"
    >
      <>
        <View className="flex-row items-center justify-between">
          <View className="bg-primary h-10 w-10 items-center justify-center rounded-full">
            <MaterialCommunityIcons name={iconName} size={24} color="white" />
          </View>
          <AppText className="text-2xl font-extrabold ">{count}</AppText>
        </View>
        <AppText className="mt-2 text-lg leading-6">{name}</AppText>
      </>
    </TouchableHighlight>
  );
}
