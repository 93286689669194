import { useEffect } from "react";
import {
  ActivityIndicator as NativeActivityIndicator,
  RefreshControl,
  TouchableHighlight,
  View,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import colors from "@acme/common-utils/colors";

import InfoText from "~/components/InfoText";
import { ListTitle } from "~/components/lists/ListTitle";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { useAuthStore } from "~/store";
import { toTitleCase } from "~/utils/toTitleCase";
import { trpc } from "~/utils/trpc";
import AppText from "../components/AppText";
import { ListItemSeparator } from "../components/lists";
import Screen from "../components/Screen";
import { UserTypeChoice } from ".prisma/client";

export default function FarmHouseBookingManageListScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.FARM_HOUSE_BOOKING_MANAGE_LIST
  >;
}) {
  const { data, isLoading, refetch } = trpc.farmHouse.listBookings.useQuery();

  const user = useAuthStore((state) => state.user)!;

  useEffect(() => {
    if (user.type === UserTypeChoice.ADMIN) {
      navigation.setOptions({
        headerRight: () => (
          <TouchableOpacity
            onPress={() => {
              navigation.navigate(routes.FARM_HOUSE_LIST);
            }}
          >
            <MaterialCommunityIcons
              name="plus"
              size={24}
              color={colors.primary}
            />
          </TouchableOpacity>
        ),
      });
    }
  }, [user]);

  return (
    <Screen
      className="px-5 pb-10"
      noSafeArea
      refreshControl={<RefreshControl refreshing={false} onRefresh={refetch} />}
    >
      <ListTitle name="Farmhouses" />
      {isLoading && (
        <View className="h-32 justify-center">
          <NativeActivityIndicator color={colors.primary} size="large" />
        </View>
      )}
      {data && (
        <>
          {data.length === 0 && (
            <View className="py-4">
              <InfoText text="There are no bookings" />
            </View>
          )}
          <View>
            <View className="rounded-xl bg-white">
              {data.map(
                (
                  {
                    id,
                    bookingStatus,
                    farmHouse,
                    userFullName,
                    userContact,
                    user,
                  },
                  index,
                ) => (
                  <TouchableHighlight
                    key={id}
                    accessibilityRole="button"
                    underlayColor={colors.highlight}
                    onPress={() => {
                      navigation.push(routes.FARM_HOUSE_BOOKING, {
                        farmHouseId: farmHouse.id,
                        farmHouseBookingId: id,
                      });
                    }}
                  >
                    <View>
                      {index === 0 ? null : (
                        <View className="pl-5">
                          <ListItemSeparator />
                        </View>
                      )}
                      <View className="flex-row items-center px-5 py-3">
                        <View className="flex-1">
                          <AppText className="text-lg">
                            {user?.fullName || userFullName}
                          </AppText>
                          <View className="flex-row justify-between">
                            <AppText className="text-mediumGray text-base">
                              Farmhouse: {farmHouse.name}
                            </AppText>
                            <AppText className="text-mediumGray text-base">
                              {toTitleCase(bookingStatus)}
                            </AppText>
                          </View>
                        </View>
                      </View>
                    </View>
                  </TouchableHighlight>
                ),
              )}
            </View>
          </View>
        </>
      )}
    </Screen>
  );
}
