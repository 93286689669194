
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
  detectRuntime,
} = require('@prisma/client/runtime/index-browser')


const Prisma = {}

exports.Prisma = Prisma
exports.$Enums = {}

/**
 * Prisma Client JS version: 5.5.2
 * Query Engine version: aebc046ce8b88ebbcb45efe31cbe7d06fd6abc0a
 */
Prisma.prismaVersion = {
  client: "5.5.2",
  engine: "aebc046ce8b88ebbcb45efe31cbe7d06fd6abc0a"
}

Prisma.PrismaClientKnownRequestError = () => {
  throw new Error(`PrismaClientKnownRequestError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  throw new Error(`PrismaClientUnknownRequestError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.PrismaClientRustPanicError = () => {
  throw new Error(`PrismaClientRustPanicError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.PrismaClientInitializationError = () => {
  throw new Error(`PrismaClientInitializationError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.PrismaClientValidationError = () => {
  throw new Error(`PrismaClientValidationError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.NotFoundError = () => {
  throw new Error(`NotFoundError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  throw new Error(`sqltag is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.empty = () => {
  throw new Error(`empty is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.join = () => {
  throw new Error(`join is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.raw = () => {
  throw new Error(`raw is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  throw new Error(`Extensions.getExtensionContext is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.defineExtension = () => {
  throw new Error(`Extensions.defineExtension is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}

/**
 * Enums
 */

exports.Prisma.TransactionIsolationLevel = makeStrictEnum({
  ReadUncommitted: 'ReadUncommitted',
  ReadCommitted: 'ReadCommitted',
  RepeatableRead: 'RepeatableRead',
  Serializable: 'Serializable'
});

exports.Prisma.UserScalarFieldEnum = {
  id: 'id',
  fullName: 'fullName',
  email: 'email',
  emailVerified: 'emailVerified',
  password: 'password',
  phone: 'phone',
  secondaryPhone: 'secondaryPhone',
  type: 'type',
  about: 'about',
  avatar: 'avatar',
  locationName: 'locationName',
  latitude: 'latitude',
  longitude: 'longitude',
  disabledNotifications: 'disabledNotifications',
  noOfPasswordsChanged: 'noOfPasswordsChanged',
  otp: 'otp',
  lastOtpSent: 'lastOtpSent',
  notificationIds: 'notificationIds',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  subscriptionId: 'subscriptionId',
  deliveriesLeft: 'deliveriesLeft',
  subscriptionTotalDeliveries: 'subscriptionTotalDeliveries',
  assignedDriverId: 'assignedDriverId',
  deliveryRecurrence: 'deliveryRecurrence',
  deliveryDateOfMonth: 'deliveryDateOfMonth',
  deliveryDayOfWeek: 'deliveryDayOfWeek',
  deliveryDate: 'deliveryDate',
  driverTruckNumber: 'driverTruckNumber',
  driverTruckDetails: 'driverTruckDetails'
};

exports.Prisma.SubscriptionScalarFieldEnum = {
  id: 'id',
  name: 'name',
  price: 'price',
  dietary: 'dietary',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.BasketProductScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  productId: 'productId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SubscriptionProductScalarFieldEnum = {
  id: 'id',
  productId: 'productId',
  subscriptionId: 'subscriptionId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ProductScalarFieldEnum = {
  id: 'id',
  name: 'name',
  price: 'price',
  description: 'description',
  image: 'image',
  isShown: 'isShown',
  dietary: 'dietary',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.DeliveryScalarFieldEnum = {
  id: 'id',
  customerId: 'customerId',
  driverId: 'driverId',
  deliveryDate: 'deliveryDate',
  status: 'status',
  rejectReason: 'rejectReason',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.FlyerScalarFieldEnum = {
  id: 'id',
  image: 'image',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ChatScalarFieldEnum = {
  id: 'id',
  read: 'read',
  closed: 'closed',
  createdAt: 'createdAt'
};

exports.Prisma.MessageScalarFieldEnum = {
  id: 'id',
  text: 'text',
  senderId: 'senderId',
  chatId: 'chatId',
  createdAt: 'createdAt'
};

exports.Prisma.TransactionScalarFieldEnum = {
  id: 'id',
  remarks: 'remarks',
  transactionType: 'transactionType',
  amount: 'amount',
  userId: 'userId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.NotificationScalarFieldEnum = {
  id: 'id',
  title: 'title',
  body: 'body',
  dataId: 'dataId',
  dataType: 'dataType',
  type: 'type',
  read: 'read',
  userId: 'userId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.RestaurantOrderScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  status: 'status',
  date: 'date',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.RestaurantOrderItemScalarFieldEnum = {
  id: 'id',
  orderId: 'orderId',
  itemId: 'itemId',
  quantity: 'quantity',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.RestaurantItemScalarFieldEnum = {
  id: 'id',
  name: 'name',
  price: 'price',
  image: 'image',
  description: 'description',
  dietary: 'dietary',
  isShown: 'isShown',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.FarmHouseScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  features: 'features',
  numberOfGuests: 'numberOfGuests',
  price: 'price',
  discountedPrice: 'discountedPrice',
  images: 'images',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.FarmHouseBookingScalarFieldEnum = {
  id: 'id',
  farmHouseId: 'farmHouseId',
  userId: 'userId',
  userFullName: 'userFullName',
  userContact: 'userContact',
  isPaid: 'isPaid',
  bookingStatus: 'bookingStatus',
  dates: 'dates',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.QueryMode = {
  default: 'default',
  insensitive: 'insensitive'
};

exports.Prisma.NullsOrder = {
  first: 'first',
  last: 'last'
};
exports.UserTypeChoice = exports.$Enums.UserTypeChoice = {
  CUSTOMER: 'CUSTOMER',
  ADMIN: 'ADMIN',
  DRIVER: 'DRIVER',
  RESTAURANT_STAFF: 'RESTAURANT_STAFF',
  GENERAL_STAFF: 'GENERAL_STAFF'
};

exports.DeliveryRecurrenceChoice = exports.$Enums.DeliveryRecurrenceChoice = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  ONCE: 'ONCE'
};

exports.DayChoice = exports.$Enums.DayChoice = {
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY'
};

exports.NotificationTypeChoice = exports.$Enums.NotificationTypeChoice = {
  SUBSCRIPTION_RENEWAL: 'SUBSCRIPTION_RENEWAL',
  FARMHOUSE_BOOKING: 'FARMHOUSE_BOOKING',
  CUSTOMER_RESTAURANT_ORDER: 'CUSTOMER_RESTAURANT_ORDER',
  DELIVERY_FAILED: 'DELIVERY_FAILED',
  DELIVERY_TOMORROW: 'DELIVERY_TOMORROW',
  CUSTOMER_ASSIGNED: 'CUSTOMER_ASSIGNED'
};

exports.DietaryChoice = exports.$Enums.DietaryChoice = {
  VEG: 'VEG',
  NON_VEG: 'NON_VEG',
  VEG__NON_VEG: 'VEG__NON_VEG'
};

exports.DeliveryStatusChoice = exports.$Enums.DeliveryStatusChoice = {
  PENDING: 'PENDING',
  COMPLETED_BY_DRIVER: 'COMPLETED_BY_DRIVER',
  COMPLETED_BY_CUSTOMER: 'COMPLETED_BY_CUSTOMER',
  REJECTED_BY_DRIVER: 'REJECTED_BY_DRIVER',
  REJECTED_BY_CUSTOMER: 'REJECTED_BY_CUSTOMER'
};

exports.TransactionTypeChoice = exports.$Enums.TransactionTypeChoice = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  BASKET: 'BASKET'
};

exports.NotificationDataTypeChoice = exports.$Enums.NotificationDataTypeChoice = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  FARMHOUSE_BOOKING: 'FARMHOUSE_BOOKING',
  RESTAURANT_ORDER: 'RESTAURANT_ORDER',
  DELIVERY: 'DELIVERY',
  USER: 'USER'
};

exports.RestaurantOrderStatusChoice = exports.$Enums.RestaurantOrderStatusChoice = {
  PENDING: 'PENDING',
  AKNOWLEDGED: 'AKNOWLEDGED',
  CANCELLED: 'CANCELLED'
};

exports.BookingStatusChoice = exports.$Enums.BookingStatusChoice = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
};

exports.Prisma.ModelName = {
  User: 'User',
  Subscription: 'Subscription',
  BasketProduct: 'BasketProduct',
  SubscriptionProduct: 'SubscriptionProduct',
  Product: 'Product',
  Delivery: 'Delivery',
  Flyer: 'Flyer',
  Chat: 'Chat',
  Message: 'Message',
  Transaction: 'Transaction',
  Notification: 'Notification',
  RestaurantOrder: 'RestaurantOrder',
  RestaurantOrderItem: 'RestaurantOrderItem',
  RestaurantItem: 'RestaurantItem',
  FarmHouse: 'FarmHouse',
  FarmHouseBooking: 'FarmHouseBooking'
};

/**
 * This is a stub Prisma Client that will error at runtime if called.
 */
class PrismaClient {
  constructor() {
    return new Proxy(this, {
      get(target, prop) {
        const runtime = detectRuntime()
        const edgeRuntimeName = {
          'workerd': 'Cloudflare Workers',
          'deno': 'Deno and Deno Deploy',
          'netlify': 'Netlify Edge Functions',
          'edge-light': 'Vercel Edge Functions',
        }[runtime]

        let message = 'PrismaClient is unable to run in '
        if (edgeRuntimeName !== undefined) {
          message += edgeRuntimeName + '. As an alternative, try Accelerate: https://pris.ly/d/accelerate.'
        } else {
          message += 'this browser environment, or has been bundled for the browser (running in `' + runtime + '`).'
        }
        
        message += `
If this is unexpected, please open an issue: https://github.com/prisma/prisma/issues`

        throw new Error(message)
      }
    })
  }
}

exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)
