import { useState } from "react";
import {
  Image,
  ActivityIndicator as NativeActivityIndicator,
  RefreshControl,
  TouchableHighlight,
  View,
} from "react-native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import colors from "@acme/common-utils/colors";

import AppListItems from "~/components/AppListItems";
import AppTextInput from "~/components/AppTextInput";
import InfoText from "~/components/InfoText";
import { ListTitle } from "~/components/lists/ListTitle";
import NotSubscribed from "~/components/NotSubscribed";
import { storageDomain } from "~/config/settings";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { useAuthStore } from "~/store";
import { toTitleCase } from "~/utils/toTitleCase";
import { trpc } from "~/utils/trpc";
import ActivityIndicator from "../components/ActivityIndicator";
import AppImage from "../components/AppImage";
import AppText from "../components/AppText";
import { ListItemSeparator } from "../components/lists";
import Screen from "../components/Screen";
import useDebounce from "../utils/useDebounce";

export default function ProductsManageListScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.PRODUCT_LIST
  >;
}) {
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useDebounce(
    () => {
      setSearchQuery(search);
    },
    [search],
    500,
  );

  const { data, isLoading, refetch } = trpc.product.list.useQuery({
    keyword: searchQuery,
  });

  return (
    <>
      <Screen
        className="px-5 pb-5"
        noSafeArea
        refreshControl={
          <RefreshControl refreshing={false} onRefresh={refetch} />
        }
      >
        <AppTextInput
          clearButtonMode="always"
          onChangeText={(text) => setSearch(text)}
          value={search}
          placeholder="Search"
          className="mt-4"
          noBorder
          textClassName="bg-gray-200 flex-1 px-3 py-2 text-base leading-[20px]"
        />
        <ListTitle name="Products" />
        {isLoading && (
          <View className="h-32 justify-center">
            <NativeActivityIndicator color={colors.primary} size="large" />
          </View>
        )}
        {data && (
          <>
            {data.length === 0 && (
              <View className="py-4">
                <InfoText text="No products found" />
              </View>
            )}
            <View>
              <View className="rounded-xl bg-white">
                {data
                  .filter(
                    ({ name }) =>
                      !name ||
                      name.toLowerCase().includes(search.toLowerCase()),
                  )
                  .map(({ id, image, name, dietary }, index) => (
                    <TouchableHighlight
                      key={id}
                      accessibilityRole="button"
                      underlayColor={colors.highlight}
                      onPress={() => {
                        navigation.push(routes.PRODUCT_CREATE, {
                          productId: id,
                        });
                      }}
                    >
                      <View>
                        {index === 0 ? null : (
                          <View className="pl-5">
                            <ListItemSeparator />
                          </View>
                        )}
                        <View className="flex-row items-center px-5 py-3">
                          <View className="h-12 w-12">
                            {image ? (
                              <AppImage
                                source={{
                                  uri: `${storageDomain}/${image}`,
                                }}
                                className="h-12 w-12 rounded-full"
                              />
                            ) : (
                              <Image
                                source={require("../assets/default-product-avatar.png")}
                                className="h-12 w-12 rounded-full"
                              />
                            )}
                          </View>
                          <View className="ml-4 flex-1">
                            <AppText className="text-lg">{name}</AppText>
                            <View className="flex-row justify-between">
                              <AppText className="text-mediumGray text-base">
                                Dietary: {toTitleCase(dietary)}
                              </AppText>
                            </View>
                          </View>
                        </View>
                      </View>
                    </TouchableHighlight>
                  ))}
              </View>
            </View>
          </>
        )}
      </Screen>
    </>
  );
}
