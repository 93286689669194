import { Platform, Pressable, Switch } from "react-native";

import colors from "@acme/common-utils/colors";

import AppText from "./AppText";

export default function AppSwitch({
  my0,
  label,
  value,
  onValueChange,
  ...otherProps
}: {
  my0?: boolean;
  label: string;
  value: boolean;
  onValueChange: (newValue: boolean) => void;
}) {
  return (
    <Pressable
      onPress={() => onValueChange(!value)}
      className={`flex-row items-center justify-between ${
        my0 ? "my-0" : "my-0"
      }`}
    >
      <AppText>{label}</AppText>
      <Switch
        className="m-2"
        trackColor={{ true: colors.primary }}
        thumbColor={Platform.OS === "ios" ? colors.white : colors.light}
        onValueChange={() => onValueChange(!value)}
        value={value}
        {...otherProps}
      />
    </Pressable>
  );
}
