import { useEffect, useState } from "react";
import { Keyboard } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import * as Yup from "yup";

import Alert from "~/components/Alert";
import { trpc } from "~/utils/trpc";
import { uploadImages } from "~/utils/upload";
import ActivityIndicator from "../components/ActivityIndicator";
import {
  ErrorMessage,
  Form,
  FormField,
  FormSingleImagePicker,
  SubmitButton,
} from "../components/forms";
import FormNativePicker from "../components/forms/FormNativePicker";
import Screen from "../components/Screen";
import { AppNavigatorParamList } from "../navigation/AppNavigator";
import routes from "../navigation/routes";
import { toTitleCase } from "../utils/toTitleCase";
import { DietaryChoice } from ".prisma/client";

export default function ProductCreateScreen({
  navigation,
  route,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.PRODUCT_CREATE
  >;
  route: RouteProp<AppNavigatorParamList, routes.PRODUCT_CREATE>;
}) {
  const utils = trpc.useUtils();
  const { productId } = route.params || {};
  const { data: productData, isLoading: productLoading } =
    trpc.product.getBasicInfo.useQuery({
      productId: productId || "",
    });

  const [artificialLoading, setArtificialLoading] = useState(false);

  const {
    mutate: createProduct,
    isLoading: createProductLoading,
    error,
  } = trpc.product.create.useMutation({
    onSuccess: () => {
      Alert.alert("Success", "Product saved successfully");
      utils.invalidate();
      setArtificialLoading(false);
      navigation.pop();
    },
    onError(error) {
      setArtificialLoading(false);
      Alert.alert("Error", error.message);
    },
  });

  const { mutateAsync: getSignedUrl } = trpc.s3.getSignedUrl.useMutation();

  const handleSubmit = async (input: {
    [K in keyof CreateProductInput]: NonNullable<CreateProductInput[K]>;
  }) => {
    setArtificialLoading(true);
    Keyboard.dismiss();
    createProduct({
      ...input,
      productId,
      image: (await uploadImages([input.image], getSignedUrl, () => {}))[0],
    });
  };

  useEffect(() => {
    if (productId) {
      navigation.setOptions({
        title: `Edit`,
      });
    }
  }, [productId]);

  return (
    <>
      <ActivityIndicator
        visible={artificialLoading || createProductLoading || productLoading}
      />
      <Screen backgroundColor="white" className="px-5 py-2" noSafeArea>
        {productData !== undefined && (
          <Form
            initialValues={
              productData
                ? Object.keys(productData).reduce((acc, key) => {
                    if (
                      Object.prototype.hasOwnProperty.call(
                        formInitialValues,
                        key,
                      )
                    ) {
                      return { ...acc, [key]: productData[key] };
                    }
                    return acc;
                  }, formInitialValues)
                : formInitialValues
            }
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <ErrorMessage
              error={error?.message || "Invalid phone or password"}
              visible={!!error}
            />
            <FormSingleImagePicker label="Product Photo" name="image" />
            <FormField
              autoCapitalize="words"
              name="name"
              label="Product Name"
              keyboardType="default"
            />
            <FormField
              autoCapitalize="words"
              name="description"
              label="Product Description"
              multiline
              keyboardType="default"
            />
            <FormNativePicker
              items={Object.values(DietaryChoice).map((value) => ({
                value,
                label: toTitleCase(value),
              }))}
              name="dietary"
              label="Dietary"
            />
            <FormField
              number
              keyboardType="number-pad"
              name="price"
              label="Price"
            />
            <SubmitButton title="Save Product" />
          </Form>
        )}
      </Screen>
    </>
  );
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label("Product Name"),
  dietary: Yup.string()
    .oneOf(Object.values(DietaryChoice))
    .required()
    .label("Dietary"),
  price: Yup.string().required().label("Price"),
  isShown: Yup.boolean().required().label("Shown to users"),
  description: Yup.string().required().label("Description"),
});

const formInitialValues: CreateProductInput = {
  name: "",
  dietary: null,
  price: 0,
  isShown: true,
  description: "",
  image: "",
};

type CreateProductInput = {
  name: string;
  dietary: DietaryChoice | null;
  price: number;
  isShown: boolean;
  description: string;
  image: string;
};
