import { useEffect, useRef, useState } from "react";
import {
  Button,
  FlatList,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
  View,
} from "react-native";
import * as Clipboard from "expo-clipboard";
import Constants from "expo-constants";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useHeaderHeight } from "@react-navigation/elements";
import { RouteProp, useScrollToTop } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import dayjs from "dayjs";

import colors from "@acme/common-utils/colors";

import ActivityIndicator from "~/components/ActivityIndicator";
import Alert from "~/components/Alert";
import AppText from "~/components/AppText";
import AppTextInput from "~/components/AppTextInput";
import InfoText from "~/components/InfoText";
import Screen from "~/components/Screen";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { useAuthStore } from "~/store";
import { RouterOutputs, trpc } from "~/utils/trpc";

export default function ChatScreen({
  navigation,
  route,
}: {
  navigation: NativeStackNavigationProp<AppNavigatorParamList, routes.CHAT>;
  route: RouteProp<AppNavigatorParamList, routes.CHAT>;
}) {
  const { chatId, fullName, noPaddingBottom } = route.params || {};

  const { data: chatData, isLoading: chatLoading } = trpc.chat.get.useQuery({
    chatId,
  });

  useEffect(() => {
    navigation.setOptions({
      headerTitle: fullName,
    });
  }, [fullName]);

  return (
    <>
      <ActivityIndicator visible={chatLoading} />
      {chatData ? (
        <ChatWithAdminScreen
          chatId={chatId}
          chatData={chatData}
          noPaddingBottom={noPaddingBottom}
        />
      ) : (
        <CreateChatScreen />
      )}
    </>
  );
}

function ChatWithAdminScreen({
  noPaddingBottom,
  chatId,
  chatData,
}: {
  noPaddingBottom?: boolean;
  chatId: string | undefined;
  chatData: RouterOutputs["chat"]["get"];
}) {
  const headerHeight = useHeaderHeight();
  const paddingBottom = Platform.OS === "ios" ? 36 : 8;

  const utils = trpc.useUtils();
  const user = useAuthStore((state) => state.user)!;
  const flatListRef = useRef<FlatList>(null);
  trpc.chat.listenToMessage.useSubscription(
    {
      chatId: chatData?.id,
    },
    {
      onData(data) {
        utils.chat.get.setData(
          {
            chatId,
          },
          (prev) => {
            if (!prev) return prev;
            const newMessages = [
              data,
              ...prev.messages.filter((message) => message.id !== message.text),
            ];
            return {
              ...prev,
              messages: newMessages,
            };
          },
        );
      },
      enabled: true,
    },
  );

  useScrollToTop(flatListRef);

  if (!chatData) return null;

  return (
    <Screen backgroundColor="white" noKeyboardAwareScroll noSafeArea>
      <FlatList
        inverted
        ref={flatListRef}
        data={chatData.messages}
        keyExtractor={(item) => item.id}
        renderItem={({ item: message }) => (
          <View
            key={message.id}
            className={`mt-1 flex-row ${
              message.sender.id === user.id ? "justify-end" : "justify-start"
            }`}
          >
            <TouchableOpacity
              onPress={() => {
                Alert.alert(
                  "Info",
                  `Message: ${message.text}
Sender: ${message.sender.fullName}
Sent on: ${dayjs(message.createdAt).format("MMM DD, YYYY [at] h:mm A")}`,
                  [
                    {
                      text: "Cancel",
                      style: "cancel",
                    },
                    {
                      text: "Copy Message",
                      onPress: () => Clipboard.setStringAsync(message.text),
                    },
                  ],
                );
              }}
              className={`rounded-3xl px-4 py-2 ${
                message.id === message.text
                  ? "bg-[#43cc68]"
                  : message.sender.id === user.id
                  ? "bg-primary"
                  : "bg-lightGray"
              }`}
            >
              <AppText
                className={`text-lg ${
                  message.sender.id === user.id ? "text-white" : "text-black"
                }`}
              >
                {message.text}
              </AppText>
            </TouchableOpacity>
          </View>
        )}
        keyboardDismissMode="interactive"
        keyboardShouldPersistTaps="handled"
        className="px-3"
      />

      {Platform.OS === "ios" ? (
        <KeyboardAvoidingView
          behavior="padding"
          keyboardVerticalOffset={
            headerHeight - (noPaddingBottom ? 0 : paddingBottom)
          }
        >
          <View
            className="flex-row items-center bg-white px-4"
            style={{
              paddingBottom: noPaddingBottom ? 0 : paddingBottom,
            }}
          >
            <TextArea chatDataId={chatData.id} chatId={chatId} />
          </View>
        </KeyboardAvoidingView>
      ) : (
        <View
          className="flex-row items-center bg-white px-4"
          style={{
            paddingBottom: noPaddingBottom ? 0 : paddingBottom,
          }}
        >
          <TextArea chatDataId={chatData.id} chatId={chatId} />
        </View>
      )}
    </Screen>
  );
}

function CreateChatScreen() {
  const utils = trpc.useUtils();
  const [artificialLoading, setArtificialLoading] = useState(false);
  const { mutate: createChat, isLoading: createChatLoading } =
    trpc.chat.create.useMutation({
      onSuccess: () => {
        setArtificialLoading(true);
        utils.invalidate();
      },
    });
  return (
    <>
      <ActivityIndicator visible={createChatLoading || artificialLoading} />
      <Screen backgroundColor="white" className="flex-1 px-5">
        <View className="mx-auto w-3/5 flex-1 items-center justify-center">
          <InfoText
            className="my-4"
            text="If you want to ask something, feel free to chat with us"
          />
          <Button
            title="Chat with Admin"
            color={colors.primary}
            onPress={() => {
              createChat();
            }}
          />
        </View>
      </Screen>
    </>
  );
}

function TextArea({
  chatDataId,
  chatId,
}: {
  chatDataId: string;
  chatId: string | undefined;
}) {
  const [message, setMessage] = useState("");
  const user = useAuthStore((state) => state.user)!;
  const utils = trpc.useUtils();
  const { mutate: send } = trpc.chat.sendMessage.useMutation({
    onMutate: (variables) => {
      utils.chat.get.setData(
        {
          chatId,
        },
        (prev) => {
          if (!prev) return prev;
          const newMessages = [
            {
              id: variables.message,
              chatId: chatDataId,
              text: variables.message,
              sender: {
                id: user.id,
                fullName: user.fullName,
                avatar: user.avatar,
              },
              createdAt: new Date(),
            },
            ...prev.messages,
          ];
          return {
            ...prev,
            messages: newMessages,
          };
        },
      );
    },
    onSettled(data, error, variables, context) {
      if (error) {
        Alert.alert("Error", error.message);
      }
    },
  });

  return (
    <>
      <View className="flex-1">
        <AppTextInput
          accessibilityLabel="Message"
          className="max-h-32"
          multiline
          placeholder="Type a message"
          value={message}
          onChangeText={(text) => setMessage(text)}
        />
      </View>
      <TouchableOpacity
        disabled={!message}
        onPress={() => {
          send({
            message,
            chatId: chatDataId,
          });
          setMessage("");
        }}
      >
        <View className="ml-2">
          <MaterialCommunityIcons
            name="send-circle"
            size={40}
            color={message ? colors.primary : colors.lightGray2}
          />
        </View>
      </TouchableOpacity>
    </>
  );
}
