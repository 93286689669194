import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { useAuthContext } from "../auth/context";
import ActivityIndicator from "../components/ActivityIndicator";
import { AuthNavigatorParamList } from "../navigation/AuthNavigator";
import routes from "../navigation/routes";

export default function LoginIntermediateScreen({
  navigation,
  route,
}: {
  navigation: NativeStackNavigationProp<
    AuthNavigatorParamList,
    routes.LOGIN_INTERMEDIATE
  >;
  route: RouteProp<AuthNavigatorParamList, routes.LOGIN_INTERMEDIATE>;
}) {
  const { login } = useAuthContext();

  const { logInData, ...others } = route.params;

  if (logInData) {
    setTimeout(() => {
      login(logInData);
    }, 500);
  } else {
    navigation.navigate(routes.LOGIN_OTP, others);
  }

  return <ActivityIndicator visible />;
}
