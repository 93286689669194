import { useEffect, useState } from "react";
import {
  Image,
  Keyboard,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useFormikContext } from "formik";
import * as Yup from "yup";

import colors from "@acme/common-utils/colors";

import Alert from "~/components/Alert";
import AppButton from "~/components/AppButton";
import AppImage from "~/components/AppImage";
import AppText from "~/components/AppText";
import InfoText from "~/components/InfoText";
import { storageDomain } from "~/config/settings";
import { navigate } from "~/navigation/routeNavigation";
import { RouterOutputs, trpc } from "~/utils/trpc";
import { uploadImages } from "~/utils/upload";
import ActivityIndicator from "../components/ActivityIndicator";
import {
  ErrorMessage,
  Form,
  FormDatePicker,
  FormField,
  FormPasswordField,
  FormSingleImagePicker,
  SubmitButton,
} from "../components/forms";
import FormNativePicker from "../components/forms/FormNativePicker";
import LocationPickerModal from "../components/LocationPickerModal";
import Screen from "../components/Screen";
import { AppNavigatorParamList } from "../navigation/AppNavigator";
import routes from "../navigation/routes";
import { toTitleCase } from "../utils/toTitleCase";
import {
  DayChoice,
  DeliveryRecurrenceChoice,
  UserTypeChoice,
} from ".prisma/client";

export type LocationType = {
  name: string;
  lat: number;
  lng: number;
};

export default function UserCreateScreen({
  navigation,
  route,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.USER_CREATE
  >;
  route: RouteProp<AppNavigatorParamList, routes.USER_CREATE>;
}) {
  const utils = trpc.useUtils();
  const { userId } = route.params || {};
  const [artificialLoading, setArtificialLoading] = useState(false);

  const { data: userData, isLoading: userLoading } =
    trpc.user.getBasicInfo.useQuery(
      {
        userId: userId || "",
      },
      {
        enabled: !!userId,
      },
    );

  const { data: subscriptionListData, isLoading: subscriptionListLoading } =
    trpc.subscription.list.useQuery();

  const {
    mutate: createUser,
    isLoading: createUserLoading,
    error,
  } = trpc.user.create.useMutation({
    onSuccess: () => {
      Alert.alert(
        "Success",
        userId ? "User updated successfully" : "User created successfully",
      );
      utils.invalidate();
      setArtificialLoading(false);
      navigation.pop();
    },
    onError(error) {
      setArtificialLoading(false);
      Alert.alert("Error", error.message);
    },
  });

  const { mutateAsync: getSignedUrl } = trpc.s3.getSignedUrl.useMutation();

  const handleSubmit = async (input: {
    [K in keyof CreateUserInput]: NonNullable<CreateUserInput[K]>;
  }) => {
    setArtificialLoading(true);
    console.log("heh?");
    Keyboard.dismiss();
    createUser({
      ...input,
      userId,
      avatar: (await uploadImages([input.avatar], getSignedUrl, () => {}))[0],
    });
  };

  useEffect(() => {
    if (userId) {
      navigation.setOptions({
        title: "Edit",
      });
    }
  }, [userId]);

  return (
    <>
      <ActivityIndicator
        visible={
          artificialLoading ||
          createUserLoading ||
          (!!userId && userLoading) ||
          subscriptionListLoading
        }
      />
      <Screen backgroundColor="white" className="px-5 py-2 pb-10" noSafeArea>
        {!userId || userData ? (
          <Form
            initialValues={
              userData
                ? Object.keys(userData).reduce((acc, key) => {
                    if (
                      Object.prototype.hasOwnProperty.call(
                        formInitialValues,
                        key,
                      )
                    ) {
                      return { ...acc, [key]: userData[key] };
                    }
                    return acc;
                  }, formInitialValues)
                : formInitialValues
            }
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <ErrorMessage
              error={error?.message || "Invalid phone or password"}
              visible={!!error}
            />
            <FormContent
              userId={userId}
              subscriptionListData={subscriptionListData}
              userData={userData}
            />
          </Form>
        ) : null}
      </Screen>
    </>
  );
}

function FormContent({
  subscriptionListData,
  userId,
  userData,
}: {
  subscriptionListData: RouterOutputs["subscription"]["list"] | undefined;
  userId: string | undefined;
  userData: RouterOutputs["user"]["getBasicInfo"] | undefined;
}) {
  const { values, errors, setFieldValue } = useFormikContext<CreateUserInput>();

  console.log({
    errors,
  });

  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const [location, setLocation] = useState<null | LocationType>(null);

  useEffect(() => {
    if (location) {
      setFieldValue("latitude", location.lat);
      setFieldValue("longitude", location.lng);
      setFieldValue("locationName", location.name);
    }
  }, [location]);

  return (
    <>
      <FormNativePicker
        items={Object.values(UserTypeChoice)
          .map((value) => ({
            value,
            label: toTitleCase(value),
          }))
          .filter(({ value }) => value !== UserTypeChoice.ADMIN)}
        name="type"
        label="User Type"
      />
      <FormField
        autoCapitalize="words"
        autoCorrect={false}
        autoComplete="name"
        name="fullName"
        label="Full Name"
        keyboardType="default"
        textContentType="name"
      />
      <FormField
        name="email"
        keyboardType="email-address"
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="email"
        label="Email"
      />
      {userId ? null : (
        <>
          <FormPasswordField
            autoCapitalize="none"
            autoCorrect={false}
            autoComplete="password-new"
            name="password"
            label="Password"
            textContentType="password"
          />
          <FormPasswordField
            autoCapitalize="none"
            autoCorrect={false}
            name="confirmPassword"
            label="Confirm Password"
            textContentType="password"
          />
        </>
      )}
      <FormField
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="tel-national"
        name="phone"
        label="Phone"
        keyboardType="phone-pad"
        textContentType="telephoneNumber"
      />
      <FormField
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="tel-national"
        name="secondaryPhone"
        label="Secondary Phone"
        keyboardType="phone-pad"
        textContentType="telephoneNumber"
      />
      <FormField multiline name="about" label="About" />
      <FormSingleImagePicker circle label="Passport Size Photo" name="avatar" />

      <AppText className="text-mediumGray mx-3 mt-2 text-sm">Location</AppText>
      <TouchableHighlight
        underlayColor={colors.highlight}
        onPress={() => {
          setLocationModalVisible(true);
        }}
        className={`border-primary my-2 flex-row items-center justify-center overflow-hidden rounded-xl border-2`}
      >
        <AppText className="flex-1 p-4 text-left">
          {values.locationName || "Tap to pick location"}
        </AppText>
      </TouchableHighlight>

      {/* {Platform.OS === "web" ? null : ( */}
      <LocationPickerModal
        modalVisible={locationModalVisible}
        setModalVisible={setLocationModalVisible}
        location={location}
        setLocation={setLocation}
      />
      {/* )} */}

      {values.type === UserTypeChoice.DRIVER ? (
        <>
          <FormField
            name="driverTruckNumber"
            label="Truck Number"
            keyboardType="default"
            textContentType="none"
          />
          <FormField
            name="driverTruckDetails"
            label="Truck Details"
            keyboardType="default"
            textContentType="none"
          />
        </>
      ) : null}
      {values.type === UserTypeChoice.CUSTOMER ? (
        <>
          <FormNativePicker
            items={Object.values(DeliveryRecurrenceChoice).map((value) => ({
              value,
              label: toTitleCase(value),
            }))}
            name="deliveryRecurrence"
            label="Delivery Recurrence"
          />
          {values.deliveryRecurrence === DeliveryRecurrenceChoice.MONTHLY ? (
            <FormField
              number
              keyboardType="numeric"
              name="deliveryDateOfMonth"
              label="Delivery Date of Month"
            />
          ) : null}
          {values.deliveryRecurrence === DeliveryRecurrenceChoice.WEEKLY ? (
            <FormNativePicker
              items={Object.values(DayChoice).map((value) => ({
                value,
                label: toTitleCase(value),
              }))}
              name="deliveryDayOfWeek"
              label="Delivery Day of Week"
            />
          ) : null}
          {values.deliveryRecurrence === DeliveryRecurrenceChoice.ONCE ? (
            <FormDatePicker
              maximumDate={undefined}
              minimumDate={new Date()}
              name="deliveryDate"
              label="Delivery Date"
            />
          ) : null}
          {subscriptionListData ? (
            <FormNativePicker
              items={Object.values(subscriptionListData).map((value) => ({
                value: value.id,
                label: toTitleCase(value.name),
              }))}
              name="subscriptionId"
              label="Subscription"
            />
          ) : null}
          <FormField
            number
            keyboardType="numeric"
            name="subscriptionTotalDeliveries"
            label="Number of Total Deliveries"
          />
          {userId ? (
            <FormField
              number
              keyboardType="numeric"
              name="deliveriesLeft"
              label="Remaining Deliveries"
            />
          ) : null}
        </>
      ) : null}

      {userData?.type === UserTypeChoice.CUSTOMER ? (
        <>
          <AppText className="text-mediumGray mx-3 mt-2 text-sm">
            Assigned Driver
          </AppText>
          {userData.assignedDriver ? (
            <View className="flex-row items-center px-5 py-3">
              <View className="h-12 w-12">
                {userData.assignedDriver.avatar ? (
                  <AppImage
                    className="h-12 w-12 rounded-full"
                    source={{
                      uri: `${storageDomain}/${userData.assignedDriver.avatar}`,
                    }}
                  />
                ) : (
                  <Image
                    source={require("../assets/default-avatar.png")}
                    className="h-12 w-12 rounded-full"
                  />
                )}
              </View>

              <View className="ml-4 flex-1">
                <AppText className="text-lg">
                  {userData.assignedDriver.fullName}
                </AppText>
                <View className="flex-row justify-between">
                  <AppText className="text-mediumGray text-base">
                    {userData.assignedDriver.phone}
                  </AppText>
                </View>
              </View>
              <TouchableOpacity
                accessibilityRole="button"
                onPress={() => {
                  navigate(routes.USER_ASSIGN_DRIVER_LIST, {
                    customerId: userId,
                  });
                }}
              >
                <MaterialCommunityIcons
                  name="pencil"
                  size={24}
                  color={colors.primary}
                />
              </TouchableOpacity>
            </View>
          ) : (
            <View className="my-4">
              <InfoText text="No driver assigned" />
              <AppButton
                className="mx-auto px-4"
                color="bg-primary"
                title="Assign Driver"
                textColor="text-white"
                onPress={() => {
                  navigate(routes.USER_ASSIGN_DRIVER_LIST, {
                    customerId: userId,
                  });
                }}
              />
            </View>
          )}
        </>
      ) : null}

      <SubmitButton title={userId ? "Update User" : "Create User"} />
    </>
  );
}

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required().label("Full Name"),
  email: Yup.string().required().email().label("Email"),
  // password: Yup.string()
  //   .required("Password is required")
  //   .matches(
  //     /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  //     "Password must contain at least one capital letter, one number, one symbol, and be at least 8 characters long",
  //   )
  //   .label("Password"),
  // confirmPassword: Yup.string()
  //   .oneOf([Yup.ref("password")], "Passwords must match")
  //   .label("Confirm Password"),
  phone: Yup.string()
    .required()
    .label("Phone")
    .max(10, "Phone number must be 10 digits")
    .min(10, "Phone number must be 10 digits"),
  type: Yup.string().oneOf(Object.values(UserTypeChoice)).required(),
  latitude: Yup.number().required().label("Latitude"),
  longitude: Yup.number().required().label("Longitude"),
  deliveryRecurrence: Yup.string()
    .oneOf(Object.values(DeliveryRecurrenceChoice))
    .nullable(),
  deliveryDateOfMonth: Yup.number().label("Delivery Date of Month"),
  deliveryDayOfWeek: Yup.string()
    .oneOf(Object.values(DayChoice))
    .label("Delivery Day of Week")
    .nullable(),
  deliveryDate: Yup.date().label("Delivery Date").nullable(),
  subscriptionTotalDeliveries: Yup.number().label("Total Subscription Days"),
  deliveriesLeft: Yup.number().label("Remaining Deliveries"),
});

const formInitialValues: CreateUserInput = {
  fullName: "",
  email: "",
  password: "",
  confirmPassword: "",
  phone: "",
  secondaryPhone: "",
  type: null,
  about: "",
  avatar: "",
  locationName: "",
  latitude: 0,
  longitude: 0,
  deliveryRecurrence: null,
  deliveryDateOfMonth: 0,
  deliveryDayOfWeek: null,
  deliveryDate: null,
  subscriptionId: "",
  subscriptionTotalDeliveries: 0,
  deliveriesLeft: 0,
  driverTruckNumber: "",
  driverTruckDetails: "",
};

type CreateUserInput = {
  fullName: string;
  email: string;
  password: string;
  confirmPassword: string;
  phone: string;
  secondaryPhone: string;
  type: UserTypeChoice | null;
  about: string;
  avatar: string;
  locationName: string;
  latitude: number;
  longitude: number;
  deliveryRecurrence: DeliveryRecurrenceChoice | null;
  deliveryDateOfMonth: number;
  deliveryDayOfWeek: DayChoice | null;
  deliveryDate: Date | null;
  subscriptionId: string;
  subscriptionTotalDeliveries: number;
  deliveriesLeft: number;
  driverTruckNumber: string;
  driverTruckDetails: string;
};
