import { useState } from "react";
import {
  Image,
  ActivityIndicator as NativeActivityIndicator,
  RefreshControl,
  TouchableHighlight,
  View,
} from "react-native";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import colors from "@acme/common-utils/colors";

import ActivityIndicator from "~/components/ActivityIndicator";
import Alert from "~/components/Alert";
import AppTextInput from "~/components/AppTextInput";
import InfoText from "~/components/InfoText";
import { ListTitle } from "~/components/lists/ListTitle";
import { storageDomain } from "~/config/settings";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { trpc } from "~/utils/trpc";
import useDebounce from "~/utils/useDebounce";
import AppImage from "../components/AppImage";
import AppText from "../components/AppText";
import { ListItemSeparator } from "../components/lists";
import Screen from "../components/Screen";
import { UserTypeChoice } from ".prisma/client";

export default function UsersAssignDriverListScreen({
  navigation,
  route,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.USER_ASSIGN_DRIVER_LIST
  >;
  route: RouteProp<AppNavigatorParamList, routes.USER_ASSIGN_DRIVER_LIST>;
}) {
  const { customerId } = route.params;

  const utils = trpc.useUtils();

  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useDebounce(
    () => {
      setSearchQuery(search);
    },
    [search],
    500,
  );

  const { data, isLoading, refetch } = trpc.user.list.useQuery({
    keyword: searchQuery,
    type: UserTypeChoice.DRIVER,
  });

  const { mutate: assignDriver, isLoading: assignDriverLoading } =
    trpc.user.assignDriver.useMutation({
      onSuccess() {
        utils.invalidate();
        Alert.alert("Success", "Driver assigned successfully");
        navigation.goBack();
      },
    });

  return (
    <>
      <ActivityIndicator
        className="bg-iosBackground"
        visible={assignDriverLoading}
      />
      <Screen
        className="flex-1 px-5"
        noSafeArea
        refreshControl={
          <RefreshControl refreshing={false} onRefresh={refetch} />
        }
      >
        <AppTextInput
          clearButtonMode="always"
          onChangeText={(text) => setSearch(text)}
          value={search}
          placeholder="Search"
          className="mt-2"
          noBorder
          textClassName="bg-gray-200 flex-1 px-3 py-2 text-base leading-[20px]"
        />
        <ListTitle name="Drivers" />
        {isLoading && (
          <View className="h-32 justify-center">
            <NativeActivityIndicator color={colors.primary} size="large" />
          </View>
        )}
        {data && (
          <>
            {data.length === 0 && (
              <View className="py-4">
                <InfoText text="There are no drivers" />
              </View>
            )}
            <View>
              <View className="rounded-xl bg-white">
                {data.map(({ id, avatar, fullName, type, phone }, index) => (
                  <TouchableHighlight
                    key={id}
                    accessibilityRole="button"
                    underlayColor={colors.highlight}
                    onPress={() => {
                      assignDriver({
                        customerId,
                        driverId: id,
                      });
                    }}
                  >
                    <View>
                      {index === 0 ? null : (
                        <View className="pl-5">
                          <ListItemSeparator />
                        </View>
                      )}
                      <View className="flex-row items-center px-5 py-3">
                        <View className="h-12 w-12">
                          {avatar ? (
                            <AppImage
                              source={{
                                uri: `${storageDomain}/${avatar}`,
                              }}
                              className="h-12 w-12 rounded-full"
                            />
                          ) : (
                            <Image
                              source={require("../assets/default-avatar.png")}
                              className="h-12 w-12 rounded-full"
                            />
                          )}
                        </View>
                        <View className="ml-4 flex-1">
                          <AppText className="text-lg">{fullName}</AppText>
                          <View className="flex-row justify-between">
                            <AppText className="text-mediumGray text-base">
                              {phone}
                            </AppText>
                            {/* <AppText className="text-mediumGray text-base">
                            {toTitleCase(type)}
                          </AppText> */}
                          </View>
                        </View>
                      </View>
                    </View>
                  </TouchableHighlight>
                ))}
              </View>
            </View>
          </>
        )}
      </Screen>
    </>
  );
}
