import { useEffect, useState } from "react";
import { Keyboard, Modal, View } from "react-native";
import { Link } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import * as Yup from "yup";

import colors from "@acme/common-utils/colors";

import Alert from "~/components/Alert";
import { useBearStore } from "~/store";
import { trpc } from "~/utils/trpc";
import ActivityIndicator from "../components/ActivityIndicator";
import Text from "../components/AppText";
import {
  ErrorMessage,
  Form,
  FormField,
  FormPasswordField,
  SubmitButton,
} from "../components/forms";
import LogoHeader from "../components/LogoHeader";
import Screen from "../components/Screen";
import defaultStyles from "../config/styles";
import { AuthNavigatorParamList } from "../navigation/AuthNavigator";
import routes from "../navigation/routes";
import WelcomeModal from "./WelcomeModal";

export default function LoginScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<AuthNavigatorParamList, routes.LOGIN>;
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const initialLoaded = useBearStore((state) => state.initialLoaded);

  const { mutate: useSendOTPForForgotPassword } =
    trpc.user.sendOTPForForgotPassword.useMutation({
      onError: (error_) => {
        Alert.alert("Error", error_.message);
        throw new Error(error_.message);
      },
      onSuccess(_, variables) {
        navigation.push(routes.RESET_PASSWORD_OTP, {
          email: variables.email,
        });
      },
    });

  const {
    mutate: useSendOTPForLogin,
    isLoading,
    error,
  } = trpc.user.sendOTPForLogin.useMutation({
    onError(error, variables) {
      if (error.message === "Reset your password before logging in with OTP") {
        useSendOTPForForgotPassword({
          email: variables.email!,
        });
      } else {
        Alert.alert("Error", error.message);
        throw new Error(error.message);
      }
    },
    onSuccess(_, variables) {
      navigation.navigate(routes.LOGIN_INTERMEDIATE, {
        email: variables.email,
        password: variables.password,
      });
    },
  });

  const handleSubmit = async ({ email, password }: LoginCredentials) => {
    Keyboard.dismiss();
    useSendOTPForLogin({
      email,
      password,
    });
  };

  useEffect(() => {
    if (initialLoaded) {
      setModalOpen(true);
    }
  }, [initialLoaded]);
  return (
    <>
      <ActivityIndicator visible={isLoading} />
      <Screen className="p-5" backgroundColor="white">
        <LogoHeader />
        <Text bigText className="my-5 font-bold">
          Login
        </Text>
        <Form
          initialValues={initialForm}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <ErrorMessage
            error={error?.message || "Invalid email or password"}
            visible={
              !!error &&
              error?.message !==
                "Reset your password before logging in with OTP"
            }
          />
          <FormField
            autoCapitalize="none"
            autoCorrect={false}
            autoComplete="email"
            name="email"
            label="Email"
            keyboardType="email-address"
            textContentType="emailAddress"
          />
          <FormPasswordField
            autoCapitalize="none"
            autoCorrect={false}
            autoComplete="password"
            name="password"
            label="Password"
            textContentType="password"
          />
          <Link
            style={[
              defaultStyles.text,
              {
                textAlign: "right",
                color: colors.primary,
                marginBottom: 10,
              },
            ]}
            to={
              {
                screen: routes.RESET_PASSWORD,
              } as any
            }
          >
            Forgot Password?
          </Link>
          <SubmitButton title="Login" />
        </Form>
      </Screen>

      <Modal
        animationType="slide"
        presentationStyle="pageSheet"
        visible={modalOpen}
        onRequestClose={() => setModalOpen(false)}
      >
        <WelcomeModal onClose={() => setModalOpen(false)} />
      </Modal>
    </>
  );
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required().label("Phone Number"),
  password: Yup.string().required().min(4).label("Password"),
});

const initialForm = { email: "", password: "" };
export type LoginCredentials = typeof initialForm;
