import { useEffect } from "react";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { trpc } from "~/utils/trpc";
import ActivityIndicator from "../components/ActivityIndicator";
import AppButton from "../components/AppButton";
import Text from "../components/AppText";
import LogoHeader from "../components/LogoHeader";
import OTPPasswordBox from "../components/OTPPasswordBox";
import Screen from "../components/Screen";
import { AuthNavigatorParamList } from "../navigation/AuthNavigator";
import routes from "../navigation/routes";

export default function LoginOTPScreen({
  route,
  navigation,
}: {
  route: RouteProp<AuthNavigatorParamList, routes.LOGIN_OTP>;
  navigation: NativeStackNavigationProp<
    AuthNavigatorParamList,
    routes.LOGIN_OTP
  >;
}) {
  const { email, password } = route.params;

  const {
    mutate: useLoginUsingOTP,
    isLoading: loading,
    error,
  } = trpc.user.login.useMutation({
    onSettled: (data) => {
      if (!data) return;
      navigation.navigate(routes.LOGIN_INTERMEDIATE, {
        logInData: data,
        email: "",
        password: "",
      });
    },
    onError: (error_) => {
      throw new Error(error_.message);
    },
  });

  const handleSubmit = async ({ pin: otp }: { pin: string }) => {
    useLoginUsingOTP({
      otp,
      email,
      password,
    });
  };

  useEffect(() => {
    // setTimeout(() => {
    //   inputRef.current?.focusField(0);
    // }, 500);
    navigation.addListener("beforeRemove", (e) => {
      if (e.data.action.type === "GO_BACK") {
        e.preventDefault();
      }
    });
    return () => {
      navigation.removeListener("beforeRemove", () => {});
    };
  }, []);

  return (
    <>
      <ActivityIndicator visible={loading} />
      <Screen noKeyboardAwareScroll backgroundColor="white" className="p-5">
        <LogoHeader />
        <Text bigText className="my-5 font-bold">
          Enter the OTP
        </Text>
        {/* <ErrorMessage error={error?.message} visible={!!error} />
        <OTPInputView
          ref={inputRef}
          autoFocusOnLoad={false}
          pinCount={5}
          code={code}
          onCodeChanged={(newCode) => setCode(newCode)}
          style={{ marginTop: 4, height: 72 }}
          codeInputFieldStyle={styles.underlineStyleBase}
          codeInputHighlightStyle={styles.underlineStyleHighLighted}
          onCodeFilled={(otp) => handleSubmit({ otp })}
        /> */}
        <OTPPasswordBox
          label="OTP"
          maxLength={5}
          handleSubmit={handleSubmit}
          error={error?.message}
        />
        <AppButton
          // className="my-6"
          title="Redo login"
          onPress={() => {
            navigation.pop();
            setTimeout(() => {
              navigation.navigate(routes.LOGIN);
            }, 100);
          }}
        />
      </Screen>
    </>
  );
}
