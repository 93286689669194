import { useState } from "react";
import {
  ActivityIndicator as NativeActivityIndicator,
  RefreshControl,
  TouchableHighlight,
  View,
} from "react-native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import colors from "@acme/common-utils/colors";

import AppTextInput from "~/components/AppTextInput";
import { ImageList } from "~/components/ImageList";
import InfoText from "~/components/InfoText";
import { ListTitle } from "~/components/lists/ListTitle";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { useAuthStore } from "~/store";
import { toTitleCase } from "~/utils/toTitleCase";
import { trpc } from "~/utils/trpc";
import useDebounce from "~/utils/useDebounce";
import AppText from "../components/AppText";
import { ListItemSeparator } from "../components/lists";
import Screen from "../components/Screen";
import { UserTypeChoice } from ".prisma/client";

export default function FarmHouseListScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.FARM_HOUSE_LIST
  >;
}) {
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const user = useAuthStore((state) => state.user)!;

  useDebounce(
    () => {
      setSearchQuery(search);
    },
    [search],
    500,
  );

  const {
    data: farmHouseList,
    isLoading: farmHouseListLoading,
    refetch: farmHouseListRefetch,
  } = trpc.farmHouse.list.useQuery({
    keyword: searchQuery,
  });

  const {
    data: myBookingsList,
    isLoading: myBookingsListLoading,
    refetch: myBookingsListRefetch,
  } = trpc.farmHouse.listMyBookings.useQuery();

  return (
    <Screen
      className="px-5 pb-10"
      noSafeArea
      refreshControl={
        <RefreshControl
          refreshing={false}
          onRefresh={() => {
            farmHouseListRefetch();
            myBookingsListRefetch();
          }}
        />
      }
    >
      <AppTextInput
        accessibilityLabel="Search"
        clearButtonMode="always"
        onChangeText={(text) => setSearch(text)}
        value={search}
        placeholder="Search"
        className="mt-2"
        noBorder
        textClassName="bg-gray-200 flex-1 px-3 py-2 text-base leading-[20px]"
      />
      <ListTitle name="New Booking" />
      {farmHouseListLoading && (
        <View className="h-32 justify-center">
          <NativeActivityIndicator color={colors.primary} size="large" />
        </View>
      )}
      {farmHouseList && (
        <>
          {farmHouseList.length === 0 && (
            <View className="py-4">
              <InfoText text="There are no farmhouses" />
            </View>
          )}
          <View>
            <View className="rounded-xl bg-white">
              {farmHouseList.map(
                (
                  { id, name, images, numberOfGuests, discountedPrice, price },
                  index,
                ) => (
                  <TouchableHighlight
                    key={id}
                    accessibilityRole="button"
                    underlayColor={colors.highlight}
                    onPress={() => {
                      navigation.push(routes.FARM_HOUSE_BOOKING, {
                        farmHouseId: id,
                      });
                    }}
                  >
                    <View>
                      {index === 0 ? null : (
                        <View className="pl-5">
                          <ListItemSeparator />
                        </View>
                      )}
                      <View className="flex-row items-center px-5 py-3">
                        <View className="flex-1">
                          <AppText className="text-lg">{name}</AppText>
                          <View className="py-2">
                            <ImageList
                              noPictureFullScreen
                              className="h-40"
                              images={images}
                            />
                          </View>
                          <View className="flex-row justify-between">
                            <AppText className="text-mediumGray text-base">
                              No. of guests: {numberOfGuests}
                            </AppText>
                            <AppText className="text-mediumGray text-base">
                              <AppText className="line-through">
                                Rs. {price}
                              </AppText>{" "}
                              Rs. {discountedPrice}
                            </AppText>
                          </View>
                        </View>
                      </View>
                    </View>
                  </TouchableHighlight>
                ),
              )}
            </View>
          </View>
        </>
      )}

      {myBookingsList && myBookingsList.length > 0 && (
        <>
          <ListTitle name="My bookings" />
          {user.type === UserTypeChoice.CUSTOMER && myBookingsListLoading && (
            <View className="h-32 justify-center">
              <NativeActivityIndicator color={colors.primary} size="large" />
            </View>
          )}
          <View>
            <View className="rounded-xl bg-white">
              {myBookingsList.map(
                (
                  {
                    id,
                    bookingStatus,
                    farmHouse: { id: farmHouseId, name, numberOfGuests },
                  },
                  index,
                ) => (
                  <TouchableHighlight
                    key={id}
                    accessibilityRole="button"
                    underlayColor={colors.highlight}
                    onPress={() => {
                      navigation.push(routes.FARM_HOUSE_BOOKING, {
                        farmHouseId,
                        farmHouseBookingId: id,
                      });
                    }}
                  >
                    <View>
                      {index === 0 ? null : (
                        <View className="pl-5">
                          <ListItemSeparator />
                        </View>
                      )}
                      <View className="flex-row items-center px-5 py-3">
                        <View className="flex-1">
                          <AppText className="text-lg">{name}</AppText>
                          <View className="flex-row justify-between">
                            <AppText className="text-mediumGray text-base">
                              No. of guests: {numberOfGuests}
                            </AppText>
                            <AppText className="text-mediumGray text-base">
                              {toTitleCase(bookingStatus)}
                            </AppText>
                          </View>
                        </View>
                      </View>
                    </View>
                  </TouchableHighlight>
                ),
              )}
            </View>
          </View>
        </>
      )}
    </Screen>
  );
}
