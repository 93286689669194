import {
  Image,
  Linking,
  ActivityIndicator as NativeActivityIndicator,
  RefreshControl,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import dayjs from "dayjs";

import colors from "@acme/common-utils/colors";

import Alert from "~/components/Alert";
import AppListItems from "~/components/AppListItems";
import AppTextInput from "~/components/AppTextInput";
import InfoText from "~/components/InfoText";
import { ListTitle } from "~/components/lists/ListTitle";
import NotSubscribed from "~/components/NotSubscribed";
import { storageDomain } from "~/config/settings";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { useAuthStore } from "~/store";
import useTextLang from "~/utils/textLang";
import { toTitleCase } from "~/utils/toTitleCase";
import { trpc } from "~/utils/trpc";
import useDebounce from "~/utils/useDebounce";
import ActivityIndicator from "../components/ActivityIndicator";
import AppImage from "../components/AppImage";
import AppText from "../components/AppText";
import { ListItemSeparator } from "../components/lists";
import Screen from "../components/Screen";
import { DeliveryStatusChoice, UserTypeChoice } from ".prisma/client";

export default function DeliveryListScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.DELIVERY_LIST
  >;
}) {
  const user = useAuthStore((state) => state.user)!;

  const textLang = useTextLang();

  const utils = trpc.useUtils();
  const {
    data: deliveriesList,
    isLoading: deliveriesListLoading,
    refetch: deliveriesListRefetch,
  } = trpc.delivery.list.useQuery();

  const {
    data: unAssignedUsersList,
    isLoading: unAssignedUsersListLoading,
    refetch: unAssignedUsersListRefetch,
  } = trpc.user.listUnassignedUsers.useQuery(undefined, {
    enabled: user.type === UserTypeChoice.ADMIN,
  });

  const { mutate: setStatus, isLoading: setStatusLoading } =
    trpc.delivery.setStatus.useMutation({
      onSuccess() {
        utils.delivery.invalidate();
        Alert.alert("Success", "Delivery status updated");
      },
      onError(error) {
        Alert.alert("Error", error.message);
        utils.invalidate();
      },
    });

  return (
    <>
      <Screen
        className="px-5 pb-5"
        noSafeArea
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={() => {
              deliveriesListRefetch();
              unAssignedUsersListRefetch();
            }}
          />
        }
      >
        {user.type === UserTypeChoice.ADMIN && (
          <>
            <ListTitle name="Unassigned Subscribers" />
            {unAssignedUsersListLoading && (
              <View className="h-32 justify-center">
                <NativeActivityIndicator color={colors.primary} size="large" />
              </View>
            )}
            {unAssignedUsersList && (
              <>
                {unAssignedUsersList.length === 0 && (
                  <View className="py-4">
                    <InfoText text="All subscribers are assigned their driver" />
                  </View>
                )}
                <View>
                  <View className="rounded-xl bg-white">
                    {unAssignedUsersList.map(
                      ({ id, avatar, fullName, type, phone }, index) => (
                        <TouchableHighlight
                          key={id}
                          accessibilityRole="button"
                          underlayColor={colors.highlight}
                          onPress={() => {
                            navigation.push(routes.USER_ASSIGN_DRIVER_LIST, {
                              customerId: id,
                            });
                          }}
                        >
                          <View>
                            {index === 0 ? null : (
                              <View className="pl-5">
                                <ListItemSeparator />
                              </View>
                            )}
                            <View className="flex-row items-center px-5 py-3">
                              <View className="h-12 w-12">
                                {avatar ? (
                                  <AppImage
                                    source={{
                                      uri: `${storageDomain}/${avatar}`,
                                    }}
                                    className="h-12 w-12 rounded-full"
                                  />
                                ) : (
                                  <Image
                                    source={require("../assets/default-avatar.png")}
                                    className="h-12 w-12 rounded-full"
                                  />
                                )}
                              </View>
                              <View className="ml-4 flex-1">
                                <AppText className="text-lg">
                                  {fullName}
                                </AppText>
                                <View className="flex-row justify-between">
                                  <AppText className="text-mediumGray text-base">
                                    {phone}
                                  </AppText>
                                  {/* <AppText className="text-mediumGray text-base">
                            {toTitleCase(type)}
                          </AppText> */}
                                </View>
                              </View>
                            </View>
                          </View>
                        </TouchableHighlight>
                      ),
                    )}
                  </View>
                </View>
              </>
            )}
          </>
        )}

        <ListTitle name={textLang("Deliveries", "डिलिभरी")} />
        {deliveriesListLoading && (
          <View className="h-32 justify-center">
            <NativeActivityIndicator color={colors.primary} size="large" />
          </View>
        )}
        {deliveriesList && (
          <>
            {deliveriesList.length === 0 && (
              <View className="py-4">
                <InfoText text="There are no deliveries" />
              </View>
            )}
            <View>
              <View className="rounded-xl bg-white">
                {deliveriesList.map(
                  ({ id, deliveryDate, status, customer }, index) => (
                    // <TouchableHighlight
                    //   key={id}
                    //   accessibilityRole="button"
                    //   underlayColor={colors.highlight}
                    //   onPress={() => {}}
                    // >
                    <View key={id}>
                      {index === 0 ? null : (
                        <View className="pl-5">
                          <ListItemSeparator />
                        </View>
                      )}
                      <View className="px-5 py-3">
                        <View className="flex-row items-center">
                          <View className="h-12 w-12">
                            {customer.avatar ? (
                              <AppImage
                                className="h-12 w-12 rounded-full"
                                source={{
                                  uri: `${storageDomain}/${customer.avatar}`,
                                }}
                              />
                            ) : (
                              <Image
                                source={require("../assets/default-avatar.png")}
                                className="h-12 w-12 rounded-full"
                              />
                            )}
                          </View>
                          <View className="ml-4 flex-1">
                            <View className="flex-row">
                              <AppText className="flex-1 text-lg">
                                {customer.fullName}
                              </AppText>
                              <AppText
                                className={`${
                                  status === DeliveryStatusChoice.PENDING
                                    ? ""
                                    : status ===
                                      DeliveryStatusChoice.REJECTED_BY_DRIVER
                                    ? "text-red"
                                    : status ===
                                      DeliveryStatusChoice.COMPLETED_BY_DRIVER
                                    ? "text-primary"
                                    : ""
                                } text-lg`}
                              >
                                {status === DeliveryStatusChoice.PENDING
                                  ? textLang("Pending", "प्रलम्भित")
                                  : status ===
                                    DeliveryStatusChoice.REJECTED_BY_DRIVER
                                  ? textLang("Rejected", "अस्वीकृत")
                                  : status ===
                                    DeliveryStatusChoice.COMPLETED_BY_DRIVER
                                  ? textLang("Accepted", "स्वीकृत")
                                  : ""}
                              </AppText>
                            </View>
                            <View className="flex-row justify-between">
                              <AppText className="text-mediumGray text-base">
                                {dayjs(deliveryDate).format("MMM D, hh:mm A")}
                              </AppText>
                              <AppText className="text-mediumGray text-base">
                                {customer.phone}
                              </AppText>
                            </View>
                          </View>
                        </View>
                        <View className="mt-2 flex-row justify-evenly">
                          {[
                            UserTypeChoice.ADMIN as UserTypeChoice,
                            UserTypeChoice.DRIVER,
                          ].includes(user.type) ? (
                            <>
                              <TouchableOpacity
                                onPress={() => {
                                  Linking.openURL(`tel:${customer.phone}`);
                                }}
                                className="items-center"
                              >
                                <View className="mx-2 mt-2 items-center">
                                  <MaterialCommunityIcons
                                    name="phone"
                                    size={24}
                                    color={colors.primary}
                                  />
                                  <AppText
                                    className="text-primary text-sm"
                                    textAlign="center"
                                  >
                                    {textLang("Call", "कल")}
                                  </AppText>
                                </View>
                              </TouchableOpacity>
                              <TouchableOpacity
                                onPress={() => {
                                  Linking.openURL(
                                    `https://www.google.com/maps/dir/?api=1&destination=${customer.latitude},${customer.longitude}`,
                                  );
                                }}
                              >
                                <View className="mx-2 mt-2 items-center">
                                  <MaterialCommunityIcons
                                    name="directions"
                                    size={24}
                                    color={colors.softBlue}
                                  />
                                  <AppText
                                    className="text-softBlue text-sm"
                                    textAlign="center"
                                  >
                                    {textLang("Directions", "नक्सा")}
                                  </AppText>
                                </View>
                              </TouchableOpacity>
                              <TouchableOpacity
                                className={
                                  status !== DeliveryStatusChoice.PENDING
                                    ? "opacity-50"
                                    : ""
                                }
                                onPress={() => {
                                  Alert.alert(
                                    "Mark as completed?",
                                    "Are you sure you want to mark this delivery as completed?",
                                    [
                                      {
                                        text: "Cancel",
                                        style: "cancel",
                                      },
                                      {
                                        text: "OK",
                                        onPress: () => {
                                          setStatus({
                                            deliveryId: id,
                                            status:
                                              DeliveryStatusChoice.COMPLETED_BY_DRIVER,
                                          });
                                        },
                                      },
                                    ],
                                  );
                                }}
                                disabled={
                                  status !== DeliveryStatusChoice.PENDING
                                }
                              >
                                <View className="mx-2 mt-2 items-center">
                                  <MaterialCommunityIcons
                                    name="check"
                                    size={24}
                                    color={colors.primary}
                                  />
                                  <AppText
                                    className={"text-primary text-sm"}
                                    textAlign="center"
                                  >
                                    {textLang("Completed", "पूरा भयो")}
                                  </AppText>
                                </View>
                              </TouchableOpacity>
                              <TouchableOpacity
                                className={
                                  status !== DeliveryStatusChoice.PENDING
                                    ? "opacity-50"
                                    : ""
                                }
                                onPress={() => {
                                  Alert.alert(
                                    "Reject delivery?",
                                    "Are you sure you want to reject this delivery?",
                                    [
                                      {
                                        text: "Cancel",
                                        style: "cancel",
                                      },
                                      {
                                        text: "OK",
                                        onPress: () => {
                                          setStatus({
                                            deliveryId: id,
                                            status:
                                              DeliveryStatusChoice.REJECTED_BY_DRIVER,
                                          });
                                        },
                                      },
                                    ],
                                  );
                                }}
                                disabled={
                                  status !== DeliveryStatusChoice.PENDING
                                }
                              >
                                <View className="mx-2 mt-2 items-center">
                                  <MaterialCommunityIcons
                                    name="close"
                                    size={24}
                                    color={colors.red}
                                  />
                                  <AppText
                                    className="text-red text-sm"
                                    textAlign="center"
                                  >
                                    {textLang("Reject", "अस्वीकृत")}
                                  </AppText>
                                </View>
                              </TouchableOpacity>
                            </>
                          ) : null}
                          <TouchableOpacity
                            onPress={() => {
                              navigation.push(routes.USER_BASKET, {
                                userId: customer.id,
                              });
                            }}
                          >
                            <View className="mx-2 mt-2 items-center">
                              <MaterialCommunityIcons
                                name="cart"
                                size={24}
                                color={colors.primary}
                              />
                              <AppText
                                className="text-primary text-sm"
                                textAlign="center"
                              >
                                {textLang("Basket", "झोला")}
                              </AppText>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                    // </TouchableHighlight>
                  ),
                )}
              </View>
            </View>
          </>
        )}
      </Screen>
    </>
  );
}
