import { View } from "react-native";
import * as ImagePicker from "expo-image-picker";
import { FormikContextType, FormikValues, useFormikContext } from "formik";

import AppText from "../AppText";
import ImageInput from "../ImageInput";
import ErrorMessage from "./ErrorMessage";

export type ImageAssetOrString = ImagePicker.ImagePickerAsset | string;

export default function FormSingleImagePicker({
  name,
  label,
  circle,
  fullWidth,
}: {
  name: string;
  label: string;
  circle?: boolean;
  fullWidth?: boolean;
}) {
  const { errors, setFieldValue, touched, values } =
    useFormikContext<FormikContextType<FormikValues>>();
  const imageAsset: ImageAssetOrString = values[name];

  const handleImageAdd = (assets: ImageAssetOrString[]) => {
    setFieldValue(name, assets);
  };

  const handleImageRemove = () => {
    setFieldValue(name, "");
  };

  return (
    <>
      {label ? (
        <AppText className="text-mediumGray mx-3 my-2 text-sm">{label}</AppText>
      ) : null}
      <View className="items-center">
        {imageAsset ? (
          <ImageInput
            fullWidth={fullWidth}
            circle={circle}
            imageAsset={imageAsset}
            onImageChange={handleImageRemove}
          />
        ) : (
          <ImageInput
            circle={circle}
            imageAsset={imageAsset}
            onImageChange={handleImageAdd}
          />
        )}
      </View>
      <ErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
}
