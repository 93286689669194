import { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import colors from "@acme/common-utils/colors";

import AppTextInput from "~/components/AppTextInput";
import AppText from "../../components/AppText";

export default function StepperInput({
  counter,
  setCounter,
}: {
  counter: string;
  setCounter: (setter: (prevCounter: string) => string) => void;
}) {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const incr = 1;

  const decrementCounter = () => {
    setCounter((prevCounter) => {
      if (parseFloat(prevCounter) >= incr) {
        return (parseFloat(prevCounter) - incr).toString();
      }
      return "0";
    });
  };

  const incrementCounter = () => {
    setCounter((prevCounter) => (parseFloat(prevCounter) + incr).toString());
  };

  const handlePressInMinus = () => {
    const id = setInterval(decrementCounter, 100); // Adjust the timing as needed
    setIntervalId(id);
  };

  const handlePressInPlus = () => {
    const id = setInterval(incrementCounter, 100); // Adjust the timing as needed
    setIntervalId(id);
  };

  const handlePressOut = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  };

  useEffect(() => {
    setCounter((prevCounter) => {
      if (counter.length > 5) {
        return prevCounter.slice(0, 5);
      }
      return prevCounter;
    });
  }, [counter]);

  return (
    <View className="flex-row items-center justify-center">
      <TouchableOpacity
        disabled={parseFloat(counter) < incr}
        onPress={decrementCounter}
        onPressIn={handlePressInMinus}
        onPressOut={handlePressOut}
      >
        <View className="px-2">
          <MaterialCommunityIcons
            name="minus"
            size={24}
            color={
              parseFloat(counter) < incr ? colors.lightGray2 : colors.primary
            }
          />
        </View>
      </TouchableOpacity>
      <AppTextInput
        maxLength={5}
        keyboardType="decimal-pad"
        textClassName="text-lg leading-[22px] px-4 text-center"
        noBorder
        my0
        value={counter}
        onChangeText={(text) => {
          setCounter(() => {
            if (text === "") {
              return "0";
            }
            return text;
          });
        }}
      />
      <TouchableOpacity
        onPress={incrementCounter}
        onPressIn={handlePressInPlus}
        onPressOut={handlePressOut}
      >
        <View className="px-2">
          <MaterialCommunityIcons
            name="plus"
            size={24}
            color={colors.primary}
          />
        </View>
      </TouchableOpacity>
    </View>
  );
}

function StepperInputKgOrG({
  counter,
  setCounter,
  kgOrGram,
  setKgOrGram,
}: {
  counter: string;
  setCounter: React.Dispatch<React.SetStateAction<string>>;

  kgOrGram: "kg" | "g";
  setKgOrGram: React.Dispatch<React.SetStateAction<"kg" | "g">>;
}) {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const incr = kgOrGram === "kg" ? 1 : 50;

  const decrementCounter = () => {
    setCounter((prevCounter) =>
      (parseFloat(prevCounter) > incr
        ? parseFloat(prevCounter) - incr
        : incr
      ).toString(),
    );
  };

  const incrementCounter = () => {
    setCounter((prevCounter) => (parseFloat(prevCounter) + incr).toString());
  };

  const handlePressInMinus = () => {
    const id = setInterval(decrementCounter, 100); // Adjust the timing as needed
    setIntervalId(id);
  };

  const handlePressInPlus = () => {
    const id = setInterval(incrementCounter, 100); // Adjust the timing as needed
    setIntervalId(id);
  };

  const handlePressOut = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  };

  // // console.log(`${counter}`);

  useEffect(() => {
    // if (counter < incr) {
    //   setCounter(incr);
    //   setKgOrGram("g");
    // }
    if (counter.length > 5) {
      setCounter(counter.slice(0, 5));
    }
    if (parseFloat(counter) > 1000 && kgOrGram === "g") {
      setKgOrGram("kg");
      setCounter("1");
    }
  }, [counter, kgOrGram]);

  return (
    <View className="flex-row items-center justify-center pr-3">
      <TouchableOpacity
        disabled={parseFloat(counter) <= incr}
        onPress={() => {
          if (parseFloat(counter) > incr) {
            setCounter((parseFloat(counter) - incr).toString());
          }
        }}
        onPressIn={handlePressInMinus}
        onPressOut={handlePressOut}
      >
        <View className="px-2">
          <MaterialCommunityIcons
            name="minus"
            size={24}
            color={
              parseFloat(counter) <= incr ? colors.lightGray2 : colors.primary
            }
          />
        </View>
      </TouchableOpacity>
      <AppTextInput
        maxLength={5}
        keyboardType="decimal-pad"
        textClassName="text-lg leading-[22px] px-4 text-center"
        noBorder
        my0
        value={counter === "0" ? "" : counter}
        onChangeText={(text) => {
          if (text === "") {
            setCounter("0");
          } else {
            setCounter(text);
          }
        }}
      />
      <TouchableOpacity
        onPress={() => {
          setCounter((parseFloat(counter) + incr).toString());
        }}
        onPressIn={handlePressInPlus}
        onPressOut={handlePressOut}
      >
        <View className="px-2">
          <MaterialCommunityIcons
            name="plus"
            size={24}
            color={colors.primary}
          />
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        className="-rl-3 px-3 py-2"
        onPress={() => {
          setKgOrGram((prev) => (prev === "kg" ? "g" : "kg"));
        }}
      >
        <AppText className="text-primary text-base">{kgOrGram}</AppText>
      </TouchableOpacity>
    </View>
  );
}
