import * as Yup from "yup";

import {
  ErrorMessage,
  Form,
  FormField,
  FormPasswordField,
  SubmitButton,
} from "./forms";

export default function OTPPasswordBox({
  handleSubmit,
  error,
  label,
  maxLength,
}: {
  handleSubmit: (values: { pin: string }) => void;
  error?: string;
  label: string;
  maxLength: number;
}) {
  const validationSchema = Yup.object().shape({
    pin: Yup.string().max(maxLength).label(label),
  });

  return (
    <Form
      initialValues={initialForm}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <ErrorMessage
        error={error || "Invalid phone or password"}
        visible={!!error}
      />
      <FormField
        autoFocus
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="off"
        keyboardType="number-pad"
        name="pin"
        label={label}
      />
      <SubmitButton title="Continue" />
    </Form>
  );
}

const initialForm = { pin: "" };
