import { useEffect, useState } from "react";
import {
  ActivityIndicator as NativeActivityIndicator,
  RefreshControl,
  TouchableHighlight,
  View,
} from "react-native";
import { Calendar } from "react-native-calendars";
import { TouchableOpacity } from "react-native-gesture-handler";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import colors from "@acme/common-utils/colors";

import ActivityIndicator from "~/components/ActivityIndicator";
import InfoText from "~/components/InfoText";
import { ListTitle } from "~/components/lists/ListTitle";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { useAuthStore } from "~/store";
import { toTitleCase } from "~/utils/toTitleCase";
import { trpc } from "~/utils/trpc";
import AppText from "../components/AppText";
import { ListItemSeparator } from "../components/lists";
import Screen from "../components/Screen";
import { selectedColorMap } from "./FarmHouseBookingScreen";
import { BookingStatusChoice, UserTypeChoice } from ".prisma/client";

export default function FarmHouseSpecificBookingListScreen({
  navigation,
  route,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.FARM_HOUSE_SPECIFIC_BOOKING_LIST
  >;
  route: RouteProp<
    AppNavigatorParamList,
    routes.FARM_HOUSE_SPECIFIC_BOOKING_LIST
  >;
}) {
  const { farmHouseId } = route.params || {
    farmHouseId: "",
  };

  const { data, isLoading, refetch } = trpc.farmHouse.listBookings.useQuery({
    farmHouseId,
  });

  const [markedDates, setMarkedDates] = useState<{
    [key: string]: {
      selected: boolean;
      farmHouseBookingId?: string;
      selectedColor: string;
    };
  }>({});

  const user = useAuthStore((state) => state.user)!;

  useEffect(() => {
    if (data) {
      const markedDatesObj: {
        [key: string]: {
          selected: boolean;
          farmHouseBookingId?: string;
          selectedColor: string;
        };
      } = {};
      data
        .filter(
          ({ bookingStatus }) =>
            bookingStatus === BookingStatusChoice.APPROVED ||
            bookingStatus === BookingStatusChoice.PENDING,
        )
        .forEach(({ dates, bookingStatus, id: farmHouseBookingId }) => {
          const markedDates = dates.reduce(
            (acc, checkInDate) => ({
              ...acc,
              [checkInDate.toISOString().split("T")[0]]: {
                selected: true,
                farmHouseBookingId,
                selectedColor:
                  bookingStatus === BookingStatusChoice.APPROVED
                    ? selectedColorMap.RED
                    : bookingStatus === BookingStatusChoice.PENDING
                    ? selectedColorMap.YELLOW
                    : selectedColorMap.GREEN,
              },
            }),
            {},
          );
          Object.assign(markedDatesObj, markedDates);
        });
      setMarkedDates(markedDatesObj);
    }
  }, [data]);

  // useEffect(() => {
  //   if (user.type === UserTypeChoice.ADMIN) {
  //     navigation.setOptions({
  //       headerRight: () => (
  //         <TouchableOpacity
  //           onPress={() => {
  //             navigation.navigate(routes.FARM_HOUSE_LIST);
  //           }}
  //         >
  //           <MaterialCommunityIcons
  //             name="plus"
  //             size={24}
  //             color={colors.primary}
  //           />
  //         </TouchableOpacity>
  //       ),
  //     });
  //   }
  // }, [user]);

  return (
    <>
      <ActivityIndicator visible={isLoading} className="bg-iosBackground" />
      <Screen
        className="p-5 pb-10"
        noSafeArea
        refreshControl={
          <RefreshControl refreshing={false} onRefresh={refetch} />
        }
      >
        <Calendar
          style={{
            height: 350,
          }}
          current={new Date().toISOString().split("T")[0]}
          onDayPress={(day) => {
            const selectedDate = day.dateString;
            const selectedDateObject = markedDates[selectedDate];
            if (selectedDateObject) {
              navigation.push(routes.FARM_HOUSE_BOOKING, {
                farmHouseId,
                farmHouseBookingId: selectedDateObject.farmHouseBookingId,
              });
            }
          }}
          markedDates={markedDates}
        />
        <ListTitle name="Bookings" />
        {data && (
          <>
            {data.length === 0 && (
              <View className="py-4">
                <InfoText text="There are no bookings" />
              </View>
            )}
            <View>
              <View className="rounded-xl bg-white">
                {data.map(
                  (
                    {
                      id,
                      bookingStatus,
                      farmHouse,
                      userFullName,
                      userContact,
                      user,
                    },
                    index,
                  ) => (
                    <TouchableHighlight
                      key={id}
                      accessibilityRole="button"
                      underlayColor={colors.highlight}
                      onPress={() => {
                        navigation.push(routes.FARM_HOUSE_BOOKING, {
                          farmHouseId: farmHouse.id,
                          farmHouseBookingId: id,
                        });
                      }}
                    >
                      <View>
                        {index === 0 ? null : (
                          <View className="pl-5">
                            <ListItemSeparator />
                          </View>
                        )}
                        <View className="flex-row items-center px-5 py-3">
                          <View className="flex-1">
                            <AppText className="text-lg">
                              {user?.fullName || userFullName}
                            </AppText>
                            <View className="flex-row justify-between">
                              <AppText className="text-mediumGray text-base">
                                Farmhouse: {farmHouse.name}
                              </AppText>
                              <AppText className="text-mediumGray text-base">
                                {toTitleCase(bookingStatus)}
                              </AppText>
                            </View>
                          </View>
                        </View>
                      </View>
                    </TouchableHighlight>
                  ),
                )}
              </View>
            </View>
          </>
        )}
      </Screen>
    </>
  );
}
