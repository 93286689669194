import { TouchableOpacity, View } from "react-native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import Alert from "~/components/Alert";
import AppImage from "~/components/AppImage";
import InfoText from "~/components/InfoText";
import { ListTitle } from "~/components/lists/ListTitle";
import { storageDomain } from "~/config/settings";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { trpc } from "~/utils/trpc";
import ActivityIndicator from "../components/ActivityIndicator";
import Screen from "../components/Screen";

export default function FlyerListScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.FLYER_LIST
  >;
}) {
  const utils = trpc.useUtils();

  const { data: flyers, isLoading: isFlyersLoading } =
    trpc.flyer.list.useQuery();

  const { mutate: deleteFlyer, isLoading: isDeletingFlyer } =
    trpc.flyer.delete.useMutation({
      onSuccess: () => {
        Alert.alert("Success", "Flyer deleted successfully");
      },
      onError: (error) => {
        Alert.alert("Error", error.message);
      },
      onSettled: () => {
        utils.invalidate();
      },
    });

  return (
    <>
      <ActivityIndicator visible={isDeletingFlyer || isFlyersLoading} />
      <Screen noSafeArea backgroundColor="white">
        <View className="px-5">
          <ListTitle name="Flyers" />
        </View>
        {flyers?.length === 0 && (
          <InfoText
            text="No flyers found. Create one to get started."
            className="mt-5 text-center"
          />
        )}
        {flyers?.map((flyer) => (
          <TouchableOpacity
            onPress={() => {
              Alert.alert(
                "Delete flyer",
                "Are you sure you want to delete this flyer?",
                [
                  {
                    text: "Yes",
                    onPress: () => {
                      deleteFlyer({
                        flyerId: flyer.id,
                      });
                    },
                  },
                  {
                    text: "No",
                  },
                ],
              );
            }}
            key={flyer.id}
            className="h-40 pb-2"
          >
            <AppImage
              key={flyer.id}
              source={{
                uri: `${storageDomain}/${flyer.image}`,
              }}
              className="h-full w-full"
            />
          </TouchableOpacity>
        ))}
      </Screen>
    </>
  );
}
