import { useEffect } from "react";
import { TouchableOpacity, View } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  createNativeStackNavigator,
  NativeStackNavigationProp,
} from "@react-navigation/native-stack";

import colors from "@acme/common-utils/colors";

import { useAuthContext } from "~/auth/context";
import useNotifications from "~/hooks/useNotifications";
import ChatScreen from "~/screens/ChatScreen";
import DeliveryListScreen from "~/screens/DeliveryListScreen";
import FarmHouseBookingAdminScreen from "~/screens/FarmHouseBookingAdminScreen";
import FarmHouseBookingManageListScreen from "~/screens/FarmHouseBookingManageListScreen";
import FarmHouseBookingScreen from "~/screens/FarmHouseBookingScreen";
import FarmHouseCreateScreen from "~/screens/FarmHouseCreateScreen";
import FarmHouseListScreen from "~/screens/FarmHouseListScreen";
import FarmHouseManageListScreen from "~/screens/FarmHouseManageListScreen";
import FarmHouseSpecificBookingListScreen from "~/screens/FarmHouseSpecificBookingListScreen";
import FlyerCreateScreen from "~/screens/FlyerCreateScreen";
import FlyerListScreen from "~/screens/FlyerListScreen";
import MyBasketScreen from "~/screens/MyBasketScreen";
import NotificationsScreen from "~/screens/NotificationsScreen";
import ProductCreateScreen from "~/screens/ProductCreateScreen";
import ProductListScreen from "~/screens/ProductListScreen";
import ProductScreen from "~/screens/ProductScreen";
import ProductsManageListScreen from "~/screens/ProductsManageListScreen";
import RestaurantItemCreateScreen from "~/screens/RestaurantItemCreateScreen";
import RestaurantItemManageListScreen from "~/screens/RestaurantItemManageListScreen";
import RestaurantOrderItemListScreen from "~/screens/RestaurantOrderItemListScreen";
import RestaurantOrderListScreen from "~/screens/RestaurantOrderListScreen";
import RestaurantOrderSummaryScreen from "~/screens/RestaurantOrderSummaryScreen";
import SubscriptionCreateScreen from "~/screens/SubscriptionCreateScreen";
import SubscriptionListScreen from "~/screens/SubscriptionListScreen";
import UserBasketScreen from "~/screens/UserBasketScreen";
import UserCreateScreen from "~/screens/UserCreateScreen";
import UsersAssignDriverListScreen from "~/screens/UsersAssignDriverListScreen";
import UsersGeneralMemberListScreen from "~/screens/UsersGeneralMemberListScreen";
import UsersManageListScreen from "~/screens/UsersManageListScreen";
import useTextLang from "~/utils/textLang";
import authStorage from "../auth/storage";
import { animation } from "../config/animation";
import defaultStyles from "../config/styles";
import SettingsScreen from "../screens/SettingsScreen";
import HomeTabNavigator from "./HomeTabNavigator";
import routes from "./routes";

type HomeTabNavigatorParamList = {
  [routes.HOME_TAB]: undefined;
  [routes.DELIVERY_LIST]: undefined;
  [routes.ADMIN_DASHBOARD]: undefined;
  [routes.GENERAL_STAFF_DASHBOARD]: undefined;
  [routes.RESTAURANT_STAFF_DASHBOARD]: undefined;
  [routes.CUSTOMER_DASHBOARD]: undefined;
  [routes.MY_BASKET]:
    | {
        noBackRoute: boolean;
      }
    | undefined;
  [routes.USER_BASKET]: {
    userId: string;
  };
  [routes.NOTIFICATIONS]: {
    noBackRoute: boolean;
    userId?: string;
  };
  [routes.SETTINGS]: undefined;
  [routes.CHAT_LIST]: undefined;
};

type AccountNavigatorParamList = {
  [routes.SETTINGS]: undefined;
};

type MultipleNavigatorParamList = {
  [routes.PRODUCT_LIST]: undefined;
  [routes.FARM_HOUSE_LIST]: undefined;
  [routes.USER_PROFILE]: {
    noBackRoute?: boolean;
    userId?: string;
  };
  [routes.CHANGE_PASSWORD]: undefined;
  [routes.CHAT]: {
    noPaddingBottom?: boolean;
    chatId?: string;
    fullName?: string;
  };
  [routes.PRODUCT]: {
    productId: string;
  };
  [routes.PRODUCT_MANAGE_LIST]: undefined;
  [routes.FARM_HOUSE_MANAGE_LIST]: undefined;
  [routes.SUBSCRIPTION_LIST]: undefined;
  [routes.USER_CREATE]:
    | {
        userId: string;
      }
    | undefined;
  [routes.NOTIFICATIONS]: {
    noBackRoute: boolean;
    userId?: string;
  };
  [routes.SUBSCRIPTION_CREATE]:
    | {
        subscriptionId: string;
      }
    | undefined;
  [routes.FLYER_CREATE]: undefined;
  [routes.FLYER_LIST]: undefined;
  [routes.PRODUCT_CREATE]:
    | {
        productId: string;
      }
    | undefined;
  [routes.USER_MANAGE_LIST]: undefined;
  [routes.USER_GENERAL_MEMBER_LIST]: undefined;
  [routes.USER_ASSIGN_DRIVER_LIST]: {
    customerId: string;
  };
  [routes.FARM_HOUSE_CREATE]:
    | {
        farmHouseId: string;
      }
    | undefined;
  [routes.FARM_HOUSE_BOOKING]: {
    farmHouseId?: string;
    farmHouseBookingId?: string;
  };
  [routes.FARM_HOUSE_BOOKING_ADMIN]: {
    farmHouseId?: string;
    farmHouseBookingId?: string;
  };
  [routes.FARM_HOUSE_SPECIFIC_BOOKING_LIST]: {
    farmHouseId: string;
  };
  [routes.FARM_HOUSE_BOOKING_MANAGE_LIST]: undefined;
  [routes.RESTAURANT_ORDER_ITEM_LIST]: undefined;
  [routes.RESTAURANT_ORDER_LIST]: undefined;
  [routes.RESTAURANT_ORDER_SUMMARY]: {
    orders?: { [key: string]: number };
    restaurantOrderId?: string;
  };
  [routes.RESTAURANT_ITEM_MANAGE_LIST]: undefined;
  [routes.RESTAURANT_ITEM_CREATE]:
    | {
        restaurantItemId: string;
      }
    | undefined;
};

export type AppNavigatorParamList = HomeTabNavigatorParamList &
  MultipleNavigatorParamList &
  AccountNavigatorParamList;

export default function AppNavigator() {
  const { setDisabledNotifications } = useAuthContext();
  useNotifications();

  const handleGetNotificationPreferences = async () => {
    setDisabledNotifications(await authStorage.getDisabledNotifications());
  };

  const textLang = useTextLang();

  useEffect(() => {
    handleGetNotificationPreferences();
  }, []);

  const Stack = createNativeStackNavigator<AppNavigatorParamList>();
  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: defaultStyles.headerStyle,
        headerShown: false,
        animation,
      }}
    >
      {/* HomeTab */}
      <Stack.Screen component={HomeTabNavigator} name={routes.HOME_TAB} />
      {/* HomeTab End */}

      {/* Account */}
      {/* -- My Account Screen -- */}
      <Stack.Screen
        options={{
          headerShown: true,
        }}
        component={SettingsScreen}
        name={routes.SETTINGS}
      />
      {/* Account End */}

      {/* Product */}
      <Stack.Screen
        options={{
          headerShown: true,
          title: "Product",
        }}
        component={ProductScreen}
        name={routes.PRODUCT}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: "Create",
        }}
        component={ProductCreateScreen}
        name={routes.PRODUCT_CREATE}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: "All Products",
        }}
        component={ProductListScreen}
        name={routes.PRODUCT_LIST}
      />
      <Stack.Screen
        options={({ navigation }) => ({
          headerShown: true,
          title: "Manage Products",
          headerRight(props) {
            return (
              <TouchableOpacity
                onPress={() => {
                  navigation.push(routes.PRODUCT_CREATE);
                }}
              >
                <MaterialCommunityIcons
                  name="plus"
                  size={24}
                  color={colors.primary}
                />
              </TouchableOpacity>
            );
          },
        })}
        component={ProductsManageListScreen}
        name={routes.PRODUCT_MANAGE_LIST}
      />
      {/* Product End */}

      {/* Multiple */}
      <Stack.Screen
        options={{
          headerShown: true,
          title: "Create",
        }}
        component={RestaurantItemCreateScreen}
        name={routes.RESTAURANT_ITEM_CREATE}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: "Create Order",
        }}
        component={RestaurantOrderItemListScreen}
        name={routes.RESTAURANT_ORDER_ITEM_LIST}
      />
      <Stack.Screen
        options={({ navigation }) => ({
          headerShown: true,
          title: "Restaurant Orders",
          headerRight(props) {
            return (
              <TouchableOpacity
                onPress={() => {
                  navigation.push(routes.RESTAURANT_ORDER_ITEM_LIST);
                }}
              >
                <MaterialCommunityIcons
                  name="plus"
                  size={24}
                  color={colors.primary}
                />
              </TouchableOpacity>
            );
          },
        })}
        component={RestaurantOrderListScreen}
        name={routes.RESTAURANT_ORDER_LIST}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: "Order Summary",
        }}
        component={RestaurantOrderSummaryScreen}
        name={routes.RESTAURANT_ORDER_SUMMARY}
      />
      <Stack.Screen
        options={({ navigation }) => ({
          headerShown: true,
          title: "Manage Restaurant Menu",
          headerRight(props) {
            return (
              <TouchableOpacity
                onPress={() => {
                  navigation.push(routes.RESTAURANT_ITEM_CREATE);
                }}
              >
                <MaterialCommunityIcons
                  name="plus"
                  size={24}
                  color={colors.primary}
                />
              </TouchableOpacity>
            );
          },
        })}
        component={RestaurantItemManageListScreen}
        name={routes.RESTAURANT_ITEM_MANAGE_LIST}
      />
      <Stack.Screen
        options={({
          navigation,
        }: {
          navigation: NativeStackNavigationProp<any>;
        }) => ({
          headerShown: true,
          title: "My Basket",
        })}
        component={MyBasketScreen}
        name={routes.MY_BASKET}
      />
      <Stack.Screen
        options={({
          navigation,
        }: {
          navigation: NativeStackNavigationProp<any>;
        }) => ({
          headerShown: true,
          title: textLang("User Basket", "प्रयोगकर्ताको झोला"),
        })}
        component={UserBasketScreen}
        name={routes.USER_BASKET}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: "Available Subscriptions",
        }}
        component={SubscriptionListScreen}
        name={routes.SUBSCRIPTION_LIST}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          // headerLargeTitle: true,
          title: "Chat",
        }}
        component={ChatScreen}
        name={routes.CHAT}
      />
      <Stack.Screen
        component={DeliveryListScreen}
        name={routes.DELIVERY_LIST}
        options={{
          headerShown: true,
          title: textLang("Deliveries", "डिलिभरी"),
        }}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: "Create",
        }}
        component={UserCreateScreen}
        name={routes.USER_CREATE}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: "Create",
        }}
        component={SubscriptionCreateScreen}
        name={routes.SUBSCRIPTION_CREATE}
      />
      <Stack.Screen
        options={({
          navigation,
        }: {
          navigation: NativeStackNavigationProp<any>;
        }) => ({
          headerShown: true,
          title: "Flyers",
          headerRight() {
            return (
              <TouchableOpacity
                onPress={() => {
                  navigation.push(routes.FLYER_CREATE);
                }}
              >
                <MaterialCommunityIcons
                  name="plus"
                  size={24}
                  color={colors.primary}
                />
              </TouchableOpacity>
            );
          },
        })}
        component={FlyerListScreen}
        name={routes.FLYER_LIST}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: "Create Flyer",
        }}
        component={FlyerCreateScreen}
        name={routes.FLYER_CREATE}
      />
      <Stack.Screen
        component={UsersManageListScreen}
        name={routes.USER_MANAGE_LIST}
        options={({
          navigation,
        }: {
          navigation: NativeStackNavigationProp<any>;
        }) => ({
          headerShown: true,
          title: "Manage Users",
          headerRight() {
            return (
              <TouchableOpacity
                onPress={() => {
                  navigation.push(routes.USER_CREATE);
                }}
              >
                {/* <View className="mx-5"> */}
                <MaterialCommunityIcons
                  name="plus"
                  size={24}
                  color={colors.primary}
                />
                {/* </View> */}
              </TouchableOpacity>
            );
          },
        })}
      />
      <Stack.Screen
        component={UsersGeneralMemberListScreen}
        name={routes.USER_GENERAL_MEMBER_LIST}
        options={({
          navigation,
        }: {
          navigation: NativeStackNavigationProp<any>;
        }) => ({
          headerShown: true,
          title: textLang("Users", "प्रयोगकर्ता"),
        })}
      />
      <Stack.Screen
        component={UsersAssignDriverListScreen}
        name={routes.USER_ASSIGN_DRIVER_LIST}
        options={{
          headerShown: true,
          title: textLang("Assign Driver", "ड्राइभर नियुक्त गर्नुहोस्"),
        }}
      />
      {/* Admin End */}
      <Stack.Screen
        component={FarmHouseBookingScreen}
        name={routes.FARM_HOUSE_BOOKING}
        options={{
          headerShown: true,
          title: textLang("Farmhouse Booking", "फार्महाउस बुकिङ"),
        }}
      />
      <Stack.Screen
        component={FarmHouseBookingAdminScreen}
        name={routes.FARM_HOUSE_BOOKING_ADMIN}
        options={{
          headerShown: true,
          title: textLang("Farmhouse Booking", "फार्महाउस बुकिङ"),
        }}
      />
      <Stack.Screen
        component={FarmHouseListScreen}
        name={routes.FARM_HOUSE_LIST}
        options={{
          headerShown: true,
          title: textLang("Farmhouses", "फार्महाउस"),
        }}
      />
      <Stack.Screen
        options={({ navigation }) => ({
          headerShown: true,
          title: textLang("Manage Farmhouses", "फार्महाउस प्रबन्ध गर्नुहोस्"),
          headerRight(props) {
            return (
              <TouchableOpacity
                onPress={() => {
                  navigation.push(routes.FARM_HOUSE_CREATE);
                }}
              >
                <MaterialCommunityIcons
                  name="plus"
                  size={24}
                  color={colors.primary}
                />
              </TouchableOpacity>
            );
          },
        })}
        component={FarmHouseManageListScreen}
        name={routes.FARM_HOUSE_MANAGE_LIST}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: textLang("Create", "बनाउने"),
        }}
        component={FarmHouseCreateScreen}
        name={routes.FARM_HOUSE_CREATE}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: textLang("Bookings", "बुकिङहरू"),
        }}
        component={FarmHouseBookingManageListScreen}
        name={routes.FARM_HOUSE_BOOKING_MANAGE_LIST}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: textLang("Bookings", "बुकिङहरू"),
        }}
        component={FarmHouseSpecificBookingListScreen}
        name={routes.FARM_HOUSE_SPECIFIC_BOOKING_LIST}
      />
      {/* Admin End */}
      <Stack.Screen
        component={NotificationsScreen}
        name={routes.NOTIFICATIONS}
        options={{
          headerShown: true,
          title: textLang("Notifications", "सूचनाहरू"),
        }}
      />
    </Stack.Navigator>
  );
}
