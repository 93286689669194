import type { RouteProp } from "@react-navigation/native";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import * as Yup from "yup";

import Alert from "~/components/Alert";
import { useAuthStore } from "~/store";
import { trpc } from "~/utils/trpc";
import { useAuthContext } from "../auth/context";
import ActivityIndicator from "../components/ActivityIndicator";
import AppButton from "../components/AppButton";
import Text from "../components/AppText";
import {
  ErrorMessage,
  Form,
  FormField,
  FormPasswordField,
  SubmitButton,
} from "../components/forms";
import LogoHeader from "../components/LogoHeader";
import Screen from "../components/Screen";
import type { AuthNavigatorParamList } from "../navigation/AuthNavigator";
import routes from "../navigation/routes";

export default function ResetPasswordOTPScreen({
  route,
  navigation,
}: {
  route: RouteProp<AuthNavigatorParamList, routes.RESET_PASSWORD_OTP>;
  navigation: NativeStackNavigationProp<
    AuthNavigatorParamList, // removed AppNavigatorParamList
    routes.RESET_PASSWORD_OTP
  >;
}) {
  const { email } = route.params;

  // const inputRef = useRef<any>(null);
  const { logout } = useAuthContext();
  const user = useAuthStore((state) => state.user);

  const {
    mutate: resetPassword,
    isLoading,
    error,
  } = trpc.user.resetPassword.useMutation({
    onSuccess(data, variables, context) {
      Alert.alert("Success", "Password reset successfully");
      if (user) {
        void logout();
        return;
      }
      navigation.navigate(routes.LOGIN_INTERMEDIATE, {
        logInData: {
          token: data.token,
          user: data.user,
        },
        email: "",
        password: "",
      });
    },

    onError: (error_) => {
      Alert.alert("Error", error_.message);
      throw new Error(error_.message);
    },
  });

  const handleSubmit = ({
    otp,
    password,
    confirmPassword,
  }: ResetPasswordForm) => {
    void resetPassword({
      otp,
      email,
      password,
      confirmPassword,
    });
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     inputRef.current?.focusField(0);
  //   }, 500);
  // }, []);

  return (
    <>
      <ActivityIndicator visible={isLoading} />
      <Screen backgroundColor="white" className="p-5">
        <LogoHeader className="my-4" />

        <Text bigText className="my-5 font-bold">
          Enter the OTP and new password
        </Text>
        <ErrorMessage error={error?.message} visible={!!error} />

        <Form
          initialValues={initialForm}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <FormField
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="number-pad"
            name="otp"
            label="OTP"
            textContentType="oneTimeCode"
          />
          <FormPasswordField
            autoCapitalize="none"
            autoCorrect={false}
            autoComplete="password-new"
            name="password"
            label="Password"
            textContentType="password"
          />
          <FormPasswordField
            autoCapitalize="none"
            autoCorrect={false}
            name="confirmPassword"
            label="Confirm Password"
            textContentType="password"
          />
          <SubmitButton title="Reset Password" />
          <AppButton
            title="Cancel"
            onPress={() => {
              navigation.pop();
            }}
          />
        </Form>
      </Screen>
    </>
  );
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one capital letter, one number, one symbol, and be at least 8 characters long",
    )
    .label("Password"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Passwords must match",
  ),
});

const initialForm = {
  otp: "",
  password: "",
  confirmPassword: "",
};

type ResetPasswordForm = typeof initialForm;
