import { useEffect, useState } from "react";
import { RefreshControl, View } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import Alert from "~/components/Alert";
import AppButton from "~/components/AppButton";
import AppDatePicker from "~/components/AppDatePicker";
import AppListItems from "~/components/AppListItems";
import InfoText from "~/components/InfoText";
import { ListTitle } from "~/components/lists/ListTitle";
import { AppNavigatorParamList } from "~/navigation/AppNavigator";
import routes from "~/navigation/routes";
import { useAuthStore } from "~/store";
import { trpc } from "~/utils/trpc";
import ActivityIndicator from "../components/ActivityIndicator";
import Screen from "../components/Screen";
import { RestaurantOrderStatusChoice, UserTypeChoice } from ".prisma/client";

export default function RestaurantOrderSummaryScreen({
  navigation,
  route,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.RESTAURANT_ORDER_SUMMARY
  >;
  route: RouteProp<AppNavigatorParamList, routes.RESTAURANT_ORDER_SUMMARY>;
}) {
  const { orders, restaurantOrderId } = route.params;

  const user = useAuthStore((state) => state.user)!;

  const [date, setDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1)),
  );

  const utils = trpc.useUtils();

  const { data, isLoading, refetch } = trpc.restaurant.listItems.useQuery(
    {},
    {
      enabled: !restaurantOrderId,
    },
  );

  const {
    data: orderData,
    isLoading: orderDataLoading,
    refetch: orderDataRefetch,
  } = trpc.restaurant.getOrder.useQuery(
    {
      restaurantOrderId: restaurantOrderId || "",
    },
    {
      enabled: !!restaurantOrderId,
    },
  );

  useEffect(() => {
    if (restaurantOrderId && orderData) {
      setDate(new Date(orderData.date));
    }
  }, [orderData]);

  const { mutate: aknowledgeOrder, isLoading: isAknowledgingOrder } =
    trpc.restaurant.aknowledgeOrder.useMutation({
      onSuccess: () => {
        Alert.alert(
          "Order Acknowledged",
          "Order has been marked as acknowledged",
        );
        utils.restaurant.invalidate();
      },
      onError: (error) => {
        Alert.alert("Error", error.message);
      },
    });

  const orderKeys = Object.keys(orders || {}).filter(
    (key) => (orders || {})[key] > 0,
  );

  const orderItems = !restaurantOrderId
    ? data
        ?.filter((item) => orderKeys.includes(item.id.toString()))
        .map((item) => ({
          ...item,
          quantity: (orders || {})[item.id],
        }))
    : orderData?.items.map((item) => ({
        ...item.item,
        quantity: item.quantity,
      }));

  const { mutate: createOrder, isLoading: isCreatingOrder } =
    trpc.restaurant.createOrder.useMutation({
      onSuccess: () => {
        navigation.popToTop();
        Alert.alert(
          "Order Created",
          "Your order has been created successfully",
        );
        utils.restaurant.invalidate();
      },
      onError: (error) => {
        Alert.alert("Error", error.message);
      },
    });

  return (
    <>
      <ActivityIndicator
        visible={
          (!restaurantOrderId && isLoading) ||
          isCreatingOrder ||
          (restaurantOrderId && orderDataLoading) ||
          isAknowledgingOrder
        }
        className="bg-iosBackground"
      />
      <Screen
        className="px-5"
        noSafeArea
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={() => {
              refetch();
              orderDataRefetch();
            }}
          />
        }
      >
        {/* {(isLoading && !restaurantOrderId) ||
          (orderDataLoading && restaurantOrderId && (
            <View className="h-32 justify-center">
              <NativeActivityIndicator color={colors.primary} size="large" />
            </View>
          ))} */}
        {orderItems && (
          <>
            {orderItems.length === 0 && (
              <View className="py-4">
                <InfoText text="You have not added any items to your order" />
              </View>
            )}
            <View>
              <AppListItems
                items={[
                  {
                    name: "Order Summary",
                    options: orderItems.map(
                      ({ name, price, quantity }, index) => ({
                        title: name,
                        subTitle: `${quantity} × Rs. ${+price.toFixed(2)}`,
                      }),
                    ),
                  },
                  {
                    name: "Order Details",
                    options: [
                      {
                        title: "Total",
                        subTitle: `Rs. ${+orderItems
                          .reduce(
                            (acc, item) => acc + item.price * item.quantity,
                            0,
                          )
                          .toFixed(2)}`,
                      },
                    ],
                  },
                ]}
              />
              <ListTitle
                name={restaurantOrderId ? "Pickup Date" : "Set Pickup Date"}
              />
              <AppDatePicker
                date={date}
                onDateChange={setDate}
                minimumDate={new Date()}
                maximumDate={undefined}
                readOnly={!!restaurantOrderId}
                label=""
                my0
                placeholder=""
                noBorder
              />
            </View>
          </>
        )}
      </Screen>
      {restaurantOrderId ? null : (
        <View className="bg-white px-5 pb-6">
          <AppButton
            title="Confirm Order"
            onPress={() => {
              createOrder({
                orders: orders || {},
                date,
              });
            }}
          />
        </View>
      )}
      {restaurantOrderId &&
      orderData &&
      orderData.status === RestaurantOrderStatusChoice.PENDING &&
      [
        UserTypeChoice.ADMIN as UserTypeChoice,
        UserTypeChoice.RESTAURANT_STAFF,
      ].includes(user.type) ? (
        <View className="bg-white px-5 pb-6">
          <AppButton
            title="Mark as Aknowledged"
            onPress={() => {
              aknowledgeOrder({
                restaurantOrderId,
              });
            }}
          />
        </View>
      ) : null}
    </>
  );
}
