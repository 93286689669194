import { useEffect, useState } from "react";
import { Keyboard } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import * as Yup from "yup";

import Alert from "~/components/Alert";
import { trpc } from "~/utils/trpc";
import { uploadImages } from "~/utils/upload";
import ActivityIndicator from "../components/ActivityIndicator";
import {
  ErrorMessage,
  Form,
  FormField,
  FormSingleImagePicker,
  SubmitButton,
} from "../components/forms";
import FormNativePicker from "../components/forms/FormNativePicker";
import Screen from "../components/Screen";
import { AppNavigatorParamList } from "../navigation/AppNavigator";
import routes from "../navigation/routes";
import { toTitleCase } from "../utils/toTitleCase";
import { DietaryChoice } from ".prisma/client";

export default function RestaurantItemCreateScreen({
  navigation,
  route,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.RESTAURANT_ITEM_CREATE
  >;
  route: RouteProp<AppNavigatorParamList, routes.RESTAURANT_ITEM_CREATE>;
}) {
  const utils = trpc.useUtils();
  const { restaurantItemId } = route.params || {};
  const { data: restaurantItemData, isLoading: restaurantItemLoading } =
    trpc.restaurant.getItemBasicInfo.useQuery({
      restaurantItemId: restaurantItemId || "",
    });

  const [artificialLoading, setArtificialLoading] = useState(false);

  const {
    mutate: createRestaurantItem,
    isLoading: createRestaurantItemLoading,
    error,
  } = trpc.restaurant.createItem.useMutation({
    onSuccess: () => {
      Alert.alert("Success", "Item saved successfully");
      utils.invalidate();
      setArtificialLoading(false);
      navigation.pop();
    },
    onError(error) {
      setArtificialLoading(false);
      Alert.alert("Error", error.message);
    },
  });

  const { mutateAsync: getSignedUrl } = trpc.s3.getSignedUrl.useMutation();

  const handleSubmit = async (input: {
    [K in keyof FormValues]: NonNullable<FormValues[K]>;
  }) => {
    setArtificialLoading(true);

    Keyboard.dismiss();
    createRestaurantItem({
      ...input,
      restaurantItemId,
      image: (await uploadImages([input.image], getSignedUrl, () => {}))[0],
    });
  };

  useEffect(() => {
    if (restaurantItemId) {
      navigation.setOptions({
        title: `Edit`,
      });
    }
  }, [restaurantItemId]);

  return (
    <>
      <ActivityIndicator
        visible={
          artificialLoading ||
          createRestaurantItemLoading ||
          restaurantItemLoading
        }
      />
      <Screen backgroundColor="white" className="px-5 py-2" noSafeArea>
        {restaurantItemData !== undefined && (
          <Form
            initialValues={
              restaurantItemData
                ? Object.keys(restaurantItemData).reduce((acc, key) => {
                    if (
                      Object.prototype.hasOwnProperty.call(
                        formInitialValues,
                        key,
                      )
                    ) {
                      return { ...acc, [key]: restaurantItemData[key] };
                    }
                    return acc;
                  }, formInitialValues)
                : formInitialValues
            }
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <ErrorMessage
              error={error?.message || "Invalid phone or password"}
              visible={!!error}
            />
            <FormSingleImagePicker name="image" label="Image" />
            <FormField
              autoCapitalize="words"
              name="name"
              label="Item Name"
              keyboardType="default"
            />
            <FormField
              autoCapitalize="words"
              name="description"
              label="Item Description"
              multiline
              keyboardType="default"
            />
            <FormNativePicker
              items={Object.values(DietaryChoice)
                .filter((value) => value !== DietaryChoice.VEG__NON_VEG)
                .map((value) => ({
                  value,
                  label: toTitleCase(value),
                }))}
              name="dietary"
              label="Dietary"
            />
            <FormField
              number
              keyboardType="number-pad"
              name="price"
              label="Price"
            />
            <SubmitButton title="Save Item" />
          </Form>
        )}
      </Screen>
    </>
  );
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label("Item Name"),
  dietary: Yup.string()
    .oneOf(Object.values(DietaryChoice))
    .required()
    .label("Dietary"),
  price: Yup.string().required().label("Price"),
  isShown: Yup.boolean().required().label("Shown to users"),
  description: Yup.string().required().label("Description"),
});

const formInitialValues: FormValues = {
  name: "",
  dietary: null,
  price: 0,
  isShown: true,
  description: "",
  image: "",
};

type FormValues = {
  name: string;
  dietary: DietaryChoice | null;
  price: number;
  isShown: boolean;
  description: string;
  image: string;
};
