import { create } from "zustand";

import authStorage from "~/auth/storage";
import { UserData } from "~/utils/trpcDataTypes";

export const langToIndex = {
  en: 0,
  np: 1,
} as const;

interface BearState {
  disabledNotifications: string[];
  setDisabledNotifications: (disabledNotifications: string[]) => void;
  loadingVisible: boolean;
  setLoadingVisible: (loadingVisible: boolean) => void;
  initialLoaded: boolean;
  setInitialLoaded: (initialLoaded: boolean) => void;
  language: keyof typeof langToIndex;
  setLanguage: (language: keyof typeof langToIndex) => void;
  textLang: (en: string, np: string) => string;
}

export const useBearStore = create<BearState>()((set, get) => ({
  disabledNotifications: [],
  setDisabledNotifications: (disabledNotifications) => {
    set({ disabledNotifications });
  },
  loadingVisible: false,
  setLoadingVisible: (loadingVisible) => set({ loadingVisible }),
  initialLoaded: false,
  setInitialLoaded: (initialLoaded) => set({ initialLoaded }),
  language: "en",
  setLanguage: (language) => {
    authStorage.setLanguage(language);
    set({ language });
  },
  textLang: (en: string, np: string) => {
    return get().language === "en" ? en : np;
  },
}));

interface AuthStore {
  user: UserData | undefined;
  setUser: (user: UserData) => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
  user: undefined,
  setUser: (user) => set({ user }),
}));
