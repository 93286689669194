import { Image, StatusBar, View } from "react-native";

import LogoHeader from "~/components/LogoHeader";
import AppButton from "../components/AppButton";
import Text from "../components/AppText";
import Screen from "../components/Screen";

export default function WelcomeModal({ onClose }: { onClose: () => void }) {
  return (
    <Screen backgroundColor="white" className="flex-1 p-5">
      <StatusBar backgroundColor={"#000"} barStyle={"light-content"} />
      <View className="w-full flex-1">
        <View className="flex-1 items-center justify-center">
          <View className="w-full">
            <LogoHeader />
          </View>

          <Text bigText className="text-center font-bold">
            Welcome to{" "}
            <Text bigText className="text-primary font-bold">
              AgriStudio
            </Text>
          </Text>
          <Text bigText className="text-center text-lg font-bold text-[#888]">
            Digital Farming
          </Text>
        </View>
        {/* <AppButton onPress={() => onClose()} title="I want assistance" /> */}
        <AppButton onPress={() => onClose()} title="Get Started" />
      </View>
    </Screen>
  );
}
