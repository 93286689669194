import * as ImagePicker from "expo-image-picker";
import { FormikContextType, FormikValues, useFormikContext } from "formik";

import AppText from "../AppText";
import ImageInputList from "../ImageInputList";
import ErrorMessage from "./ErrorMessage";

export type ImageAssetOrString = ImagePicker.ImagePickerAsset | string;

export default function FormImagePicker({
  name,
  label,
}: {
  name: string;
  label: string;
}) {
  const { errors, setFieldValue, touched, values } =
    useFormikContext<FormikContextType<FormikValues>>();
  const imageAssets: ImageAssetOrString[] = values[name];

  const handleImageAdd = (assets: ImageAssetOrString[]) => {
    setFieldValue(name, [...imageAssets, ...assets.map((asset) => [asset])]);
  };

  const handleImageRemove = (asset: ImageAssetOrString) => {
    setFieldValue(
      name,
      imageAssets.filter((imageAsset: ImageAssetOrString) => {
        if (typeof asset === "string" && typeof imageAsset === "string") {
          return imageAsset !== asset;
        }
        if (typeof asset === "string" || typeof imageAsset === "string") {
          return false;
        }
        return imageAsset[0].uri !== asset[0].uri;
      }),
    );
  };

  return (
    <>
      {label ? (
        <AppText className="text-mediumGray mx-3 my-2 text-sm">{label}</AppText>
      ) : null}
      <ImageInputList
        imageAssets={imageAssets}
        onImageAdd={handleImageAdd}
        onImageRemove={handleImageRemove}
      />
      <ErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
}
