import { Button, Text, View } from "react-native";

import colors from "@acme/common-utils/colors";

import { navigate } from "~/navigation/routeNavigation";
import routes from "~/navigation/routes";
import InfoText from "./InfoText";

export default function NotSubscribed() {
  return (
    <View className="py-4">
      <InfoText className="mb-4" text="You are not subscribed to any plan" />
      <Button
        title="View Plans"
        color={colors.primary}
        onPress={() => {
          navigate(routes.SUBSCRIPTION_LIST, {});
        }}
      />
    </View>
  );
}
