import { ScrollView, TouchableOpacity, View } from "react-native";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
} from "react-native-popup-menu";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import colors from "@acme/common-utils/colors";

import { toTitleCase } from "../utils/toTitleCase";
import { Item } from "./AppPicker";
import AppText from "./AppText";
import AppTextInput from "./AppTextInput";
import { ListItemSeparator } from "./lists";

export default function AppMenuPicker({
  icon,
  items,
  onItemSelect,
  my0,
  placeholder,
  selectedItem,
  label,
}: {
  icon?: string;
  items: Item[];
  onItemSelect: (item: Item) => void;
  my0?: boolean;
  placeholder: string;
  selectedItem: string;
  label: string;
}) {
  const handleItemSelect = (item: Item) => {
    onItemSelect(item);
  };

  return (
    <View>
      {label ? (
        <AppText className="text-mediumGray mx-3 mt-2 text-sm">{label}</AppText>
      ) : null}
      <Menu>
        <MenuTrigger
          customStyles={{
            TriggerTouchableComponent: TouchableOpacity,
          }}
        >
          <AppTextInput accessibilityLabel={label} icon={icon} my0={my0}>
            {selectedItem ? (
              <AppText className="flex-1 p-3 px-4">
                {items.find((item) => item.value === selectedItem)?.label}
              </AppText>
            ) : (
              <AppText className="text-mediumGray flex-1 p-3 px-4">
                {placeholder || "Select an item"}
              </AppText>
            )}
            <View className="mx-2">
              <MaterialCommunityIcons
                color={colors.mediumGray}
                name="chevron-down"
                size={20}
              />
            </View>
          </AppTextInput>
        </MenuTrigger>
        <MenuOptions
          customStyles={{
            optionWrapper: {
              minHeight: 40,
              justifyContent: "center",
            },
            optionsContainer: {
              width: "90%",
              borderRadius: 12,
            },
          }}
        >
          <ScrollView style={{ maxHeight: 400 }}>
            {items.map((option, index) => (
              <View key={option.label}>
                <MenuOption onSelect={() => handleItemSelect(items[index])}>
                  <View className="flex-row items-center px-2">
                    <AppText className="ml-2 flex-1 text-base">
                      {toTitleCase(option.label)}
                    </AppText>
                  </View>
                </MenuOption>
                {index !== items.length - 1 && <ListItemSeparator />}
              </View>
            ))}
          </ScrollView>
        </MenuOptions>
      </Menu>
    </View>
  );
}
