import { useEffect } from "react";
import {
  RefreshControl,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from "react-native";
import CircularProgress from "react-native-circular-progress-indicator";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import colors from "@acme/common-utils/colors";

import ActivityIndicator from "~/components/ActivityIndicator";
import NotSubscribed from "~/components/NotSubscribed";
import SaleCarousel from "~/components/SaleCarousel";
import { useAuthStore } from "~/store";
import { trpc } from "~/utils/trpc";
import { UserTypeChoice } from "../../../prisma-generated";
import AppText from "../components/AppText";
import Screen from "../components/Screen";
import SquareItem from "../components/SquareItem";
import { AppNavigatorParamList } from "../navigation/AppNavigator";
import routes from "../navigation/routes";

dayjs.extend(advancedFormat);

export default function RestaurantStaffDashboardScreen({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    AppNavigatorParamList,
    routes.RESTAURANT_STAFF_DASHBOARD
  >;
}) {
  const { data: counts, isLoading } = trpc.getRestaurantDashboard.useQuery();

  const utils = trpc.useUtils();

  return (
    <>
      <ActivityIndicator visible={isLoading} />
      <Screen
        noSafeArea
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={() => {
              utils.invalidate();
            }}
          />
        }
      >
        <View className="p-5">
          <View className="flex-row flex-wrap justify-between">
            {[
              {
                name: "Restaurant Menu Items",
                onPress: () => {
                  navigation.push(routes.RESTAURANT_ITEM_MANAGE_LIST);
                },
                iconName: "clipboard-text",
                count: counts?.restaurantItems,
              },
              {
                name: "Restaurant Orders",
                onPress: () => {
                  navigation.push(routes.RESTAURANT_ORDER_LIST);
                },
                iconName: "carrot",
                count: counts?.restaurantOrders,
              },
            ].map(({ name, onPress, iconName, count }) => (
              <SquareItem
                key={name}
                name={name}
                onPress={onPress}
                iconName={iconName}
                count={count}
              />
            ))}
          </View>
        </View>
      </Screen>
    </>
  );
}
